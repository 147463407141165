import React from "react";
import * as Style from "./Styled.component"; // Assuming you're importing styled components or custom styles
import { ContactForm } from "../Contact/Contact.form";
import SideMenuBar from "./SideMenuBar";
import { sidebarMenu } from "./StaticData.Constant";

const ContactUsPage = () => {
  return (
    <Style.MainLayoutSection>
    <SideMenuBar menuItems={sidebarMenu}/>
    <Style.PageContainer>
      {/* Main Heading */}
      <Style.MainHeading>CONTACT US</Style.MainHeading>

      {/* Static content for Contact Us */}
      <Style.SectionContainer>
        {/* Introduction */}
        <Style.Section>
          <Style.Paragraph>
            Thank you for visiting StitchZone! Whether you have a question about our products, need assistance with an order, or have feedback to share, we're here to help. Please feel free to reach out to us using any of the following methods:
          </Style.Paragraph>
        </Style.Section>

        {/* Customer Service Hours */}
        <Style.Section>
          <Style.SubHeading>Customer Service Hours:</Style.SubHeading>
          <Style.Paragraph>Monday - Friday: 10:00 AM - 1:00 PM (PST)</Style.Paragraph>
        </Style.Section>

        {/* Phone */}
        <Style.Section>
          <Style.SubHeading>Phone:</Style.SubHeading>
          <Style.Paragraph>
            For immediate assistance, call us at: <strong>(510) 818 1182</strong>
          </Style.Paragraph>
        </Style.Section>

        {/* Email */}
        <Style.Section>
          <Style.SubHeading>Email:</Style.SubHeading>
          <Style.Paragraph>
            Send us an email at: <a href="mailto:info@stitchzone.com">info@stitchzone.com</a>
          </Style.Paragraph>
        </Style.Section>

        {/* Storefront Address */}
        <Style.Section>
          <Style.SubHeading>Storefront Address (By Appointment Only):</Style.SubHeading>
          <Style.Paragraph>4412 Technology Drive</Style.Paragraph>
          <Style.Paragraph>Fremont, CA 94538</Style.Paragraph>
        </Style.Section>

        {/* Feedback */}
        <Style.Section>
          <Style.SubHeading>Feedback:</Style.SubHeading>
          <Style.Paragraph>
            We value your feedback! Let us know how we're doing or suggest improvements by emailing us at <a href="mailto:info@stitchzone.com">info@stitchzone.com</a>.
          </Style.Paragraph>
        </Style.Section>

        {/* Additional Information */}
        <Style.Section>
          <Style.Paragraph>
            Please note that our storefront address is by appointment only. To schedule a visit, please contact us in advance. We strive to respond to all inquiries during regular business hours. Your satisfaction is our priority, and we look forward to assisting you.
          </Style.Paragraph>
        </Style.Section>

        {/* Thank You Note */}
        <Style.Section>
          <Style.Paragraph>
            Thank you for choosing StitchZone for all your embroidery needs!
          </Style.Paragraph>
        </Style.Section>
      </Style.SectionContainer>
      <ContactForm/>
    </Style.PageContainer>
    </Style.MainLayoutSection>
  );
};

export default ContactUsPage;
