import React from "react";
import styled from "styled-components";
import { ImagesV2 } from "../../models/ApiResponses/IGetProductResponseV2";
import { Tooltip } from "@happeouikit/tooltip";
import { TextZeta, TinyText } from "@happeouikit/typography";

import {
  DesignerViewStates
} from "../../models";

interface SidePrevieweProps {
  images: ImagesV2;
  onSelect(url: string, side: string): void;
  applicableEmbroidaries: string[];
  onlyFrontEnabled: boolean;
  selectedSide:string;
  designerViewState: any;
  selectedProduct: any;
}
interface SidePreviewState {}

export default class SidePreview extends React.Component<
  SidePrevieweProps,
  SidePreviewState
> {
  constructor(props: SidePrevieweProps) {
    super(props);
    this.state = {};

    /* console.log("this.props.images -> hood_forward : ", this.props.images.hood_forward); */
  }

  handleAccordion = (id: String) => {
    this.setState({ active: id });
  };

  

  render() {
    const { onlyFrontEnabled, selectedSide, designerViewState, selectedProduct } = this.props;

    return (
      <div >
      <TextZeta className="bold" style={{paddingLeft:"20px"}}>Preview</TextZeta>
      <TinyText style={{paddingLeft:"3%"}}>(Click on images)</TinyText>
      <Container>
        <ImgCover color={this.props.selectedSide === "front" ? "blue":"white"}>
          <Tooltip
            id="front_preview_tooltip"
            content="Click here"
            background={"#000000"}
            text={"#FBFCFD"}
            place="left"
            effect="float"
          />
          <Img
            data-tip data-for="front_preview_tooltip" id="front_preview"
            src={this.props?.images?.front}
            onClick={() =>
              this.props.onSelect(this?.props?.images?.front, "front")
            }
          />
          <PreviewText>Front</PreviewText>
        </ImgCover>
        {(selectedProduct && selectedProduct.typeId?.type && (!selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE") || (selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE") && designerViewState == DesignerViewStates.EmboideryLocations))) && <ImgCover color={this.props.selectedSide === "back" ? "blue":"white"}>
          <Tooltip
            id="back_preview_tooltip"
            content="Click here"
            background={"#000000"}
            text={"#FBFCFD"}
            place="left"
            effect="float"
          />
          <Img
            data-tip data-for="back_preview_tooltip" id="back_preview"
            src={this?.props?.images?.back}
            onClick={() => {
              this.props.onSelect(this?.props?.images?.back, "back");
            }}
          />
          <PreviewText>Back</PreviewText>
        </ImgCover>}
        {this.props.applicableEmbroidaries.findIndex((t:any) => (t.previewSideName === "arm_left")) !== -1 && this.props.images.arm_left && <ImgCover className={onlyFrontEnabled ? "disable" : ""} color={this.props.selectedSide === "arm_left" ? "blue":"white"}>
          {!onlyFrontEnabled && <Tooltip
            id="arm_left_preview_tooltip"
            content="Click here"
            background={"#000000"}
            text={"#FBFCFD"}
            place="left"
            effect="float"
          />}
          <Img
            data-tip data-for="arm_left_preview_tooltip" id="arm_left_preview"
            src={this.props.images.arm_left}
            onClick={() => {
              if (!onlyFrontEnabled)
                this.props.onSelect(this.props.images.arm_left, "arm_left");
            }}
          />
          <PreviewText>Left Arm</PreviewText>
        </ImgCover>}
        {this.props.applicableEmbroidaries.findIndex((t:any) => (t.previewSideName === "arm_right")) !== -1 && this.props.images.arm_right && <ImgCover className={onlyFrontEnabled ? "disable" : ""} color={this.props.selectedSide === "arm_right" ? "blue":"white"}>
        {!onlyFrontEnabled && <Tooltip
            id="arm_right_preview_tooltip"
            content="Click here"
            background={"#000000"}
            text={"#FBFCFD"}
            place="left"
            effect="float"
          />}
          <Img
            data-tip data-for="arm_right_preview_tooltip" id="arm_left_preview"
            src={this.props.images.arm_right}
            onClick={() => {
              if (!onlyFrontEnabled)
                this.props.onSelect(this.props.images.arm_right, "arm_right");
            }}
          />
          <PreviewText>Right Arm</PreviewText>
        </ImgCover>}
        {this.props.applicableEmbroidaries.findIndex((t:any) => (t.previewSideName === "cuff_left")) !== -1 && this.props.images.cuff_left && <ImgCover className={onlyFrontEnabled ? "disable" : ""} color={this.props.selectedSide === "cuff_left" ? "blue":"white"}>
        {!onlyFrontEnabled && <Tooltip
            id="cuff_left_preview_tooltip"
            content="Click here"
            background={"#000000"}
            text={"#FBFCFD"}
            place="left"
            effect="float"
          />}
          <Img
            data-tip data-for="cuff_left_preview_tooltip" id="cuff_left_preview"
            src={this.props.images.cuff_left}
            onClick={() => {
              if (!onlyFrontEnabled)
                this.props.onSelect(this.props.images.cuff_left, "cuff_left");
            }}
          />
          <PreviewText>Left Cuff</PreviewText>
          </ImgCover>}
        {this.props.applicableEmbroidaries.findIndex((t:any) => (t.previewSideName === "cuff_right")) !== -1 && this.props.images.cuff_right && <ImgCover className={onlyFrontEnabled ? "disable" : ""} color={this.props.selectedSide === "cuff_right" ? "blue":"white"}>
        {!onlyFrontEnabled && <Tooltip
            id="cuff_right_preview_tooltip"
            content="Click here"
            background={"#000000"}
            text={"#FBFCFD"}
            place="left"
            effect="float"
          />}
          <Img
            data-tip data-for="cuff_right_preview_tooltip" id="cuff_right_preview"
            src={this.props.images.cuff_right}
            onClick={() => {
              if (!onlyFrontEnabled)
                this.props.onSelect(this.props.images.cuff_right, "cuff_right");
            }}
          />
          <PreviewText>Right Cuff</PreviewText>
        </ImgCover>}
        {this.props.applicableEmbroidaries.findIndex((t:any) => (t.previewSideName === "on_collar")) !== -1 && this.props.images.on_collar && <ImgCover className={onlyFrontEnabled ? "disable" : ""} color={this.props.selectedSide === "on_collar" ? "blue":"white"}>
        {!onlyFrontEnabled && <Tooltip
            id="on_collar_preview_tooltip"
            content="Click here"
            background={"#000000"}
            text={"#FBFCFD"}
            place="left"
            effect="float"
          />}
          <Img
            data-tip data-for="on_collar_preview_tooltip" id="on_collar_preview"
            src={this.props.images.on_collar}
            onClick={() => {
              if (!onlyFrontEnabled)
                this.props.onSelect(this.props.images.on_collar, "on_collar");
            }}
          />
          <PreviewText>On Coller</PreviewText>
        </ImgCover>}
        {this.props.applicableEmbroidaries.findIndex((t:any) => (t.previewSideName === "collar_flipped_up")) !== -1 && this.props.images.collar_flipped_up && <ImgCover className={onlyFrontEnabled ? "disable" : ""} color={this.props.selectedSide === "collar_flipped_up" ? "blue":"white"}>
        {!onlyFrontEnabled && <Tooltip
            id="collar_flipped_up_preview_tooltip"
            content="Click here"
            background={"#000000"}
            text={"#FBFCFD"}
            place="left"
            effect="float"
          />}
          <Img
            data-tip data-for="collar_flipped_up_preview_tooltip" id="collar_flipped_up_preview"
            src={this.props.images.collar_flipped_up}
            onClick={() => {
              if (!onlyFrontEnabled)
                this.props.onSelect(this.props.images.collar_flipped_up, "collar_flipped_up");
            }}
          />
          <PreviewText>Collar Flipped Up</PreviewText>
        </ImgCover>}
        {this.props.applicableEmbroidaries.findIndex((t:any) => (t.previewSideName === "hood")) !== -1 && this.props.images.hood && <ImgCover className={onlyFrontEnabled ? "disable" : ""} color={this.props.selectedSide === "hood" ? "blue":"white"}>
        {!onlyFrontEnabled && <Tooltip
            id="hood_up_preview"
            content="Click here"
            background={"#000000"}
            text={"#FBFCFD"}
            place="left"
            effect="float"
          />}
          <Img
            data-tip data-for="hood_preview_tooltip" id="hood_preview"
            src={this.props.images.hood}
            onClick={() => {
              if (!onlyFrontEnabled)
                this.props.onSelect(this.props.images.hood, "hood");
            }}
          />
          <PreviewText>Hood</PreviewText>
        </ImgCover>}
        {this.props.applicableEmbroidaries.findIndex((t:any) => (t.previewSideName === "hood_forward")) !== -1 && this.props.images.hood_forward && <ImgCover className={onlyFrontEnabled ? "disable" : ""} color={this.props.selectedSide === "hood_forward" ? "blue":"white"}>
        {!onlyFrontEnabled && <Tooltip
            id="hood_forward_preview_tooltip"
            content="Click here"
            background={"#000000"}
            text={"#FBFCFD"}
            place="left"
            effect="float"
          />}
          <Img
            data-tip data-for="hood_forward_preview_tooltip" id="hood_forward_preview"
            src={this.props.images.hood_forward}
            onClick={() => {
              if (!onlyFrontEnabled)
                this.props.onSelect(this.props.images.hood_forward, "hood_forward");
            }}
          />
          <PreviewText>Hood Forward</PreviewText>
        </ImgCover>}
        {this.props.applicableEmbroidaries.findIndex((t:any) => (t.previewSideName === "hood_backward")) !== -1 && this.props.images.hood_backward && <ImgCover className={onlyFrontEnabled ? "disable" : ""} color={this.props.selectedSide === "hood_backward" ? "blue":"white"}>
        {!onlyFrontEnabled && <Tooltip
            id="hood_backward_preview_tooltip"
            content="Click here"
            background={"#000000"}
            text={"#FBFCFD"}
            place="left"
            effect="float"
          />}
          <Img
            data-tip data-for="hood_backward_preview_tooltip" id="hood_backward_preview"
            src={this.props.images.hood_backward}
            onClick={() => {
              if (!onlyFrontEnabled)
                this.props.onSelect(this.props.images.hood_backward, "hood_backward");
            }}
          />
          <PreviewText>Hood Backward</PreviewText>
        </ImgCover>}
        {this.props.applicableEmbroidaries.findIndex((t:any) => (t.previewSideName === "custom")) !== -1 && <ImgCover className={onlyFrontEnabled ? "disable" : ""} color={this.props.selectedSide === "custom" ? "blue":"white"}>
        {/*this.props.images.custom*/}
        {!onlyFrontEnabled && <Tooltip
            id="custom_preview_tooltip"
            content="Click here"
            background={"#000000"}
            text={"#FBFCFD"}
            place="left"
            effect="float"
          />}
          {/*this.props.images.custom && */<Img
            data-tip data-for="custom_preview_tooltip" id="custom_preview"
            src={"https://admin.favorsbythebay.com/apis/1740982579048CustomLocation.jpg"} 
            onClick={() => {
              if (!onlyFrontEnabled)
                this.props.onSelect(this.props.images.custom, "custom");
            }}
          />}
          <PreviewText onClick={() => {
              if (!onlyFrontEnabled)
                this.props.onSelect(this.props.images.custom, "custom");
            }}>Custom</PreviewText>
        </ImgCover>}
      </Container>
      </div>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  margin-top: 10px;
  margin-right: 10px;
  /*padding-top: 7%;*/
  padding-bottom: 3%;
  /*margin-top: 0%;*/
  height: 70vh;
  overflow-y: scroll;
`;

const ImgCover = styled.div.attrs((props) => ({
  className: props.className,
}))`
  &.disable {
    cursor: not-allowed;
    filter: opacity(0.4);
  }

  width: 100px;
  height: 100px;
  border: 2px solid ${(props) => props.color || "white"};
  border-radius: 4px;
  
  cursor: pointer;
  margin-top: 20px; 
  margin-bottom: 20px; 
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }
`;

const PreviewText = styled.p`
  font-size: 11px;
`;


const Img = styled.img`
  width: 100px;
  height: 100px;
  object-fit: fill;
`;
