import React from 'react';
import * as Style from './Styled.component';
import SideMenuBar from './SideMenuBar';
import { sidebarMenu } from './StaticData.Constant';

const CareerOportunity: React.FC = () => {
  return (
    <Style.MainLayoutSection>
    <SideMenuBar menuItems={sidebarMenu}/>
    <Style.PageContainer>
      <Style.MainHeading>CAREER OPPORTUNITIES</Style.MainHeading>
      <Style.SectionContainer>
        <Style.Section>
          <Style.Paragraph>
            Are you ready to take the next step in your career journey? We want to hear from you! If you're enthusiastic about joining our team and believe you have what it takes to excel with us, don't hesitate to reach out. Simply email us your information, resume, and availability to{' '}
            <a href="mailto:jobs@stitchzone.com">jobs@stitchzone.com</a>. We look forward to connecting with you and discovering how you can contribute to our innovative and vibrant team.
          </Style.Paragraph>
        </Style.Section>
        <Style.Section>
            <Style.Ul type="disc">
          <Style.List color="blue" >
            <a href="/production-assistant">Production Assistant</a>
          </Style.List>
          <Style.List color="blue">
            <a href="/sales-associate">Sales Associate</a>
          </Style.List>
          <Style.List color="blue">
            <a href="/social-media-specialist">Social Media Specialist</a>
          </Style.List>
          </Style.Ul>
        </Style.Section>
      </Style.SectionContainer>
    </Style.PageContainer>
    </Style.MainLayoutSection>
  );
};

export default CareerOportunity;
