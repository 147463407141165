import React from 'react';
import * as Style from './Styled.component';
import { FundraiseForm } from '../Fundraise/Funraise.form';
import SideMenuBar from './SideMenuBar';
import { sidebarMenu } from './StaticData.Constant';

const FundraiserPage: React.FC = () => {
  return (
    <Style.MainLayoutSection>
    <SideMenuBar menuItems={sidebarMenu}/>
    <Style.PageContainer>
      {/* Main Heading */}
      <Style.MainHeading>FUNDRAISE</Style.MainHeading>

      {/* Sub-headings and Paragraphs */}
      <Style.SectionContainer>
        {/* Fundraiser Intro */}
        <Style.Section>
          <Style.Paragraph>
            We are excited to be a part of something truly impactful. At StitchZone, we believe in your organization and your passion to reach your goals.
          </Style.Paragraph>
        </Style.Section>

        {/* Fundraiser Discount Code */}
        <Style.Section>
          <Style.Paragraph>
            A special fundraiser discount code will be given to your organization. Through this campaign, we aim to assist in reaching your outcomes.
          </Style.Paragraph>
        </Style.Section>

        {/* Referral Program */}
        <Style.Section>
          <Style.Paragraph>
            If you refer others to purchase item(s) using this special fundraiser discount code, they will be given a 5% discount off the regular advertised purchase price. Your organization will then be given a 5% commission of that purchase.
          </Style.Paragraph>
        </Style.Section>

        {/* Fundraiser Duration */}
        <Style.Section>
          <Style.Paragraph>
            This fundraising promotion runs for 3 months, per school year.
          </Style.Paragraph>
        </Style.Section>

        {/* Note Section */}
        <Style.Section>
          <Style.SubHeading>Note:</Style.SubHeading>
          <Style.Paragraph>
            This fundraiser is not valid for promotional discount pricing, discounted group orders, and rush orders. The fundraiser discount code will have an expiration date noted. The commission check will be paid at the end of the promotion, with a minimum of $25.00 earned.
          </Style.Paragraph>
        </Style.Section>
      </Style.SectionContainer>
      <Style.FormContainer><FundraiseForm/></Style.FormContainer>
    </Style.PageContainer>
    </Style.MainLayoutSection>
  );
};

export default FundraiserPage;
