import React from "react";
import * as Style from "./Styled.component";
import { sidebarMenu } from "./StaticData.Constant";
import SideMenuBar from "./SideMenuBar";

const RefundAndReturn = () => {
  return (
    <Style.MainLayoutSection>
    <SideMenuBar menuItems={sidebarMenu}/>
    <Style.PageContainer>
      <Style.MainHeading>REFUND & RETURNS</Style.MainHeading>

      {/* Static content for refund and return */}
      <Style.SectionContainer>
        {/* Introduction */}
        <Style.Section>
          <Style.Paragraph>
            At StitchZone, we strive to ensure your satisfaction with every order. As all our items are custom-made, please review our policy regarding refunds and returns below:
          </Style.Paragraph>
        </Style.Section>

        {/* Refunds */}
        <Style.Section>
          <Style.SubHeading>Refunds:</Style.SubHeading>
          <Style.Paragraph>
            - Defective or Incorrect Items: If you receive a defective item or an incorrect item, please contact us within 72 hours of receiving your order. We will replace the item or issue a refund, depending on your preference. We may require photo evidence of the issue for our records.
          </Style.Paragraph>
        </Style.Section>

        {/* Returns */}
        <Style.Section>
          <Style.SubHeading>Returns:</Style.SubHeading>
          <Style.Paragraph>
            - Return Criteria: Due to the custom nature of our products, we do not accept returns for reasons other than defects or incorrect items received.
          </Style.Paragraph>
          <Style.Paragraph>
            - Defective or Incorrect Item Return Process: If you believe your item is defective or you received the wrong item, please contact our customer service team within 72 hours of receiving your order. Do not send the item back without contacting us first. We may request the item to be returned for inspection before issuing a replacement or refund.
          </Style.Paragraph>
        </Style.Section>

        {/* Additional Information */}
        <Style.Section>
          <Style.SubHeading>Additional Information:</Style.SubHeading>
          <Style.Paragraph>
            - Order Verification: All orders undergo thorough examination by at least two members of our production staff to ensure accuracy in size and quantity (where applicable) and to detect any defects before shipping.
          </Style.Paragraph>
          <Style.Paragraph>
            - Responsibility After Delivery: StitchZone is not responsible for issues with orders that have been opened or left unattended after delivery.
          </Style.Paragraph>
          <Style.Paragraph>
            - Group Screen Printing Orders: Reprinting of missing or defective items in group screen printing orders is subject to criteria for a re-order. Please contact us promptly within 72 hours of receiving your order if there is an issue.
          </Style.Paragraph>
          <Style.Paragraph>
            - Time Limit for Returns: Items must be reported as defective or incorrect within 72 hours of delivery. Unauthorized returns may be refused and returned to the customer.
          </Style.Paragraph>
          <Style.Paragraph>
            - Return Deadline: Items must be returned within two weeks of receipt for any reason.
          </Style.Paragraph>
        </Style.Section>

        {/* Contact for Questions */}
        <Style.Section>
          <Style.Paragraph>
            If you have any questions or concerns about our refunds and returns policy, please contact our customer service team at
            
           <span><a href="mailto:info@stitchzone.com"> info@stitchzone.com </a></span> or (510) 818 1182. We are here to assist you.
          </Style.Paragraph>
        </Style.Section>

        
      </Style.SectionContainer>
    </Style.PageContainer>
    </Style.MainLayoutSection>
  );
};

export default RefundAndReturn;
