import React, { ChangeEvent, useState } from "react";
import { ILoginRequestParams } from "../../models";
import { Validation } from "../../Utils";
import { StyledInput, Button, StyledForm } from "./styled.component";

interface LoginFormProps {
  handleLogin(params: ILoginRequestParams): void;
}

export const LoginForm: React.FC<LoginFormProps> = ({ handleLogin }) => {
  const [loginParam, setloginParams] = useState<ILoginRequestParams>(
    process.env.NODE_ENV === "development"
      ? {
          email: "superadmin@yopmail.com",
          password: "superadmin@123",
        }
      : {
          email: "",
          password: "",
        }
  );

  const [validationError, setValidationErrors] = useState({
    emailInvalidMsg: "",
    passwordInvalidMsg: "",
  });

  const validateForm = () => {
    const { email, password } = loginParam;

    const { errorMessage: passwordInvalidMsg } =
      Validation.validatePassword(password);
    const { errorMessage: emailInvalidMsg } = Validation.validateEmail(email);

    setValidationErrors({
      passwordInvalidMsg,
      emailInvalidMsg,
    });

    return !passwordInvalidMsg && !emailInvalidMsg;
  };

  const validateAndLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { email, password } = loginParam;

    if (validateForm()) {
      const loginParams: ILoginRequestParams = {
        email,
        password,
      };

      handleLogin(loginParam);
    }
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setloginParams({ ...loginParam, email });

    setValidationErrors({
      ...validationError,
      emailInvalidMsg: Validation.validateEmail(email).errorMessage,
    });
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    setloginParams({ ...loginParam, password });

    setValidationErrors({
      ...validationError,
      passwordInvalidMsg: Validation.validatePassword(password).errorMessage,
    });
  };

  return (
    <>
    <StyledForm onSubmit={validateAndLogin}>
      <StyledInput
        type="text"
        placeholder="Email"
        autoComplete="off"
        onChange={handleEmailChange}
        label={validationError.emailInvalidMsg}
      />

      <StyledInput
        type="password"
        placeholder="Password"
        autoComplete="off"
        onChange={handlePasswordChange}
        label={validationError.passwordInvalidMsg}
      />
      <Button type="submit">Log In</Button>
      </StyledForm>
    </>
  );
};
