import React, { ChangeEvent } from "react";
import { Modal } from "@happeouikit/modals";
import { Checkbox, Dropdown, Input, Textarea, RadioButton } from "@happeouikit/form-elements";
import { BodyUI } from "@happeouikit/typography";
import styled, { keyframes } from "styled-components";
import {
  Embroidary,
  Embroidary_Locations,
  marginM,
  marginS,
  marginXL,
  paddingM,
  paddingS,
  primaryColor,
  marginL,
} from "../constants";
import {
  EmboidaryOptionValues,
  GREEK_ALPHABETS,
  IFontStyle,
  PreviewEmbroidarySettingNames,
  PreviewSettingNames,
} from "../models";
import {
  EmboidaryInputType,
  EmbroidaryID,
  IColorV2,
  IOneProductV2
} from "../models/ApiResponses/IGetProductResponseV2";
import { FontStyles } from "../widgets/shirtDesigner/fontStyle";
import {pascalCase, displayEmbdName, flippedText, isNumberLetters, isGreekLetters} from "../Utils";


interface AdditionalDesignModalProps {
  isOpen: boolean;
  close(): void;
  emboidaryOptionValues?: EmboidaryOptionValues;
  updateEmbroidaryLocations(emboidaryLoc: any, callback: any): void;
  embroidaryOption?: EmbroidaryID;
  onDone(
    enteredValues: Record<string, Record<string, string>>,
    embroidaryOption: any
  ): void;
  selectedSide: string;
  embroidaryLocations: {
    [key: string]: {};
  };
  appliquePreviewData: any;
  selectedColor?: IColorV2;
  selectedProduct:IOneProductV2;
}

interface AdditionalDesignModalState {
  isModalDisable: boolean;
  embroidaryOption: any;
  selectedOption: string;
}

export class AdditionalDesignModal extends React.Component<
  AdditionalDesignModalProps,
  AdditionalDesignModalState
> {
  private selectedData: any = {};
  private embroidaryOptionItems: any = [];

  constructor(props: AdditionalDesignModalProps) {
    super(props);

    this.state = {
      isModalDisable: false,
      embroidaryOption: this.props.embroidaryOption,
     selectedOption: "",

    };

    const {
      isOpen,
      close,
      embroidaryOption,
      selectedSide,
      onDone,
      embroidaryLocations,
      selectedColor,
      selectedProduct
    } = this.props;

    if(embroidaryOption && embroidaryOption.keys){
      Object.keys(embroidaryOption.keys).map((key:string, idx) => {
        /* if stole then additional logic will be applied */
        if(
          selectedProduct.typeId?.type 
          && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE") 
          && selectedSide == "back"
          && key == "Top"
        ){
          this.updateEmbroidaryLocations(embroidaryOption, key, {
            checked: true/* !(embroidaryLocations[selectedSide] as any)[
              embroidaryOption._id
            ][key].checked */,
          }, false, () => {
            const otherEmbroidaryOptionItems = this.embroidaryOptionItems.filter((itm:any) => itm !== key);
            otherEmbroidaryOptionItems.map((itm:string) => {
              this.updateEmbroidaryLocations(embroidaryOption, itm, {
                checked: false,
              });
            });
          });
        } else {

        }
      });
    }

    this.getOptionValuesForCurrentKey =
      this.getOptionValuesForCurrentKey.bind(this);
  }

  componentDidUpdate(prevProps: Readonly<AdditionalDesignModalProps>, prevState: Readonly<AdditionalDesignModalState>, snapshot?: any): void {
    console.log("componentDidUpdate--------");
    console.log("prevProps : ", prevProps);
    console.log("prevState : ", prevState);
    console.log("snapshot : ", snapshot);
    if(prevProps.embroidaryOption != this.props.embroidaryOption){
      console.log("embroidaryOption changed");
      const {
        embroidaryOption
      } = this.props;

      this.setState({
        embroidaryOption: embroidaryOption
      });
      
      this.embroidaryOptionItems = embroidaryOption && embroidaryOption.keys?Object.keys(embroidaryOption.keys):[];
      console.log("embroidaryOptionItems : ", this.embroidaryOptionItems);


      /* if single item the mark as checked */
      if(this.embroidaryOptionItems.length == 1){
        this.updateEmbroidaryLocations(embroidaryOption, this.embroidaryOptionItems[0], {
          checked: true, 
        });
      }
    }

    if(typeof this.state.embroidaryOption !== undefined){
      this.checkDisabilityForButton();
    }
  }

  getValue(name: string, d: any) {
    if (name === "threadToMatchs") {
      return d.value;
    } else if (name === Embroidary.design) {
      return d.image;
    } else {
      return d._id;
    }
  }

  getOptionValuesForCurrentKey(currentField: any) {
    const { emboidaryOptionValues, selectedSide, selectedProduct } = this.props;
    const {embroidaryOption} = this.state;
    let  name = currentField.name;
    console.log("currentField : ", currentField);
    console.log("name : ", name);
    console.log("emboidaryOptionValues keys : ", Object.keys(emboidaryOptionValues || []));
    console.log("emboidaryOptionValues : ", emboidaryOptionValues);

    let emboidaryOptionValueKeys = Object.keys(emboidaryOptionValues || []);
    name = emboidaryOptionValueKeys.length > 0 && emboidaryOptionValueKeys.filter(itm => name.indexOf(itm) !== -1).length > 0?emboidaryOptionValueKeys.filter(itm => name.indexOf(itm) !== -1).pop():name;
    console.log("getOptionValuesForCurrentKey name : ", name);
    if (/* (emboidaryOptionValues as any)[name] */ emboidaryOptionValueKeys.filter(itm => name.indexOf(itm) !== -1).length > 0) {

      const options = (emboidaryOptionValues as any)[name].map((d: any) => {
        if(d.name.toLowerCase() == "above greek letter"){
          return {
            label: Embroidary.aboveEmbroidery,
            price: typeof d.price !== "undefined"?d.price:0,
            value: this.getValue(name, d),
            priority: d.priority
          };
        } else if(d.name.toLowerCase() == "over greek letter"){
          return {
            label: Embroidary.overEmbroidery,
            price: typeof d.price !== "undefined"?d.price:0,
            value: this.getValue(name, d),
            priority: d.priority
          };
        } else if(d.name.toLowerCase() == "under greek letter"){
          return {
            label: Embroidary.underEmbroidery,
            price: typeof d.price !== "undefined"?d.price:0,
            value: this.getValue(name, d),
            priority: d.priority
          };
        } else {
          return {
            label: d.name,
            price: typeof d.price !== "undefined"?d.price:0,
            value: this.getValue(name, d),
            priority: d.priority
          };
        }
      }).sort((a : any, b:any) => parseInt(a.priority) - parseInt(b.priority));
      console.log("========== options : ", options);
      if (options && Array.isArray(options)) {
        console.log("embd NAME : ", name)
        console.log("options ==:== ", options);
        if (name === Embroidary.embroidaryLocation) {
          console.log("this.props.appliquePreviewData := ", this.props.appliquePreviewData);
          const selectedAppliqueContextText =
            this.props.appliquePreviewData.find((i: any) => {
              return i.settingName === PreviewSettingNames.contentText;
            }).settingVal.value.trim();
            console.log("selectedAppliqueContextText := ", selectedAppliqueContextText);
          if (selectedAppliqueContextText !== "") {
            if(isGreekLetters(selectedAppliqueContextText)){
              return options.filter((i) => {
                return (
                  i.label.includes(this.props.selectedSide) ||
                  i.label === Embroidary.aboveEmbroidery ||
                  i.label === Embroidary.overEmbroidery ||
                  i.label === Embroidary.underEmbroidery ||
                  i.label.toLowerCase().includes("left chest") ||
                  i.label.toLowerCase().includes("left chest align to first greek letter") ||
                  i.label.toLowerCase().includes("right chest") ||
                  i.label.toLowerCase().includes("right chest align to first greek letter")
                );
              })/*.sort(function(a:any, b:any){
                if(a.label < b.label) { return -1; }
                if(a.label > b.label) { return 1; }
                return 0;
              })*/;
            } else if(isNumberLetters(selectedAppliqueContextText)) {
              return options.filter((i) => {
                return (
                  i.label.includes(this.props.selectedSide) ||
                  i.label === Embroidary.underNumber
                );
              });
            } else {
              if(selectedProduct.typeId?.type && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE")){
                return options.filter((i) => {
                  return i.label
                    .toLowerCase()
                    .includes(
                      /*this.props.selectedSide.indexOf("_") !== -1
                        ? this.props.selectedSide.split("_")[1]
                        : this.props.selectedSide*/
                        'above text'
                    ) || i.label
                    .toLowerCase()
                    .includes('under text')
                    || i.label
                    .toLowerCase()
                    .includes('over text');
                });
              } else {
                return options.filter((i) => {
                  return i.label
                    .toLowerCase()
                    .includes(
                      this.props.selectedSide.indexOf("_") !== -1
                        ? this.props.selectedSide.split("_")[1]
                        : this.props.selectedSide
                    );
                });
              }
  
              /*if(selectedOptions.length == 0){
                return options.filter((i) => {
                  return i;
                }).sort(function(a:any, b:any){
                  if(a.label < b.label) { return -1; }
                  if(a.label > b.label) { return 1; }
                  return 0;
                });
              } else {
                return selectedOptions;
              }*/
            }
          } else { 
            console.log("selectedSide ::= ", selectedSide);
            if(selectedSide == "arm_left"){
              console.log("options ::= ", options.filter((i) => {
                return i.label
                  .toLowerCase()
                  .includes(
                    /*this.props.selectedSide.indexOf("_") !== -1
                      ? this.props.selectedSide.split("_")[1]
                      : this.props.selectedSide*/
                      'above text'
                  ) || i.label
                  .toLowerCase()
                  .includes('under text');
                  /* || i.label
                  .toLowerCase()
                  .includes('over text'); */
              }));
              return options.filter((i) => {
                return i.label
                  .toLowerCase()
                  .includes(
                    /*this.props.selectedSide.indexOf("_") !== -1
                      ? this.props.selectedSide.split("_")[1]
                      : this.props.selectedSide*/
                      'above text'
                  ) || i.label
                  .toLowerCase()
                  .includes('under text');
                  /* || i.label
                  .toLowerCase()
                  .includes('over text'); */
              });
            } else if(selectedSide == "arm_right"){
              console.log("options ::= ", options.filter((i) => {
                return i.label
                  .toLowerCase()
                  .includes(
                    /*this.props.selectedSide.indexOf("_") !== -1
                      ? this.props.selectedSide.split("_")[1]
                      : this.props.selectedSide*/
                      'above text'
                  ) || i.label
                  .toLowerCase()
                  .includes('under text');
                  /* || i.label
                  .toLowerCase()
                  .includes('over text'); */
              }));
              return options.filter((i) => {
                return i.label
                  .toLowerCase()
                  .includes(
                    /*this.props.selectedSide.indexOf("_") !== -1
                      ? this.props.selectedSide.split("_")[1]
                      : this.props.selectedSide*/
                      'above text'
                  ) || i.label
                  .toLowerCase()
                  .includes('under text');
                  /* || i.label
                  .toLowerCase()
                  .includes('over text'); */
              });
            } else if(selectedSide == "front"){
              console.log("options ::= ", options.filter((i) => {
                return i.label
                  .toLowerCase()
                  .includes(
                    /*this.props.selectedSide.indexOf("_") !== -1
                      ? this.props.selectedSide.split("_")[1]
                      : this.props.selectedSide*/
                      'left chest'
                  ) || i.label
                  .toLowerCase()
                  .includes('right chest')
                  || i.label
                  .toLowerCase()
                  .includes('center chest');
              }));
              console.log("selectedProduct : ", selectedProduct);
              return options.filter((i) => {
                if(selectedProduct.subCategoryId.name.toLocaleUpperCase().includes("JACKETS")){
                  return i.label
                    .toLowerCase()
                    .includes(
                      /*this.props.selectedSide.indexOf("_") !== -1
                        ? this.props.selectedSide.split("_")[1]
                        : this.props.selectedSide*/
                        'left chest'
                    ) || i.label
                    .toLowerCase()
                    .includes('right chest');
                } else {
                  return i.label
                  .toLowerCase()
                  .includes(
                    /*this.props.selectedSide.indexOf("_") !== -1
                      ? this.props.selectedSide.split("_")[1]
                      : this.props.selectedSide*/
                      'left chest'
                  ) || i.label
                  .toLowerCase()
                  .includes('right chest')
                  || i.label
                  .toLowerCase()
                  .includes('center chest');
                }
              });
            } else if(selectedSide == "back"){
              console.log("options ::= ", options.filter((i) => {
                return i.label
                  .toLowerCase()
                  .includes(
                    /*this.props.selectedSide.indexOf("_") !== -1
                      ? this.props.selectedSide.split("_")[1]
                      : this.props.selectedSide*/
                      'bottom of back'
                  ) || i.label
                  .toLowerCase()
                  .includes('center of back')
                  || i.label
                  .toLowerCase()
                  .includes('top of back');
              }));
              return options.filter((i) => {
                return i.label
                  .toLowerCase()
                  .includes(
                    /*this.props.selectedSide.indexOf("_") !== -1
                      ? this.props.selectedSide.split("_")[1]
                      : this.props.selectedSide*/
                      'bottom of back'
                  ) || i.label
                  .toLowerCase()
                  .includes('center of back')
                  || i.label
                  .toLowerCase()
                  .includes('top of back');
              });
            } else {
              console.log("options ===========: ", options);
              return options.filter((i) => {
                return i.label
                  .toLowerCase()
                  .includes(
                    this.props.selectedSide.indexOf("_") !== -1
                      ? this.props.selectedSide.split("_")[1]
                      : this.props.selectedSide
                  );
              });

              /*if(selectedOptions.length == 0){
                return options.filter((i) => {
                  return i;
                }).sort(function(a:any, b:any){
                  if(a.label < b.label) { return -1; }
                  if(a.label > b.label) { return 1; }
                  return 0;
                });
              } else {
                return selectedOptions;
              }*/
            }
          }
        } else if(name === Embroidary.fontStyles){
          return options.filter((i) => {
            return i.label
              .toLowerCase() != 'college slab';
          });
        }
        return options;
      }
    } else {
      /* get options from the field option itself */
      if(name == "kappaLocations" && typeof currentField["locations"] !== "undefined"){
        /* kappaLocations */
        return currentField["locations"].map((loc:any) => {
          return {
            label: `${loc.label} (${loc.currency}${loc.price})`,
            price: loc.price,
            currency: loc.currency,
            images: loc.images,
            value: loc.name
          }
        });
      }
    }

    return [];
  }
  scrollToSelectedOption = (name:string) => {
    const dropdown = document.getElementById(`dropdown_${name}_${this.props.selectedSide}`) as HTMLSelectElement;
    const selectedIndex = dropdown?.selectedIndex;
    if (selectedIndex !== undefined) {
      dropdown.scrollTop = dropdown.options[selectedIndex].offsetTop;
    }
  };

  renderHeaderText(name: string) {
    return (
      <StyledHeader>
        <div>
          <StyledH2>{name === "Crest and Images(price varies with size and design)"? "Crest and Images ( Price Varies With Size And Design)":name} </StyledH2>
          {/*<p>
            Current View: <b>{pascalCase(displayEmbdName(this.props.selectedSide.toUpperCase()))} </b>
          </p>*/}
        </div>
        <StyledAnchor>
          <StyledLink href="/sz-fonts" target="_blank" style={{fontFamily: "Arial, Helvetica, san-serif"}}>
            View Fonts
          </StyledLink>
          <StyledLink href="/colors" target="_blank">
            View Colors
          </StyledLink >
          <StyledLink href="/size-and-location" target="_blank">
            View Sizes
          </StyledLink>
        </StyledAnchor>
      </StyledHeader>
    );
  }

  getCurrentState(key: string) {
    const { embroidaryLocations, selectedSide } = this.props;
    const {embroidaryOption} = this.state;
    if (embroidaryOption) {
      if (
        Object.keys(embroidaryLocations[selectedSide]).includes(
          embroidaryOption._id
        )
      ) { 
        const selected = (embroidaryLocations[selectedSide] as any)[
          embroidaryOption?._id
        ];
        return selected[key].checked ? true : false;
      }
      return false;
    }
  }

  updateEmbroidaryLocations(
    embroidaryOption: any,
    key: string,
    option: any,
    currentField?: any,
    callBack = () => {}
  ) {
    
    const { selectedSide, embroidaryLocations, emboidaryOptionValues } =
      this.props;
    let temp = {};
    if (
      currentField &&
      (currentField.name === "fontSize" || currentField.name === "fontStyles")
    ) {
      const { textLine1, textLine2, textLine3, ...a } = (
        embroidaryLocations[selectedSide] as any
      )[embroidaryOption._id][key];
      let b = {};
      if (currentField.name === "fontSize") {
        let { label: fontSizeLabel, value: fontSizeValue } = option["fontSize"];
        b = {
          fontSizeLabel: fontSizeLabel.split('"')[0],
          fontSizeValue: fontSizeValue,
          fontStyles: a["fontStyles"].label,
          fontStylesValue: a["fontStyles"].value,
        };
      } else {
        let { label: fontStylesLabel, value: fontStylesValue } =
          option["fontStyles"];
        b = {
          fontSizeLabel: fontStylesLabel,
          fontSizeValue: a["fontSize"].value,
          fontStyles: a["fontSize"].label.split('"')[0],
          fontStylesValue: fontStylesValue,
        };
      }
      console.log("b : ",b);
      if ((b as any)["fontStylesValue"] && (b as any)["fontSizeValue"]) {
        const selectedFontStyle = (
          emboidaryOptionValues as any
        ).fontStyles.find((i: any) => {
          return i._id == (b as any)["fontStylesValue"];
        });
        console.log("selectedFontStyle : ", selectedFontStyle);
        const selectedCharLength = selectedFontStyle.charLength.find((i: any) => {
          return i.fsId === (b as any)["fontSizeValue"];
        });
        const maxLength = typeof selectedCharLength !== "undefined"?selectedCharLength.maxLength:9; //default text length for all testSizes is 9.

        (temp as any)["textLine1"] = textLine1.slice(0, maxLength);
        if (textLine2) {
          (temp as any)["textLine2"] = textLine2.slice(0, maxLength);
        }
        if (textLine3) {
          (temp as any)["textLine3"] = textLine3.slice(0, maxLength);
        }
      }
    }
    /*console.log("updateEmbroidaryLocations - embroidaryLocations", {
      ...embroidaryLocations,
      [selectedSide]: {
        ...embroidaryLocations[selectedSide],
        [embroidaryOption._id]: {
          ...(embroidaryLocations[selectedSide] as any)[embroidaryOption._id],
          [key]: {
            ...(embroidaryLocations[selectedSide] as any)[
              embroidaryOption._id
            ][key],
            ...option,
            ...temp,
          },
        },
      },
    });*/
    console.log("embroidaryOption : ",embroidaryOption, "key : ",key,"option : ",option,"currentField : ",currentField,"callBack : ",callBack);
    this.props.updateEmbroidaryLocations(
      {
        ...embroidaryLocations,
        [selectedSide]: {
          ...embroidaryLocations[selectedSide],
          [embroidaryOption._id]: {
            ...(embroidaryLocations[selectedSide] as any)[embroidaryOption._id],
            [key]: {
              ...(embroidaryLocations[selectedSide] as any)[
                embroidaryOption._id
              ][key],
              ...option,
              ...temp,
            },
          },
        },
      },
      () => {
        const { embroidaryLocations, selectedSide } = this.props;
        for (let i in embroidaryLocations[selectedSide]) {
          for (const [key, value] of Object.entries(
            (embroidaryLocations[selectedSide] as any)[i]
          )) {
            if ((value as any).checked) {
              this.checkValues(value, key);
            } else {
              delete this.selectedData[key];
              this.checkDisabilityForButton();
            }
          }
        }

        callBack();
      }
    );
  }

  checkValues(data: any, selectedKey: string) {
    this.selectedData[selectedKey] = true;
    for (const [key, value] of Object.entries(data)) {
      const label = (value as any).label;
      if (label && label.includes("Select") && key != "checked") {
        this.selectedData[selectedKey] = false;
        break;
      } else if (
        (key == "textLine1" && value == "") ||
        (key == "text" && value == "")
      ) {
        this.selectedData[selectedKey] = false;
        break;
      }
    }
    this.checkDisabilityForButton();
  }

  checkNumber(str: string) {
    return !/\d/.test(str);
  }

  getMaxLength(embroidaryOption: any, key: string) {
    const { embroidaryLocations, selectedSide, emboidaryOptionValues } =
      this.props;
    const { fontStyles, fontSize } = (embroidaryLocations[selectedSide] as any)[
      embroidaryOption._id
    ]
      ? (embroidaryLocations[selectedSide] as any)[embroidaryOption._id][key]
      : { fontStyles: "false", fontSize: "false" };
    if (fontSize && fontStyles) {
      const { value: fontSizeValue } = fontSize;
      const { value: fontStylesValue } = fontStyles;
      if (fontSizeValue && fontStylesValue) {
        const selectedFontStyle = (emboidaryOptionValues as any)[
          "fontStyles"
        ].find((i: any) => {
          return i._id === fontStylesValue;
        });
        const selectedFontSize = selectedFontStyle?.charLength.find(
          (i: any) => {
            return i.fsId === fontSizeValue;
          }
        );
        if (selectedFontSize && selectedFontStyle) {
          return selectedFontSize["maxLength"];
        }
      }
      return undefined;
    }

    return undefined;
  }

  checkDisabilityForButton() {
    //console.log("this.selectedData : ", this.selectedData);
    let status: boolean = false;
    if (Object.keys(this.selectedData).length > 0) {
      for (const i in this.selectedData) {
        if (this.selectedData[i] == false && this.embroidaryOptionItems.indexOf(i) !== -1) {
          status = true;
        }
      }
    } else {
      status = false;
    }
    //console.log("status : ", status);
    //console.log("this.state.isModalDisable : ", this.state.isModalDisable);
    if(this.state.isModalDisable != status){
      //console.log("changed");
      this.setState({
        isModalDisable: status,
      });
    }
  }

  addMoreTextOptions(index:number){
    let index1 = index + 1;
    const {
      selectedSide
    } = this.props;
    const {embroidaryOption} = this.state;
    console.log("embroidaryOption == ", embroidaryOption);
    let embKey = "";
    switch(selectedSide){
      case "arm_left": embKey="Left"; break;
      case "arm_right": embKey="Right"; break;
      case "custom": embKey="Custom"; break;
    }  

    if(embKey != "" && embroidaryOption){
      let embroidaryOptionList = (embroidaryOption as any).keys[embKey];
      /* embroidaryOptionList.push({
          "label": "Text line4",
          "name": "textLine4",
          "inputType": "text"
      }); */

      if(embKey == "Custom"){
        embroidaryOptionList.push({
            "label": "Text line"+index1,
            "name": "textLine"+index1,
            "inputType": "text"
        });
      } else {
        embroidaryOptionList.push({
          "label": "Text line"+index,
          "name": "textLine"+index,
          "inputType": "text"
      });
      }

      this.setState({
        embroidaryOption: {
          ...embroidaryOption,
          keys: {
            ...embroidaryOption.keys,
            [embKey]: embroidaryOptionList
          }
        }
      });
    }
  }

  addMoreLocOptions(index:number){
    const indexNumber = index;
    index = index + 1;
    console.log(index,"index in add more location");
    const {
      selectedSide
    } = this.props;
    const {embroidaryOption} = this.state;
    console.log("embroidaryOption == ", embroidaryOption);
    let embKey = "";
    switch(selectedSide){
      case "custom": embKey="Custom"; break;
    }  

    if(embKey != "" && embroidaryOption){
      let embroidaryOptionList = (embroidaryOption as any).keys[embKey];
      /* embroidaryOptionList.push({
          "label": "Text line4",
          "name": "textLine4",
          "inputType": "text"
      }); */

      if(embKey == "Custom"){

        /* 
        
        {
                "label": "Location",
                "name": "location",
                "inputType": "text"
              },
              {
                  "label": "Font Style",
                  "name": "fontStyles",
                  "inputType": "select",
                  "params": "fontStyle"
              },
              {
                  "label": "Font Size",
                  "name": "fontSize",
                  "inputType": "select",
                  "params": "fontSize"
              },
              {
                  "label": "Thread Color",
                  "name": "threadToMatchs",
                  "inputType": "select",
                  "params": "threadColor"
              },
              {
                  "label": "Text line1",
                  "name": "textLine1",
                  "inputType": "text"
              },
              {
                "label": "Text line2",
                "name": "textLine2",
                "inputType": "text"
              },
              {
                "label": "Text line3",
                "name": "textLine3",
                "inputType": "text"
              } 
                
              */
        embroidaryOptionList.push({
          "label": "Location"+indexNumber,
          "name": "location_"+indexNumber,
          "inputType": "text"
        });
        embroidaryOptionList.push({
          "label": "Font Style"+indexNumber,
          "name": "fontStyles_"+indexNumber,
          "inputType": "select",
          "params" : "fontStyle"
        });
        embroidaryOptionList.push({
          "label": "Font Size"+indexNumber,
          "name": "fontSize_"+indexNumber,
          "inputType": "select",
          "params" : "fontSize"
        });
        embroidaryOptionList.push({
          "label": "Thread Color"+indexNumber,
          "name": "threadToMatchs_"+indexNumber,
          "inputType": "select",
          "params": "threadColor"
        });
        embroidaryOptionList.push({
          "label": "Text line1_"+indexNumber,
          "name": "textLine1_"+indexNumber,
          "inputType": "text"
        });
        embroidaryOptionList.push({
          "label": "Text line2_"+indexNumber,
          "name": "textLine2_"+indexNumber,
          "inputType": "text"
        });
        embroidaryOptionList.push({
          "label": "Text line3_"+indexNumber,
          "name": "textLine3_"+indexNumber,
          "inputType": "text"
        });
      } /* else {
        embroidaryOptionList.push({
          "label": "Text line"+index,
          "name": "textLine"+index,
          "inputType": "text"
      });
      } */

      this.setState({
        embroidaryOption: {
          ...embroidaryOption,
          keys: {
            ...embroidaryOption.keys,
            [embKey]: embroidaryOptionList
          }
        }
      });
    }
  }
  removeTextOption(index:number){
    const {
      selectedSide,
      embroidaryLocations
    } = this.props;
    const {embroidaryOption} = this.state;

    let embKey = "";
    switch(selectedSide){
      case "arm_left": embKey="Left"; break;
      case "arm_right": embKey="Right"; break;
    }

    console.log("removeTextOption index : ", index);

    if(embKey !== "" && embroidaryOption){
      let embroidaryOptionList = (embroidaryOption as any).keys[embKey];
      console.log("embroidaryOptionList : ", embroidaryOptionList);
      /* find index */
      let findDeleteIndex = embroidaryOptionList.findIndex((itm:any) => itm.name == "textLine"+index);
      console.log("findDeleteIndex : ", findDeleteIndex);
      let deletedItem = embroidaryOptionList.splice(findDeleteIndex, 1).pop();
      console.log("deletedItem: ", deletedItem);
      this.setState({
        embroidaryOption: {
          ...embroidaryOption,
          keys: {
            ...embroidaryOption.keys,
            [embKey]: embroidaryOptionList
          }
        }
      }, () => {
        console.log("embroidaryOption afer update : ",embroidaryOption);
        let embroidaryLocationsData = embroidaryLocations;
        delete (embroidaryLocationsData[selectedSide] as any)[
          embroidaryOption._id
        ][embKey][deletedItem.name];
        delete (embroidaryLocationsData[selectedSide] as any)[
          embroidaryOption._id
        ][embKey][deletedItem.name+"_actual"];
        /* update deleted option value to blank */
        this.updateEmbroidaryLocations(
          embroidaryOption,
          embKey,
          (embroidaryLocationsData[selectedSide] as any)[
            embroidaryOption._id
          ][embKey],
          deletedItem,
          () => {
            
          }
        )
      });
    }
  }

  removeLocOption(index:number){
    const {
      selectedSide,
      embroidaryLocations
    } = this.props;
    const {embroidaryOption} = this.state;

    let embKey = "";
    switch(selectedSide){
      case "custom": embKey="Custom"; break;
    }

    console.log("removeTextOption index : ", index);

    if(embKey !== "" && embroidaryOption){
      let embroidaryOptionList = (embroidaryOption as any).keys[embKey];
      console.log("embroidaryOptionList : ", embroidaryOptionList);

      /* find index */
      let findLocDeleteIndex = embroidaryOptionList.findIndex((itm:any) => itm.name == "location_"+index);
      console.log("findLocDeleteIndex : ", findLocDeleteIndex);
      let deletedLocItem = embroidaryOptionList.splice(findLocDeleteIndex, 1).pop();

      let findfStyleDeleteIndex = embroidaryOptionList.findIndex((itm:any) => itm.name == "fontStyles_"+index);
      console.log("findfStyleDeleteIndex : ", findfStyleDeleteIndex);
      let deletedfStyleItem = embroidaryOptionList.splice(findfStyleDeleteIndex, 1).pop();

      let findfSizeDeleteIndex = embroidaryOptionList.findIndex((itm:any) => itm.name == "fontSize_"+index);
      console.log("findfSizeDeleteIndex : ", findfSizeDeleteIndex);
      let deletedfSizeItem = embroidaryOptionList.splice(findfStyleDeleteIndex, 1).pop();

      let findThreadToMatchDeleteIndex = embroidaryOptionList.findIndex((itm:any) => itm.name == "threadToMatchs_"+index);
      console.log("findThreadToMatchDeleteIndex : ", findThreadToMatchDeleteIndex);
      let deletedThreadToMatchItem = embroidaryOptionList.splice(findThreadToMatchDeleteIndex, 1).pop();

      let findtextLine1DeleteIndex = embroidaryOptionList.findIndex((itm:any) => itm.name == "textLine1_"+index);
      console.log("findtextLine1DeleteIndex : ", findtextLine1DeleteIndex);
      let deletedtextLine1Item = embroidaryOptionList.splice(findtextLine1DeleteIndex, 1).pop();

      let findtextLine2DeleteIndex = embroidaryOptionList.findIndex((itm:any) => itm.name == "textLine2_"+index);
      console.log("findtextLine2DeleteIndex : ", findtextLine2DeleteIndex);
      let deletedtextLine2Item = embroidaryOptionList.splice(findtextLine2DeleteIndex, 1).pop();

      let findtextLine3DeleteIndex = embroidaryOptionList.findIndex((itm:any) => itm.name == "textLine3_"+index);
      console.log("findtextLine3DeleteIndex : ", findtextLine3DeleteIndex);
      let deletedtextLine3Item = embroidaryOptionList.splice(findtextLine3DeleteIndex, 1).pop();

      //console.log("deletedItem: ", deletedItem);
      this.setState({
        embroidaryOption: {
          ...embroidaryOption,
          keys: {
            ...embroidaryOption.keys,
            [embKey]: embroidaryOptionList
          }
        }
      }, () => {
        console.log("embroidaryOption afer update : ",embroidaryOption);
        let embroidaryLocationsData = embroidaryLocations;
        delete (embroidaryLocationsData[selectedSide] as any)[
          embroidaryOption._id
        ][embKey][deletedLocItem.name];
        delete (embroidaryLocationsData[selectedSide] as any)[
          embroidaryOption._id
        ][embKey][deletedfStyleItem.name];
        delete (embroidaryLocationsData[selectedSide] as any)[
          embroidaryOption._id
        ][embKey][deletedfSizeItem.name];
        delete (embroidaryLocationsData[selectedSide] as any)[
          embroidaryOption._id
        ][embKey][deletedThreadToMatchItem.name];
        delete (embroidaryLocationsData[selectedSide] as any)[
          embroidaryOption._id
        ][embKey][deletedtextLine1Item.name];
        delete (embroidaryLocationsData[selectedSide] as any)[
          embroidaryOption._id
        ][embKey][deletedtextLine1Item.name+"_actual"];
        delete (embroidaryLocationsData[selectedSide] as any)[
          embroidaryOption._id
        ][embKey][deletedtextLine2Item.name];
        delete (embroidaryLocationsData[selectedSide] as any)[
          embroidaryOption._id
        ][embKey][deletedtextLine2Item.name+"_actual"];
        delete (embroidaryLocationsData[selectedSide] as any)[
          embroidaryOption._id
        ][embKey][deletedtextLine3Item.name];
        delete (embroidaryLocationsData[selectedSide] as any)[
          embroidaryOption._id
        ][embKey][deletedtextLine3Item.name+"_actual"];
        /* update deleted option value to blank */
        this.updateEmbroidaryLocations(
          embroidaryOption,
          embKey,
          (embroidaryLocationsData[selectedSide] as any)[
            embroidaryOption._id
          ][embKey],
          null,
          () => {
            
          }
        )
      });
    }
  }

  render(): React.ReactNode {
    const {
      isOpen,
      close,
      //embroidaryOption,
      selectedSide,
      onDone,
      embroidaryLocations,
      selectedColor,
      selectedProduct
    } = this.props;
    const {embroidaryOption} = this.state;

    console.log("embroidaryLocations :::=>> ", embroidaryLocations);
    /*console.log("selectedSide : ", selectedSide);
    console.log("embroidaryLocations : ", embroidaryLocations);
    console.log("embroidaryOption : ", embroidaryOption);
    console.log(
      "embroidaryLocations[selectedSide] : ",
      embroidaryLocations[selectedSide]
    );*/
    
    console.log("= : embroidaryOption : ", embroidaryOption);
    //const embroidaryOptionItems = embroidaryOption && embroidaryOption.keys?Object.keys(embroidaryOption.keys):[];
    console.log("embroidaryOptionItems : ", this.embroidaryOptionItems);

    


    return (
      <Modal
        isOpen={isOpen}
        close={close}
        headerText={this.renderHeaderText(embroidaryOption?.name || "")}
        width={"984px"}
        disabled={this.state.isModalDisable}
        okCallback={() => {
          onDone(
            this.props.embroidaryLocations[selectedSide],
            this.props.embroidaryOption
          );
          close();
        }}
        cancelText={"Cancel"}
        overlayStyle={{ zIndex: "1061" }}
      >
        {embroidaryOption && embroidaryOption.keys && (
          <div style={{ display: "flex", flexFlow: "row wrap" }}>
            {Object.keys(embroidaryOption.keys).map((key:string, idx) => {
              /* if(selectedSide == "collar" && key == "On_Collar_Flipper_Up"){
                return false;
              } else if(selectedSide == "collar_flipper_up" && key == "On_Collar"){
                return false;
              } */ 

              if(
                selectedProduct.typeId?.type 
                && selectedProduct.typeId?.type.toLocaleUpperCase().includes("STOLE") 
                && selectedSide === "back"
                && key === "Bottom"
              ){
                return false;
              }

              let value = embroidaryOption.keys[key];
              let textOptionCount = 0;
              let locOptionCount = 0;
              
              console.log("key : ", key);
              console.log("value : ", value);

              let image = "";

              if (
                (embroidaryLocations[selectedSide] as any)[
                  embroidaryOption._id
                ] &&
                (embroidaryLocations[selectedSide] as any)[
                  embroidaryOption._id
                ][key] &&
                (embroidaryLocations[selectedSide] as any)[
                  embroidaryOption._id
                ][key][Embroidary.design] &&
                (embroidaryLocations[selectedSide] as any)[
                  embroidaryOption._id
                ][key][Embroidary.design].value !== ""
              ) {
                const selectedEmDesign = (
                  this.props.emboidaryOptionValues as any
                )[Embroidary.design].find((i: any) => {
                  return (
                    i.name ===
                    (embroidaryLocations[selectedSide] as any)[
                      embroidaryOption._id
                    ][key][Embroidary.design].label
                  );
                });
                if (selectedEmDesign) {
                  image = selectedEmDesign.image;
                }
              } else if(
                (embroidaryLocations[selectedSide] as any)[
                  embroidaryOption._id
                ] &&
                (embroidaryLocations[selectedSide] as any)[
                  embroidaryOption._id
                ][key] &&
                (embroidaryLocations[selectedSide] as any)[
                  embroidaryOption._id
                ][key]["kappaLocations"] &&
                (embroidaryLocations[selectedSide] as any)[
                  embroidaryOption._id
                ][key]["kappaLocations"].images !== ""
              ){
                image = "https://favorsbythebay.com/apis/"+(embroidaryLocations[selectedSide] as any)[
                  embroidaryOption._id
                ][key]["kappaLocations"].images;
              } else {
                image =
                  (embroidaryOption.images as any)[idx] &&
                  (embroidaryOption.images as any)[idx].includes("http")
                    ? (embroidaryOption.images as any)[idx]
                    : (selectedColor as any).images[
                        (embroidaryOption.images as any)[key]
                      ];
              }

              switch(key){
                case "Over_Kappa": 
                  image = image == ""?"https://favorsbythebay.com/apis/1677613201337over_top_left_kappa.jpg":image;
                break;
                case "Top": 
                  image = "http://favorsbythebay.com/apis/top_of_back.gif";
                break;
                case "UnderNumber": 
                  image = "http://favorsbythebay.com/apis/under_number.gif";
                break;
                case "Bottom": 
                  image = "http://favorsbythebay.com/apis/bottom_of_back.gif";
                break;
                case "Left":
                  if(selectedSide == "cuff_left"){
                    image = "http://favorsbythebay.com/apis/cuff.jpg";
                  } else if(selectedSide == "front"){
                    image = "https://admin.favorsbythebay.com/apis/1742292448303left_chest.png";
                  } else {
                    image = "http://favorsbythebay.com/apis/left_arm.jpg";
                  }
                break;
                case "Right":
                  if(selectedSide == "cuff_right"){
                    image = "http://favorsbythebay.com/apis/cuff.jpg";
                  } else if(selectedSide == "front"){
                    image = "https://admin.favorsbythebay.com/apis/1742292449404right_chest.png";
                  } else  {
                    image = "http://favorsbythebay.com/apis/right_arm.jpg"
                  }
                break;
                case "On_Collar":
                  image = "http://favorsbythebay.com/apis/on_back_collar.jpg";
                break;
                case "On_Collar_Flipped_Up":
                  image = "http://favorsbythebay.com/apis/CollerFlippedUp.jpeg";
                break;
              }

              console.log("image : ", image);
              const selectedAppliqueContextText =
                this.props.appliquePreviewData.find((i: any) => {
                  return i.settingName === PreviewSettingNames.contentText;
                });

              // if (
              //   key !== PreviewEmbroidarySettingNames.Design_1 &&
              //   key !== PreviewEmbroidarySettingNames.Design_2
              // ) {
              //   if (selectedSide.includes("arm")) {
              //     if (!selectedSide.includes(key.toLowerCase())) {
              //       return;
              //     }
              //   }
              // }

              if (
                selectedAppliqueContextText ||
                this.props.appliquePreviewData.length === 0
              ) {
                if (
                  key === Embroidary_Locations.Over_Kappa &&
                  selectedAppliqueContextText.settingVal.value
                    .trim()
                    .indexOf(GREEK_ALPHABETS.Kappa) === -1
                ) {
                  return;
                }
                if (
                  key === Embroidary_Locations.UnderNumber &&
                  this.checkNumber(
                    selectedAppliqueContextText.settingVal.value.trim()
                  )
                ) {
                  return;
                }
              }
              console.log("key : ", key);
              console.log("selectedSide : ", selectedSide);
              console.log("embroidaryOption : ", embroidaryOption);
              console.log("embroidaryLocations[selectedSide] : ", embroidaryLocations[selectedSide]);
              let optionName = key.replace(/_/g," ");
              switch(true){
                case optionName == Embroidary_Locations.UnderNumber:  optionName = "Under Number"; break;
              }

              let showAddLocation = false;
              let addMoreText = false;

              return (
                <ContentWrapper key={key} style={{ flex: "50%" }}>
                  {this.embroidaryOptionItems.length > 0 && (selectedSide !== "hood" ?<Checkbox
                    name={"embdOptCheck"}
                    label={optionName}
                    checked={this.getCurrentState(key)}
                    onChange={() =>
                      this.updateEmbroidaryLocations(embroidaryOption, key, {
                        checked: !(embroidaryLocations[selectedSide] as any)[
                          embroidaryOption._id
                        ][key].checked,
                      })
                    }
                    
                  />:<RadioButton
                    name={"embdOptChoose"}
                    label={optionName}
                    checked={this.getCurrentState(key)}
                    /* onChange={() =>
                      this.updateEmbroidaryLocations(embroidaryOption, key, {
                        checked: !(embroidaryLocations[selectedSide] as any)[
                          embroidaryOption._id
                        ][key].checked,
                      })
                    } */
                    onClick={(e:any) => {
                      console.log(e.target.checked);
                      this.updateEmbroidaryLocations(embroidaryOption, key, {
                        checked: true/* !(embroidaryLocations[selectedSide] as any)[
                          embroidaryOption._id
                        ][key].checked */,
                      }, false, () => {
                        const otherEmbroidaryOptionItems = this.embroidaryOptionItems.filter((itm:any) => itm !== key);
                        otherEmbroidaryOptionItems.map((itm:string) => {
                          this.updateEmbroidaryLocations(embroidaryOption, itm, {
                            checked: false,
                          });
                        });
                      });
                    }}
                  />)}
                  <ContentContainer>
                    {image !="" && selectedSide!= "custom" && <Content>
                      <DisplayImage src={image} alt="" />
                    </Content>}
                    <Content marginL>
                      {value.map((currentField:any, fieldIndex: string) => {
                        let removeHTMLLink:any = "";
                        let addDiv :any = "";
                        if(parseInt(fieldIndex) === 0){
                          // addDiv="<div>"
                        }
                        if((parseInt(fieldIndex)%7 == 0 || locOptionCount == 0) && parseInt(fieldIndex) > 0 && selectedSide === "custom"){
                          locOptionCount++;
                          if(parseInt(fieldIndex) === value.length-1|| value.length ===8 ){
                            showAddLocation = true;
                          }
                        } 
                        if(((parseInt(fieldIndex) !== 1)&& (parseInt(fieldIndex) - 1)%7 == 0 || locOptionCount == 0) && parseInt(fieldIndex) > 0 && selectedSide === "custom"){
                          
                          // addDiv="</div><div>" ;
                          addDiv=<GroupInput/> ;
                        }
                        removeHTMLLink = <RemoveBtn onClick={() => this.removeLocOption(locOptionCount)}>Remove locaion</RemoveBtn>;
                        //return removeHTMLLink;
                        if((selectedSide === "arm_left"|| selectedSide==="arm_right") && (parseInt(fieldIndex) === value.length-1 || (value.length === 7 && 5=== parseInt(fieldIndex)  ) )){
                          addMoreText=true;
                        }
                        if (
                          currentField.inputType === EmboidaryInputType.Select
                        ) 
                        {
                          return (
                            <>
                            {addDiv}
                            
                            <StyledDropdown
                              key={`currentField_${fieldIndex}`}
                              placeholder={currentField.label}
                              options={[
                                {
                                  label: `Select ${currentField.label}`,
                                  value: "",
                                },
                                ...this.getOptionValuesForCurrentKey(
                                  currentField
                                ),
                              ]}
                              onChange={(selectedOption: any) =>{
                                this.setState({ selectedOption });

                                // Save the selected option to sessionStorage (session persists only during the session)
                                sessionStorage.setItem(`selectedOption_${currentField.name}_${this.props.selectedSide}`, JSON.stringify(selectedOption));
                            
                                this.updateEmbroidaryLocations(
                                  embroidaryOption,
                                  key,
                                  {
                                    [currentField.name]: selectedOption,
                                  },
                                  currentField
                                )
                              }
                            }
                              value={
                                (embroidaryLocations[selectedSide] as any)[
                                  embroidaryOption._id
                                ] &&
                                (embroidaryLocations[selectedSide] as any)[
                                  embroidaryOption._id
                                ][key][currentField.name]
                              }
                              onFocus={()=>this.scrollToSelectedOption(currentField.name)}
                              disabled={!this.getCurrentState(key)}
                            />
                            </>
                          );
                        } else if (
                          currentField.inputType === EmboidaryInputType.Text
                        ) {
                          textOptionCount++;
                          return (<>
                          {addDiv}
                            <InputContainer key={`currentField_${fieldIndex}`}>
                              <Input
                                disabled={!this.getCurrentState(key)}
                                type="text"
                                placeholder={currentField.label}
                                maxLength={this.getMaxLength(
                                  embroidaryOption,
                                  key
                                )}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                  if(selectedSide === "custom"){
                                    console.log("currentField.name : ", currentField.name);
                                    console.log("key : ", key);
                                    console.log("this.getMaxLength(embroidaryOption, key) : ", this.getMaxLength(embroidaryOption, key));
                                    if(currentField.name.indexOf("textLine1") !== -1){
                                      let fnm = currentField.name.replace("textLine1", "");
                                      this.updateEmbroidaryLocations(
                                        embroidaryOption,
                                        key,
                                        {
                                          [currentField.name]: e.target.value,
                                          ["textLine2"+fnm]: (embroidaryLocations[selectedSide] as any)[
                                            embroidaryOption._id
                                          ] &&
                                          (embroidaryLocations[selectedSide] as any)[
                                            embroidaryOption._id
                                          ][key]["textLine2"+fnm]?(embroidaryLocations[selectedSide] as any)[
                                            embroidaryOption._id
                                          ] &&
                                          (embroidaryLocations[selectedSide] as any)[
                                            embroidaryOption._id
                                          ][key]["textLine2"+fnm]:"",
                                          ["textLine3"+fnm]: (embroidaryLocations[selectedSide] as any)[
                                            embroidaryOption._id
                                          ] &&
                                          (embroidaryLocations[selectedSide] as any)[
                                            embroidaryOption._id
                                          ][key]["textLine3"+fnm]?(embroidaryLocations[selectedSide] as any)[
                                            embroidaryOption._id
                                          ] &&
                                          (embroidaryLocations[selectedSide] as any)[
                                            embroidaryOption._id
                                          ][key]["textLine3"+fnm]:""
                                        },
                                        currentField,
                                        () => {
                                          
    
                                        }
                                      );

                                      
                                      
                                    } else if(currentField.name.indexOf("textLine2") !== -1){
                                      let fnm = currentField.name.replace("textLine2", "");
                                      this.updateEmbroidaryLocations(
                                        embroidaryOption,
                                        key,
                                        {
                                          [currentField.name]: e.target.value,
                                          ["textLine1"+fnm]: (embroidaryLocations[selectedSide] as any)[
                                            embroidaryOption._id
                                          ] &&
                                          (embroidaryLocations[selectedSide] as any)[
                                            embroidaryOption._id
                                          ][key]["textLine1"+fnm]?(embroidaryLocations[selectedSide] as any)[
                                            embroidaryOption._id
                                          ] &&
                                          (embroidaryLocations[selectedSide] as any)[
                                            embroidaryOption._id
                                          ][key]["textLine1"+fnm]:"",
                                          ["textLine3"+fnm]: (embroidaryLocations[selectedSide] as any)[
                                            embroidaryOption._id
                                          ] &&
                                          (embroidaryLocations[selectedSide] as any)[
                                            embroidaryOption._id
                                          ][key]["textLine3"+fnm]?(embroidaryLocations[selectedSide] as any)[
                                            embroidaryOption._id
                                          ] &&
                                          (embroidaryLocations[selectedSide] as any)[
                                            embroidaryOption._id
                                          ][key]["textLine3"+fnm]:""
                                        },
                                        currentField,
                                        () => {
                                          
                                        }
                                      );
                                    } else if(currentField.name.indexOf("textLine3") !== -1){
                                      let fnm = currentField.name.replace("textLine3", "");
                                      this.updateEmbroidaryLocations(
                                        embroidaryOption,
                                        key,
                                        {
                                          [currentField.name]: e.target.value,
                                          ["textLine1"+fnm]: (embroidaryLocations[selectedSide] as any)[
                                            embroidaryOption._id
                                          ] &&
                                          (embroidaryLocations[selectedSide] as any)[
                                            embroidaryOption._id
                                          ][key]["textLine1"+fnm]?(embroidaryLocations[selectedSide] as any)[
                                            embroidaryOption._id
                                          ] &&
                                          (embroidaryLocations[selectedSide] as any)[
                                            embroidaryOption._id
                                          ][key]["textLine1"+fnm]:"",
                                          ["textLine2"+fnm]: (embroidaryLocations[selectedSide] as any)[
                                            embroidaryOption._id
                                          ] &&
                                          (embroidaryLocations[selectedSide] as any)[
                                            embroidaryOption._id
                                          ][key]["textLine2"+fnm]?(embroidaryLocations[selectedSide] as any)[
                                            embroidaryOption._id
                                          ] &&
                                          (embroidaryLocations[selectedSide] as any)[
                                            embroidaryOption._id
                                          ][key]["textLine2"+fnm]:""
                                        },
                                        currentField,
                                        () => {
                                          
                                        }
                                      );
                                    } else {
                                      this.updateEmbroidaryLocations(
                                        embroidaryOption,
                                        key,
                                        {
                                          [currentField.name]: e.target.value.slice(
                                            0,
                                            this.getMaxLength(embroidaryOption, key)
                                          ),
                                          /* [currentField.name+"_actual"]: e.target.value.slice(
                                            0,
                                            this.getMaxLength(embroidaryOption, key)
                                          ) */
                                        },
                                        currentField,
                                        () => {
                                          
                                        }
                                      );
                                    }
                                  } else {
                                    this.updateEmbroidaryLocations(
                                      embroidaryOption,
                                      key,
                                      {
                                        [currentField.name]: (selectedSide == "hood" && key == "Hood_Forward")?e.target.value.slice(
                                          0,
                                          this.getMaxLength(embroidaryOption, key)
                                        ):e.target.value.slice(
                                          0,
                                          this.getMaxLength(embroidaryOption, key)
                                        ),
                                        [currentField.name+"_actual"]: e.target.value.slice(
                                          0,
                                          this.getMaxLength(embroidaryOption, key)
                                        )
                                      },
                                      currentField,
                                      () => {
                                        
                                      }
                                    );
                                  }
                                }}
                                value={
                                  (embroidaryLocations[selectedSide] as any)[
                                    embroidaryOption._id
                                  ] &&
                                  (embroidaryLocations[selectedSide] as any)[
                                    embroidaryOption._id
                                  ][key][currentField.name]?((selectedSide == "hood" && key == "Hood_Forward")?(embroidaryLocations[selectedSide] as any)[
                                    embroidaryOption._id
                                  ][key][currentField.name+"_actual"]:(embroidaryLocations[selectedSide] as any)[
                                    embroidaryOption._id
                                  ][key][currentField.name]):""
                                }
                              />
                              {(selectedSide === "arm_left" || selectedSide === "arm_right") && 5< parseInt(fieldIndex)  && <RemoveBtn onClick={() => this.removeTextOption(textOptionCount)}>Remove</RemoveBtn>}
                            {(selectedSide === "arm_left"  || selectedSide === "arm_right") && addMoreText && key != "Design_#1" && key != "Design_#2" && <AddMoreBtn onClick={() => this.addMoreTextOptions(textOptionCount)}>Add more</AddMoreBtn>}
                              
                            </InputContainer>
                            {selectedSide === "custom" && locOptionCount > 1 && currentField.label.toLowerCase().indexOf("text line3") > -1?removeHTMLLink:''}
                            {selectedSide === "custom" && showAddLocation && currentField.label.toLowerCase().indexOf("text line3") > -1 && <> <AddMoreBtn onClick={() => this.addMoreLocOptions(locOptionCount)}>Add more locations</AddMoreBtn> </>}
                            </>
                          );
                        } else if (
                          currentField.inputType === EmboidaryInputType.TextArea
                        ) {
                          showAddLocation = false
                        } else {
                          return <></>;
                        }

                        
                        
                      })}
                      
                      {value.map((currentField:any, fieldIndex: string) => {
                        if (
                          currentField.inputType === EmboidaryInputType.TextArea
                        ) {
                          textOptionCount++;
                          return (<>
                            <InputContainer key={`currentField_${fieldIndex}`}>
                              <Textarea 
                                rows={5} 
                                
                                style={{width:"100%",    resize: "vertical"}}
                                disabled={!this.getCurrentState(key)}
                                placeholder={currentField.label}
                                maxLength={100}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                  
                                    this.updateEmbroidaryLocations(
                                      embroidaryOption,
                                      key,
                                      {
                                        [currentField.name]: e.target.value.slice(
                                          0,
                                          100
                                        ),
                                        [currentField.name+"_actual"]: e.target.value.slice(
                                          0,
                                          100
                                        )
                                      },
                                      currentField,
                                      () => {
                                        
                                      }
                                    );
                                  
                                }}
                                value={
                                  (embroidaryLocations[selectedSide] as any)[
                                    embroidaryOption._id
                                  ] &&
                                  (embroidaryLocations[selectedSide] as any)[
                                    embroidaryOption._id
                                  ][key][currentField.name]?(embroidaryLocations[selectedSide] as any)[
                                    embroidaryOption._id
                                  ][key][currentField.name]:""
                                }
                              />
                            </InputContainer>
                          </>
                          );
                        }
                      })}
                    </Content>
                  </ContentContainer>
                </ContentWrapper>
              );
            })}
          </div>
        )}
      </Modal>
    );
  }
}

const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ContentWrapper = styled.div`
  width: 50%;
`;

const ContentContainer = styled.div`
  display: flex;
`;

const Content = styled.div<{ marginL?: boolean }>`
  margin-left: ${(p) => (p.marginL ? marginM : 0)};
  .dropdown__control {
    margin-bottom: ${marginS};
  }
`;

// Keyframes for moving the background
const barAnimation = keyframes`
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 200%;
  }
`;

// Styled component for <b> with .hr and .anim classes
const GroupInput = styled.b`
  width: 100%;
  height: 0.5em;
  display: block;
  position: relative;
  margin-bottom: 0em;
  padding: 0.5em 0;

  /* Ensure the pseudo-elements are in place */
  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: 50%;
    left: 0;
  }

  /* Initial background styles for :before */
  &:before {
  background: black;
   // background: linear-gradient(90deg,rgb(20, 28, 134) 0%, #10111F 50%, transparent 50%, transparent 100%);
    background-size: 15px;
    background-position: center;
    z-index: 1;
  }

  /* The animated line effect in :after */
  &:after {
    //    background: linear-gradient(
    //   to right,
    //   rgb(20, 47, 142) 0%, 
    //   rgb(247, 247, 248) 10%,  
    //   rgb(20, 47, 142) 10%, 
    //   rgb(247, 247, 248) 30%, 
    //   rgb(20, 47, 142) 30%,
    //   rgb(247, 247, 248) 50%, 
    //   rgb(20, 47, 142) 50%,  
    //   rgb(247, 247, 248) 70%, 
    //   rgb(20, 47, 142) 70%,  
    //   rgb(247, 247, 248) 90%,  
    //   rgb(20, 47, 142) 90%,
    //   rgb(247, 247, 248) 100% 
    // );
    background-size: 200%;
    background-position: 0%;
    z-index: 1;
    // animation: ${barAnimation} 10s linear infinite;
  }

  /* Hover effect to speed up or modify the animation */
  &:hover:before {
    animation-duration: 2s;
  }

  &:hover:after {
    animation-duration: 2s;
  }
`;



const DisplayImage = styled.img`
  width: 160px;
  height: 160px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const InputContainer = styled.div`
  margin-top: ${marginS};
  width:100%;
`;

const StyledDropdown = styled(Dropdown)<{ disabled: boolean }>`
  margin-top: ${marginS};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "inherit")};

  & .dropdown__control {
    border: ${({ disabled }) =>
      disabled
        ? "1px solid rgb(232, 236, 242)"
        : "1px solid rgb(186, 198, 208)"};
    background: ${({ disabled }) =>
      disabled ? "rgb(239, 239, 239)" : "rgb(255, 255, 255)"};
  }
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

const StyledAnchor = styled.div`
  a {
    margin-right: 20px;
    color: rgb(20, 47, 142);
    font-weight: bold;
    font-family: Arial, Helvetica, san-serif;
  }
`;

const RemoveBtn = styled.button`
  margin-top: 5px;
  color:white;
  cursor: pointer;
  margin-right: ${marginS};
  background: red 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  padding: ${paddingS} ${paddingM};
`;

const AddMoreBtn = styled.button`
  margin-top: 5px;
  cursor: pointer;
  margin-right: ${marginS};
  background: green 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  padding: ${paddingS} ${paddingM};
  color: #ffffff;
`;
const StyledLink = styled.a`
font-family: Arial, Helvetica, san-serif;
`
const StyledH2 = styled.h2`
font-family: Arial, Helvetica, san-serif;
`