import React, { useEffect } from "react";
import { Dispatch } from "redux";
import User from "../../components/svgIcons/User";
import Edit from "../../components/svgIcons/Edit";
import { IAppState, IHeaderState, megaMenuData } from "../../models";
import { primaryColor } from "../../constants";

import { headerClient } from "../../ApiClients/HeaderClient";
import {
  Container,
  Logo,
  HeaderActions,
  CategoryLinks,
  DropDown,
  ProfileDropDown,
  SubContainer,
  LinkList,
  BtnPrimary,
  BtnSecondary,
  ListDropdown,
  List,
  ExploreBtn,
  MegaMenuContainer,
  LogoContainer,
  CategoryHeaderLinks,
} from "./styled.component";
import { connect } from "react-redux";
import SZLogo from "../../images/stitchzone_logo_slogan_white.png";
import SubCategoryList from "../MegaMenu/SubCategoryList";
import MegaMenu from "../MegaMenu/MegaMenu";
import { authCookieName } from "../../constants";
import {getCookie, deleteCookie} from "../../Utils/common";

interface HeaderProps {
  header: IHeaderState;
  profileData: any;
  getHeader(): void;
  //lines added for mega menu.
  getMegaMenu(): void;
}

interface HeaderState {
  menu:megaMenuData,
  hoveredCategory:string,
  hoveredRefference:string,
  isLoggedIn: boolean
}


export class HeaderComponent extends React.Component<HeaderProps,HeaderState> {
  constructor(props: HeaderProps) {
    super(props);
    this.state = {
      menu: {
        _id: '',
        name: '',
        image: '',
        appliqueIds: [],
        priority: 0,
        subcategories: [{
          _id:"",
          name:"",
          categoryId:"",
          image:"",
          typeId:[],
          priority: 0,
          active: false,
          createdAt:"",
          updatedAt:"",
          __v: 0,
          products:[
            {
              _id: '',
              categoryId: '',
              cName: '',
              sName: '',
              subCategoryId: '',
              name: '',
              price: 0,
              currency: '',
              totalQuantity: 0,
              clearance: false,
              colorName: '',
              images: {
                front: '',
                back: '',
                cuff_left: '',
                cuff_right: '',
                arm_left: '',
                arm_right: '',
                collar: '',
                collar_flipper_up: '',
              },
            }
          ],
        }]
    },
    hoveredCategory:"",
    hoveredRefference:"",
    isLoggedIn: false
    };
  }

  componentDidMount(): void {
    this.props.getHeader();
    //lines added for mega menu.
    this.props.getMegaMenu();
  }

  
  logout = ():void => {
    localStorage.removeItem('userDetails');
    /* remove cookie authDetails */
    deleteCookie(authCookieName);
    window.location.href="/login";
  }
  componentDidUpdate(prevProps: Readonly<HeaderProps>, prevState: Readonly<{}>, snapshot?: any): void {
    const sortedMegaMenu = JSON.parse(JSON.stringify(this.props.header.megaMenu));
          // Sort subcategories by priority
          sortedMegaMenu.forEach((category :megaMenuData) => {
            category.subcategories.sort((a, b) => a.priority - b.priority);
      
            // Sort products within each subcategory by price
            category.subcategories.forEach(subcategory => {
              subcategory.products.sort((a, b) => a.price - b.price);
            });
          });
      
          // Set the sorted megaMenu back to props.header.megaMenu
          this.props.header.megaMenu = sortedMegaMenu;
  }
  hoveredCategoryUpdate(subCategory:string):void{
    this.setState({
     ...this.state,
      hoveredCategory: subCategory
    })
  }
  hoveredRefferenceUpdate(subCategory:string):void{
    this.setState({
     ...this.state,
      hoveredRefference: subCategory
    })
  }
  hoverLoginCheck(){
    if(getCookie(authCookieName) != null && localStorage.getItem("userDetails") != null){
      this.setState({
        ...this.state,
        isLoggedIn: true
      });
    }
  }

  render() {
    return (
      <>
        <Container bgColor={this.props.header.headerBgColor}>
          <a href="/" style={{margin:"10px 10px 10px 5px"}}>
            <Logo src={SZLogo} alt="stitch zone logo" />
          </a>
          
          <HeaderActions>
            {this.props.header.categories.map((category) => {
              return (
                <MegaMenuContainer
                onMouseEnter={() => this.hoveredCategoryUpdate(category.name)}
                onMouseLeave={() => this.hoveredCategoryUpdate("")}                
                >
                <CategoryHeaderLinks
                  color={this.props.header.headerFontColor}
                  href={`/catalog/${category._id}/subcategories`}
                  key={category._id}
                  selected={(window.location.pathname.indexOf(category._id) !== -1 && this.state.hoveredCategory ==="") || this.state.hoveredCategory ===category.name ?true:false}
                >
                  {category.name}
                </CategoryHeaderLinks>
                <MegaMenu menu= {this.props.header.megaMenu.find(item => item.name === category.name) || this.state.menu} id={category._id||""}/>
            </MegaMenuContainer>
              );
            })}
            <CategoryHeaderLinks
              color={this.props.header.headerFontColor}
              href={`/clearance`}
              key="clearance"
              selected={window.location.pathname.indexOf("clearance") !== -1?true:false}
            >
              Clearance
            </CategoryHeaderLinks>
            <CategoryHeaderLinks
              color={this.props.header.headerFontColor}
              href={`#`}
              key="refernce"
              selected={['fundraise', 'specifications', 'design', 'fonts', 'colors', 'size-and-location', 'contact'].indexOf(window.location.pathname.replace("/", "")) !== -1?true:false}
            >
              References
              <DropDown style={{width: "150px"}}>
                <CategoryLinks color={primaryColor} onMouseEnter={() => this.hoveredRefferenceUpdate("fundraise")}
                onMouseLeave={() => this.hoveredRefferenceUpdate("")}  href="/fundraiser-program" selected={(window.location.pathname.indexOf("fundraiser-program")!== -1 && this.state.hoveredRefference ==="") || this.state.hoveredRefference ==="fundraise" ?true:false}>
                  Fundraise
                </CategoryLinks>
                
                <CategoryLinks color={primaryColor} onMouseEnter={() => this.hoveredRefferenceUpdate("specifications")}
                onMouseLeave={() => this.hoveredRefferenceUpdate("")}  href="/specifications" selected={(window.location.pathname.indexOf("specifications") !== -1 && this.state.hoveredRefference ==="") || this.state.hoveredRefference ==="specifications"?true:false}>
                  Specifications
                </CategoryLinks>
                {" "}
                <CategoryLinks color={primaryColor} onMouseEnter={() => this.hoveredRefferenceUpdate("design")}
                onMouseLeave={() => this.hoveredRefferenceUpdate("")}  href="/designs" selected={(window.location.pathname.indexOf("design") !== -1 && this.state.hoveredRefference ==="") || this.state.hoveredRefference ==="design"?true:false}>
                  Designs
                </CategoryLinks>
                {" "}
                <CategoryLinks color={primaryColor} onMouseEnter={() => this.hoveredRefferenceUpdate("sz-fonts")}
                onMouseLeave={() => this.hoveredRefferenceUpdate("")}  href="/sz-fonts" selected={(window.location.pathname.indexOf("sz-fonts") !== -1 && this.state.hoveredRefference ==="") || this.state.hoveredRefference ==="sz-fonts"?true:false}>
                  Fonts
                </CategoryLinks>
                {" "}
                <CategoryLinks color={primaryColor} onMouseEnter={() => this.hoveredRefferenceUpdate("colors")}
                onMouseLeave={() => this.hoveredRefferenceUpdate("")}  href="/colors" selected={(window.location.pathname.indexOf("colors") !== -1 && this.state.hoveredRefference ==="") || this.state.hoveredRefference ==="colors"?true:false}>
                  Colors
                </CategoryLinks>
                {" "}
                <CategoryLinks color={primaryColor} onMouseEnter={() => this.hoveredRefferenceUpdate("size-and-location")}
                onMouseLeave={() => this.hoveredRefferenceUpdate("")}  href="/size-and-location" selected={(window.location.pathname.indexOf("size-and-location") !== -1 && this.state.hoveredRefference ==="") || this.state.hoveredRefference ==="size-and-location"?true:false}>
                  Size and Locations
                </CategoryLinks>
                <CategoryLinks color={primaryColor} onMouseEnter={() => this.hoveredRefferenceUpdate("special")}
                onMouseLeave={() => this.hoveredRefferenceUpdate("")}  href="/#special-offer" selected={(window.location.pathname.indexOf("/#special-offer") !== -1 && this.state.hoveredRefference ==="") || this.state.hoveredRefference ==="special"?true:false}>Specials</CategoryLinks>
                <CategoryLinks color={primaryColor} onMouseEnter={() => this.hoveredRefferenceUpdate("contact")}
                onMouseLeave={() => this.hoveredRefferenceUpdate("")}  href="/Contact-us" selected={(window.location.pathname.indexOf("contact-us") !== -1 && this.state.hoveredRefference ==="") || this.state.hoveredRefference ==="contact"?true:false}>Contact</CategoryLinks>
              </DropDown>
            </CategoryHeaderLinks>
            <div id="myaccount_menu">
            {getCookie(authCookieName) != null && localStorage.getItem("userDetails") != null? (<CategoryLinks
              color={this.props.header.headerFontColor}
              href={`#`}
              key="refernce"
            >
              <User />
              <ProfileDropDown style={{width: "106px"}}>
                <CategoryLinks color={primaryColor} href="/my-profile" selected={window.location.pathname.indexOf("my-profile") !== -1?true:false}>
                  My Profile
                </CategoryLinks>
                <br />
                <CategoryLinks color={primaryColor} href="/my-orders" selected={window.location.pathname.indexOf("my-orders") !== -1?true:false}>
                  My Orders
                </CategoryLinks>
                <br />
                <CategoryLinks color={primaryColor} href="javascript:void(0)" onClick={this.logout}>
                  Logout
                </CategoryLinks>
              </ProfileDropDown>
            </CategoryLinks>
            ) : (
              <a href="/login" onMouseOver={() => this.hoverLoginCheck()}>
                <User />
              </a>
            )}
            </div>
            &nbsp;&nbsp;
          </HeaderActions>
          {/* {window.location.pathname === "/" && (
            <SubContainer>
              <LinkList>
                {[
                  "sweetshirt",
                  "jacket",
                  "cardian",
                  "shirt",
                  "stole",
                  "hats",
                  "bags",
                  "accessroies",
                  "off the rack",
                  "imprint",
                ].map((list) => (
                  <List key={list}>
                    <a>{list}</a>
                    <ListDropdown>
                      <ExploreBtn>Explore More Products</ExploreBtn>
                    </ListDropdown>
                  </List>
                ))}
              </LinkList>
              <BtnPrimary href="/sign-up">Join Now</BtnPrimary>
              <BtnSecondary href="/catalog/">Start Design</BtnSecondary>
            </SubContainer>
          )} */}
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state: IAppState) => {
  return {
    header: state.header,
    profileData: state.profile
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    getHeader: () => headerClient.getHeader(dispatch),
    //lines added for mega menu.
    getMegaMenu: () => headerClient.getMegaMenu(dispatch),
  };
};

export const Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComponent);
