import React, { useEffect, ChangeEvent } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import styled from "styled-components";
import {
  ForegroundBackgroundSettings,
  GreekLettersList,
  GREEK_ALPHABETS,
  IAppliqueData,
  ICustomizationOptions,
  IFontStyle,
  IMaterialFillStrokeSash,
  IMaterialFillStrokeBackGround,
  IMaterialFillStrokeForeGround,
  IThreadToMatch,
  PreviewSettingNames,
  UpdatePreviewParams,
  IAppState,
  NUMBERS,
  DesignerViewStates,
} from "../../../models";
import {
  Dropdown,
  RadioButton,
  Textarea,
  Checkbox,
} from "@happeouikit/form-elements";
import { TextZeta } from "@happeouikit/typography";
import { defaulGreekText, marginL, marginM } from "../../../constants";
import {
  GreekLetterSizeId,
  IOneProductV2,
  LetterplacementID,
  StitchingTypeId,
} from "../../../models/ApiResponses/IGetProductResponseV2";
import { NumbersList } from "../../../models/interfaces/Numbers";
import { addPriceToCart, removePriceToCart } from "../../../actions";

import { useFilePicker } from "use-file-picker";
import {
  FileAmountLimitValidator,
  FileTypeValidator,
  FileSizeValidator,
  ImageDimensionsValidator,
} from "use-file-picker/validators";
import { useImperativeFilePicker } from "use-file-picker";
import { toast } from "@happeouikit/toast";

const Font_Color_Select = {
  label: "Select Color",
  value: "",
};

const Body_Color_Select = {
  label: "Select Body Color",
  value: "",
};

const Trim_Color_Select = {
  label: "Select Trim Color",
  value: "",
};

const Foreground_Color_Select = {
  label: "Select Foreground Color",
  value: "",
};

const Foreground_Thread_Select = {
  label: "Thread to match (Default)",
  value: "",
};

const Background_Color_Select = {
  label: "Select Background Color",
  value: "",
};

const Background_Thread_Select = {
  label: "Thread to match (Default)",
  value: "",
};

interface GreekTextSettingsProps {
  updatePreview(newSetting: UpdatePreviewParams, key: string, side?:string): void;
  updateAppliqueView(applique: any): void;
  availableForegroundOptions: ICustomizationOptions;
  availableBackgroundOptions: ICustomizationOptions;
  fontStyleOptions: Array<IFontStyle>;
  availableThreadOptions: Array<IThreadToMatch>;
  appliqueData: IAppliqueData;
  selectedProduct: IOneProductV2;
  selectedSide: string;
  cartAddedData(obj: any): void;
  resetForm(side: string, {}: any): void;
  appliqueView: {
    [key: string]: {
      letterSize: {
        _id: string;
        size: string;
      };
      stitchingType: {
        _id: string;
        stitchingType: string;
        price: number;
        currency: string;
      };
      letterPlacement: any;
      specialInstructions: string;
      selectedGreekText: GreekLettersList;
      enteredText: string;
      sashSettings?: IMaterialFillStrokeSash;
      foreGroundSettings: IMaterialFillStrokeForeGround;
      backgroundSettings: IMaterialFillStrokeBackGround;
      selectedNumber?: NumbersList;
      isCenterApplique?: boolean;
      heatSealedLetter: {
        isactive: boolean;
        cost: string;
      };
      greekorNumber?: string;
      customFiles: any;
    };
  };
  alphabetTypeContainer: any;
}

interface GreekTextSettingsState {
  selectedForeMaterial: any;
  selectedSashMaterial: any;
  selectedBackMaterial: any;
  selectedForeColor: any;
  selectedSashBodyColor: any;
  selectedBackColor: any;
  selectedForeThreadColor: any;
  selectedSashTrimColor: any;
  selectedBackThreadColor: any;
  customFiles: any;
}

interface GreekPropsFromRedux {
  letterplacementId: Array<LetterplacementID>;
  stitchingTypeId: StitchingTypeId[];
  greekLetterSizeId: GreekLetterSizeId[];
  subCategoryId: any;
  addPriceToCart(data: any): void;
  removePriceToCart(data: any): void;
}

export class GreekTextSettingsComponent extends React.Component<
  GreekTextSettingsProps & GreekPropsFromRedux,
  GreekTextSettingsState
> {
  private greekAlphabetOptions = Object.keys(GREEK_ALPHABETS).map((key) => ({
    label: key,
    value: (GREEK_ALPHABETS as any)[key as any],
  }));

  private NumberOptions = Object.keys(NUMBERS).map((key) => ({
    label: key,
    value: (NUMBERS as any)[key as any],
  }));

  constructor(props: GreekTextSettingsProps & GreekPropsFromRedux) {
    super(props);
    const {
      availableForegroundOptions,
      availableBackgroundOptions,
      appliqueView,
      selectedSide,
    } = props;
    const defaultForeFGroundMaterial =
      availableForegroundOptions[Object.keys(availableForegroundOptions)[0]];

    const defaultSashMaterial =
      availableForegroundOptions[Object.keys(availableForegroundOptions)[0]];

    const defaultBackGroundMaterial =
      availableBackgroundOptions[Object.keys(availableBackgroundOptions)[0]];
      console.log("defaultSashMaterial : ",defaultSashMaterial);
    this.state = {
      selectedForeMaterial: defaultForeFGroundMaterial,
      selectedSashMaterial: defaultSashMaterial,
      selectedBackMaterial: defaultBackGroundMaterial,
      selectedForeColor: {},
      selectedSashBodyColor: {},
      selectedBackColor: {},
      selectedForeThreadColor: {},
      selectedSashTrimColor: {},
      selectedBackThreadColor: {},
      customFiles: [],
    };

    this.setSpecialInstructions = this.setSpecialInstructions.bind(this);
    this.setStichType = this.setStichType.bind(this);
    this.setLetterPlacementChange = this.setLetterPlacementChange.bind(this);
    this.setGreekTextChange = this.setGreekTextChange.bind(this);
    this.setNumberChange = this.setNumberChange.bind(this);
    this.setLetterSize = this.setLetterSize.bind(this);
    this.setForeGroundFillStorkeAndMarker =
      this.setForeGroundFillStorkeAndMarker.bind(this);
    this.setSashFillStorkeAndMarker =
      this.setSashFillStorkeAndMarker.bind(this);
    this.setBackGroundFillStorkeAndMarker =
      this.setBackGroundFillStorkeAndMarker.bind(this);
    this.setCustomFileChange = this.setCustomFileChange.bind(this);

    this.updateInitialPreview();

    const {
      greekorNumber
    } = props.appliqueView[selectedSide];

    if(greekorNumber === "numbers"){
      this.props.alphabetTypeContainer.current.style.display="none";
    }
  }

  componentDidMount(): void {
    /* console.log("componentDidMount"); */
    this.updateStitching();
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.selectedSide !== this.props.selectedSide) {
      /* console.log("componentDidUpdate"); */
      const {
        availableForegroundOptions,
        availableBackgroundOptions
      } = this.props;
      const defaultForeFGroundMaterial =
        availableForegroundOptions[Object.keys(availableForegroundOptions)[0]];

      const defaultSashMaterial =
        availableForegroundOptions[Object.keys(availableForegroundOptions)[0]];
  
      const defaultBackGroundMaterial =
        availableBackgroundOptions[Object.keys(availableBackgroundOptions)[0]];
        
      this.setState({
        selectedForeMaterial: defaultForeFGroundMaterial,
        selectedSashMaterial: defaultSashMaterial,
        selectedBackMaterial: defaultBackGroundMaterial,
        selectedForeColor: {},
        selectedSashBodyColor: {},
        selectedBackColor: {},
        selectedForeThreadColor: {},
        selectedSashTrimColor: {},
        selectedBackThreadColor: {},
        customFiles: [],
      });
      this.updateInitialPreview();
      this.updateStitching();
    }
  }

  updateStitching() {
    const { selectedSide, availableThreadOptions } = this.props;
    const {
      foreGroundSettings: { stroke },
      backgroundSettings: { stroke: bgStroke },
    } = this.props.appliqueView[this.props.selectedSide];
    const selectedStroke = availableThreadOptions.find(
      ({ value }) => value === stroke
    );
    const selectedBgStroke = availableThreadOptions.find(
      ({ value }) => value === bgStroke
    );
    if (selectedStroke || selectedBgStroke) {
      const st = this.props.stitchingTypeId.find((i: any) => {
        return i.stitchingType === "Double/Satin";
      });
      this.props.updateAppliqueView({
        ...this.props.appliqueView,
        [selectedSide]: {
          ...this.props.appliqueView[selectedSide],
          stitchingType: {
            _id: st?._id,
            stitchingType: st?.stitchingType,
            price: st?.price,
            currency: st?.currency,
          },
        },
      });
    }
  }

  updateInitialPreview() {
    const {
      letterSize,
      stitchingType,
      letterPlacement,
      foreGroundSettings,
      backgroundSettings,
    } = this.props.appliqueView[this.props.selectedSide];

    const {
      letterplacementId
    } = this.props;

    /* console.log("this.props.appliqueView[this.props.selectedSide] : ", this.props.appliqueView[this.props.selectedSide]); */

    const { value: letterPlacementValue } = letterPlacement || {value: false};

    if (letterPlacementValue) {
      this.props.updatePreview(
        {
          settingName: PreviewSettingNames.letterPlacement,
          settingVal: {
            value: letterPlacementValue,
          },
        },
        DesignerViewStates.Applique
      );
    }

    this.props.updatePreview(
      {
        settingName: PreviewSettingNames.stitchType,
        settingVal: {
          value: stitchingType.stitchingType,
        },
      },
      DesignerViewStates.Applique
    );

    this.props.updatePreview(
      {
        settingName: PreviewSettingNames.letterSize,
        settingVal: {
          value: letterSize.size.split('"')[0],
        },
      },
      DesignerViewStates.Applique
    );

    this.props.updatePreview(
      {
        settingName: PreviewSettingNames.foreGroundFillStrokeMarker,
        settingVal: {
          value: foreGroundSettings,
        },
      },
      DesignerViewStates.Applique
    );

    this.props.updatePreview(
      {
        settingName: PreviewSettingNames.backGroundFillStrokeMarker,
        settingVal: {
          value: backgroundSettings,
        },
      },
      DesignerViewStates.Applique
    );

    const newGreekLettersList: GreekLettersList = [
      ...this.props.appliqueView[this.props.selectedSide].selectedGreekText,
    ];

    if (
      newGreekLettersList.reduce((a, b) => (a + b) as any).trim().length > 0
    ) {
      this.props.updatePreview(
        {
          settingName: PreviewSettingNames.contentText,
          settingVal: {
            value:
              newGreekLettersList.join("").length === 0
                ? defaulGreekText
                : newGreekLettersList.join(""),
          },
        },
        DesignerViewStates.Applique
      );
    } else {
      if (this.props.selectedSide === "back") {
        const newNumbersList: NumbersList = [
          ...this.props.appliqueView[this.props.selectedSide].selectedNumber!,
        ];
        this.props.updatePreview(
          {
            settingName: PreviewSettingNames.contentText,
            settingVal: {
              value:
                newNumbersList.join("").length === 0
                  ? defaulGreekText
                  : newNumbersList.join(""),
            },
          },
          DesignerViewStates.Applique
        );

        /* If number selected */
        if(newNumbersList.join("").length > 0){
          /* for number the letter placement will be Center Of Back */
          let letterplacementIdValue : any = {
            x_axis: "35%",
            y_axis: "40%"
          };

          let letterPlacement = letterplacementId.filter((itm) => (itm.name === "Centre of Back" || itm.name === "Center of Back"));
          if(letterPlacement.length > 0){
            letterplacementIdValue = letterPlacement[0];
          }
          
          this.props.updatePreview(
            {
              settingName: PreviewSettingNames.letterPlacement,
              settingVal: {
                value: { x_axis: letterplacementIdValue.x_axis, y_axis: letterplacementIdValue.y_axis },
              },
            },
            DesignerViewStates.Applique
          );
      
          this.props.updatePreview(
            {
              settingName: PreviewSettingNames.letterSize,
              settingVal: {
                value: 7.0,
              },
            },
            DesignerViewStates.Applique
          );
        }
      }
    }
  }

  setCustomFileChange(fileArr: any) {
    this.setState(
      {
        customFiles: fileArr,
      },
      () => {
        //console.log("this.state.customFiles : ", this.state.customFiles);

        const { selectedSide } = this.props;
        this.props.updateAppliqueView({
          ...this.props.appliqueView,
          [selectedSide]: {
            ...this.props.appliqueView[selectedSide],
            customFiles: this.state.customFiles,
          },
        });
      }
    );
  }

  setLetterSize(letterSize: any) {
    const { selectedSide } = this.props;
    this.props.updateAppliqueView({
      ...this.props.appliqueView,
      [selectedSide]: {
        ...this.props.appliqueView[selectedSide],
        letterSize: {
          size: letterSize.size,
          _id: letterSize._id,
        },
      },
    });

    this.props.updatePreview(
      {
        settingName: PreviewSettingNames.letterSize,
        settingVal: {
          value: letterSize.size.split('"')[0],
        },
      },
      DesignerViewStates.Applique
    );
  }

  private setGreekTextChange(
    value: { label: string; value: GREEK_ALPHABETS },
    index: number
  ) {
    const { selectedSide } = this.props;
    /*const newGreekLettersList: GreekLettersList = [
      ...this.props.appliqueView[selectedSide].selectedGreekText,
    ];*/

    let newGreekLettersList: any = [
      ...this.props.appliqueView[selectedSide].selectedGreekText,
    ];
    /* console.log("index : ", index);
    console.log("value : ", value);
    console.log("newGreekLettersList : ", newGreekLettersList); */

    /* check if all previous options are already selected */
    let isAllPreviousOptionsSelected = this.props.appliqueView[
      selectedSide
    ].selectedGreekText.filter(
      (value, idx) => idx < index && newGreekLettersList[idx] == " "
    );
    /* console.log(
      "isAllPreviousOptionsSelected.length : ",
      isAllPreviousOptionsSelected.length
    );
    console.log(
      "newGreekLettersList.map((v, i) => (i < index) ? v: ' ') : ",
      newGreekLettersList.map((v: any, i: number) => (i < index ? v : " "))
    ); */

    let letterSize = this.props.appliqueView[
      selectedSide
    ].letterSize.size;

    if (isAllPreviousOptionsSelected.length == 0) {
      let fontSize = letterSize.substring(0,1);
      console.log("letterSize : ", letterSize);
      /* put new letter to calculate actual letter count */
      newGreekLettersList[index] = value.value;
      let currentLetterCount = newGreekLettersList.filter((v:any) => v != " ").length;
      console.log("currentLetterCount : ", currentLetterCount);
      /* letter count validation */
      if(fontSize == "3" && currentLetterCount > 4){
        toast.error({
          message: `For this letter size only 4 characters are allowed. `,
          delay: 2000,
        });
        return false;
      } else if(fontSize == "4" && currentLetterCount > 3){
        toast.error({
          message: `For this letter size only 3 characters are allowed. `,
          delay: 2000,
        });
        return false;
      } else if(fontSize == "6" && currentLetterCount > 2){
        toast.error({
          message: `For this letter size only 2 characters are allowed. `,
          delay: 2000,
        });
        return false;
      } else {
        if (value.value == " ") {
          newGreekLettersList = newGreekLettersList.map((v: any, i: number) =>
            i < index ? v : " "
          );
        } else {
          newGreekLettersList[index] = value.value;
        }
      }
    } else {
      toast.error({
        message: `Please fill all the previous options first. `,
        delay: 2000,
      });
    }
    /* console.log("newGreekLettersList after : ", newGreekLettersList); */

    this.props.updateAppliqueView({
      ...this.props.appliqueView,
      [selectedSide]: {
        ...this.props.appliqueView[selectedSide],
        selectedGreekText: newGreekLettersList,
        enteredText: newGreekLettersList.join("").trim(),
      },
    });
    this.props.updatePreview(
      {
        settingName: PreviewSettingNames.contentText,
        settingVal: {
          value: newGreekLettersList.join("").trim(),
        },
      },
      DesignerViewStates.Applique
    );
  }

  private setNumberChange(
    value: { label: string; value: NUMBERS },
    index: number
  ) {
    const { selectedSide, letterplacementId } = this.props;
    /* const newNumbersList: NumbersList = [
      ...this.props.appliqueView[selectedSide].selectedNumber!,
    ]; */

    let newNumbersList = [
      ...this.props.appliqueView[selectedSide].selectedNumber!,
    ];

    if (value.value == " " && newNumbersList[2] != " ") {
      /* console.log("setNumberChange-removePriceToCart"); */
      this.props.removePriceToCart({
        price: 2,
      });
    }

    /* check if all previous options are already selected */
    let isAllPreviousOptionsNotSelected = this.props.appliqueView[
      selectedSide
    ].selectedNumber!.filter(
      (value, idx) => idx < index && newNumbersList[idx] == " "
    );

    if (isAllPreviousOptionsNotSelected.length == 0) {
      /* if the 3rd number selection then add $2 into total cart price calculation */
      if (index == 2 && value.value != " " && newNumbersList[2] == " ") {
        /* console.log("setNumberChange-addPriceToCart"); */
        this.props.addPriceToCart({
          price: 2,
        });
      }

      if (value.value == " ") {
        newNumbersList = newNumbersList.map((v: any, i: number) =>
          i < index ? v : " "
        );
      } else {
        newNumbersList[index] = value.value;
      }
    } else {
      toast.error({
        message: `Please fill all the previous options first. `,
        delay: 2000,
      });
    }

    this.props.updateAppliqueView({
      ...this.props.appliqueView,
      [selectedSide]: {
        ...this.props.appliqueView[selectedSide],
        selectedNumber: newNumbersList,
        enteredText: newNumbersList.join("").trim(),
      },
    });

    this.props.updatePreview(
      {
        settingName: PreviewSettingNames.contentText,
        settingVal: {
          value: newNumbersList.join(""),
        },
      },
      DesignerViewStates.Applique
    );

    /* for number the letter placement will be Center Of Back */
    let letterplacementIdValue : any = {
      x_axis: "35%",
      y_axis: "40%"
    };

    let letterPlacement = letterplacementId.filter((itm) => (itm.name === "Centre of Back" || itm.name === "Center of Back"));
    if(letterPlacement.length > 0){
      letterplacementIdValue = letterPlacement[0];
    }

    this.props.updatePreview(
      {
        settingName: PreviewSettingNames.letterPlacement,
        settingVal: {
          value: { x_axis: letterplacementIdValue.x_axis, y_axis: letterplacementIdValue.y_axis },
        },
      },
      DesignerViewStates.Applique
    );

    this.props.updatePreview(
      {
        settingName: PreviewSettingNames.letterSize,
        settingVal: {
          value: 7.0,
        },
      },
      DesignerViewStates.Applique
    );
  }

  setLetterPlacementChange(selectedOption: {
    label: string;
    value: string;
    x_axis: number;
    y_axis: number;
  }) {
    const { x_axis, y_axis } = selectedOption;
    const { selectedSide } = this.props;
    this.props.updateAppliqueView({
      ...this.props.appliqueView,
      [selectedSide]: {
        ...this.props.appliqueView[selectedSide],
        letterPlacement: {
          label: selectedOption.label,
          value: {
            x_axis,
            y_axis,
          },
        },
      },
    });
    this.props.updatePreview(
      {
        settingName: PreviewSettingNames.letterPlacement,
        settingVal: {
          value: { x_axis, y_axis },
        },
      },
      DesignerViewStates.Applique
    );
  }

  setStichType(stitchType: {
    _id: string;
    stitchingType: string;
    price: number;
    currency: string;
  }) {
    /* console.log("setStichType-removePriceToCart"); */
    this.props.removePriceToCart({
      price:
        this.props.appliqueView[this.props.selectedSide].stitchingType.price,
    });
    const { selectedSide, stitchingTypeId } = this.props;
    this.props.updateAppliqueView({
      ...this.props.appliqueView,
      [selectedSide]: {
        ...this.props.appliqueView[selectedSide],
        stitchingType: {
          _id: stitchType._id,
          stitchingType: stitchType.stitchingType,
          price: stitchType.price,
          currency: stitchType.currency,
        },
      },
    });

    this.props.updatePreview(
      {
        settingName: PreviewSettingNames.stitchType,
        settingVal: {
          value: stitchType.stitchingType,
        },
      },
      DesignerViewStates.Applique
    );
    /* console.log("setStichType-addPriceToCart"); */
    this.props.addPriceToCart({
      price: stitchType.price,
    });
  }

  setSpecialInstructions(e: ChangeEvent<HTMLInputElement>) {
    const { selectedSide } = this.props;
    this.props.updateAppliqueView({
      ...this.props.appliqueView,
      [selectedSide]: {
        ...this.props.appliqueView[selectedSide],
        specialInstructions: e.target.value,
      },
    });
  }

  setForeGroundFillStorkeAndMarker(
    value: string,
    type: ForegroundBackgroundSettings
  ) {
    /* console.log("value : ", value);
    console.log("type : ", type); */

    const { availableForegroundOptions, selectedSide, availableThreadOptions } =
      this.props;
      console.log("props:", this.props);
    const {
      foreGroundSettings: { stroke },
    } = this.props.appliqueView[selectedSide];

    if (type === ForegroundBackgroundSettings.material) {
      if (Object.keys(this.state.selectedForeMaterial).length > 0) {
        /* console.log("setForeGroundFillStorkeAndMarker-material-addPriceToCart"); */
        this.props.removePriceToCart({
          price: (this.state.selectedForeMaterial as any)["price"],
        });
      }

      if (this.state.selectedForeColor) {
        if (Object.keys(this.state.selectedForeColor).length > 0) {
          /* console.log(
            "setForeGroundFillStorkeAndMarker-material-removePriceToCart"
          ); */
          this.props.removePriceToCart({
            price: (this.state.selectedForeColor as any)["price"],
          });
        }
      }

      let selectedMaterial = Object.values(availableForegroundOptions).find(
        ({ value: _value }) => {
          return _value == value;
        }
      ) || { availableFills: [] };
      const newValues = {
        material: value,
        // fill: !value ? "" : selectedMaterial?.availableFills[0].value,
        fill: !value ? "" : "",
        stroke,
      };

      this.setState({
        selectedForeMaterial: selectedMaterial,
        selectedForeColor: {},
      });

      if (selectedMaterial) {
        /* console.log("setForeGroundFillStorkeAndMarker-material-addPriceToCart"); */
        this.props.addPriceToCart({
          price: (selectedMaterial as any)["price"],
        });
      }

      this.props.updateAppliqueView({
        ...this.props.appliqueView,
        [selectedSide]: {
          ...this.props.appliqueView[selectedSide],
          foreGroundSettings: newValues,
        },
      });

      this.props.updatePreview(
        {
          settingName: PreviewSettingNames.foreGroundFillStrokeMarker,
          settingVal: { value: newValues },
        },
        DesignerViewStates.Applique
      );
    } else if (type === ForegroundBackgroundSettings.fill) {
      if (this.state.selectedForeColor) {
        if (Object.keys(this.state.selectedForeColor).length > 0) {
          /* console.log(
            "setForeGroundFillStorkeAndMarker-fill-removePriceToCart"
          ); */
          this.props.removePriceToCart({
            price: (this.state.selectedForeColor as any)["price"],
          });
        }
      }
      console.log("availableForegroundOptions:", availableForegroundOptions);
      const selectedFill = availableForegroundOptions[
        this.state.selectedForeMaterial?.label as string
      ].availableFills.find((filll) => value === filll.value);
      if (!selectedFill) {
        // Handle case where the fill is not found or availableFills is undefined
    }
      this.setState({
        selectedForeColor: selectedFill,
      });

      if (selectedFill) {
        /* console.log("setForeGroundFillStorkeAndMarker-fill-addPriceToCart"); */
        this.props.addPriceToCart({
          price: (selectedFill as any)["price"],
        });
      }

      const newValues = {
        ...this.props.appliqueView[selectedSide].foreGroundSettings,
        [type]: selectedFill?.value as string,
      };

      this.props.updateAppliqueView({
        ...this.props.appliqueView,
        [selectedSide]: {
          ...this.props.appliqueView[selectedSide],
          foreGroundSettings: newValues,
        },
      });

      this.props.updatePreview(
        {
          settingName: PreviewSettingNames.foreGroundFillStrokeMarker,
          settingVal: { value: newValues },
        },
        DesignerViewStates.Applique
      );
    } else {
      /* Thgread color / Stroke */
      if (this.state.selectedForeThreadColor) {
        if (Object.keys(this.state.selectedForeThreadColor).length > 0) {
          /* console.log(
            "setForeGroundFillStorkeAndMarker-Stroke-removePriceToCart"
          ); */
          this.props.removePriceToCart({
            price: (this.state.selectedForeThreadColor as any)["price"],
          });
        }
      }

      //console.log("availableThreadOptions : ", availableThreadOptions);

      let selectedThread = Object.values(availableThreadOptions).find(
        ({ value: _value }) => {
          return _value == value;
        }
      );

      this.setState({
        selectedForeThreadColor: selectedThread,
      });

      if (selectedThread) {
        /* console.log("setForeGroundFillStorkeAndMarker-Stroke-addPriceToCart"); */
        this.props.addPriceToCart({
          price: (selectedThread as any)["price"],
        });
      }

      const newValues = {
        ...this.props.appliqueView[selectedSide].foreGroundSettings,
        [type]: value, // stroke value
      };

      
      const st = this.props.stitchingTypeId.find((i: any) => {
        return i.stitchingType === "Double/Satin";
      });

      /* commented by Soumalya - 21-01-2024 */
      /* if (
        st &&
        newValues.stroke !== "" &&
        this.props.appliqueView[selectedSide].stitchingType.stitchingType ===
          "Standard"
      ) { 
        this.props.addPriceToCart({
          price: (st as any)["price"],
        });
      } */

      if(value != ""){
        this.props.updateAppliqueView({
          ...this.props.appliqueView,
          [selectedSide]: {
            ...this.props.appliqueView[selectedSide],
            foreGroundSettings: newValues,
            stitchingType: {
              _id: st?._id,
              stitchingType: st?.stitchingType,
              price: st?.price,
              currency: st?.currency,
            }
          }
        });
      } else {
        this.props.updateAppliqueView({
          ...this.props.appliqueView,
          [selectedSide]: {
            ...this.props.appliqueView[selectedSide],
            foreGroundSettings: newValues
          },
        });
      }

      this.props.updatePreview(
        {
          settingName: PreviewSettingNames.foreGroundFillStrokeMarker,
          settingVal: { value: newValues },
        },
        DesignerViewStates.Applique
      );
    }
  }

  setSashFillStorkeAndMarker(
    value: string,
    type: ForegroundBackgroundSettings
  ) {
    /* console.log("value : ", value);
    console.log("type : ", type); */

    const { availableForegroundOptions, selectedSide, availableThreadOptions, subCategoryId } =
      this.props;
    const {
      sashSettings,
    } = this.props.appliqueView[selectedSide];

    /*if (type === ForegroundBackgroundSettings.material) {
      if (Object.keys(this.state.selectedForeMaterial).length > 0) {
        
        this.props.removePriceToCart({
          price: (this.state.selectedForeMaterial as any)["price"],
        });
      }

      if (this.state.selectedForeColor) {
        if (Object.keys(this.state.selectedForeColor).length > 0) {

          this.props.removePriceToCart({
            price: (this.state.selectedForeColor as any)["price"],
          });
        }
      }

      let selectedMaterial = Object.values(availableForegroundOptions).find(
        ({ value: _value }) => {
          return _value == value;
        }
      ) || { availableFills: [] };

      const newValues = {
        material: value,
        // fill: !value ? "" : selectedMaterial?.availableFills[0].value,
        fill: !value ? "" : "",
        stroke,
      };

      this.setState({
        selectedForeMaterial: selectedMaterial,
        selectedForeColor: {},
      });

      if (selectedMaterial) {
        
        this.props.addPriceToCart({
          price: (selectedMaterial as any)["price"],
        });
      }

      this.props.updateAppliqueView({
        ...this.props.appliqueView,
        [selectedSide]: {
          ...this.props.appliqueView[selectedSide],
          foreGroundSettings: newValues,
        },
      });

      this.props.updatePreview(
        {
          settingName: PreviewSettingNames.foreGroundFillStrokeMarker,
          settingVal: { value: newValues },
        },
        DesignerViewStates.Applique
      );
    } else*/ if (type === ForegroundBackgroundSettings.fill) {
      if (this.state.selectedSashBodyColor) {
        if (Object.keys(this.state.selectedSashBodyColor).length > 0) {
          /* console.log(
            "setForeGroundFillStorkeAndMarker-fill-removePriceToCart"
          ); */
          this.props.removePriceToCart({
            price: (this.state.selectedSashBodyColor as any)["price"],
          });
        }
      }
      console.log("availableFills : ", availableForegroundOptions[
        this.state.selectedSashMaterial?.label as string
      ]);

      const selectedFill = availableForegroundOptions[
        this.state.selectedSashMaterial?.label as string
      ].availableFills?.find((filll) => value === filll.value);
      console.log("selectedFill : ", selectedFill);
      this.setState({
        selectedSashBodyColor: selectedFill,
      });

      if (selectedFill) {
        /* console.log("setForeGroundFillStorkeAndMarker-fill-addPriceToCart"); */
        this.props.addPriceToCart({
          price: (selectedFill as any)["price"],
        });
      }

      const newValues = {
        ...this.props.appliqueView[selectedSide].sashSettings,
        [type]: selectedFill?.value as string,
      };
      

      if(subCategoryId.name.toLocaleUpperCase().includes("STOLE")){
        let newBValues = {
          ...this.props.appliqueView["back"].sashSettings,
          [type]: selectedFill?.value as string,
        };

        this.props.updateAppliqueView({
          ...this.props.appliqueView,
          [selectedSide]: {
            ...this.props.appliqueView[selectedSide],
            sashSettings: newValues,
          },
          ["back"]: {
            ...this.props.appliqueView["back"],
            sashSettings: newBValues,
          },
        });

        this.props.updatePreview(
          {
            settingName: PreviewSettingNames.sashFillStrokeMarker,
            settingVal: { value: newValues },
          },
          DesignerViewStates.Applique
        );

        this.props.updatePreview(
          {
            settingName: PreviewSettingNames.sashFillStrokeMarker,
            settingVal: { value: newBValues },
          },
          DesignerViewStates.Applique,
          "back"
        );
      } else {
        this.props.updateAppliqueView({
          ...this.props.appliqueView,
          [selectedSide]: {
            ...this.props.appliqueView[selectedSide],
            sashSettings: newValues,
          },
        });

        this.props.updatePreview(
          {
            settingName: PreviewSettingNames.sashFillStrokeMarker,
            settingVal: { value: newValues },
          },
          DesignerViewStates.Applique
        );
      }

      
    } else {
      /* Thgread color / Stroke */
      if (this.state.selectedSashTrimColor) {
        if (Object.keys(this.state.selectedSashTrimColor).length > 0) {
          /* console.log(
            "setForeGroundFillStorkeAndMarker-Stroke-removePriceToCart"
          ); */
          this.props.removePriceToCart({
            price: (this.state.selectedSashTrimColor as any)["price"],
          });
        }
      }

      //console.log("availableThreadOptions : ", availableThreadOptions);

      let selectedThread = Object.values(availableThreadOptions).find(
        ({ value: _value }) => {
          return _value == value;
        }
      );

      this.setState({
        selectedSashTrimColor: selectedThread,
      });

      if (selectedThread) {
        /* console.log("setForeGroundFillStorkeAndMarker-Stroke-addPriceToCart"); */
        this.props.addPriceToCart({
          price: (selectedThread as any)["price"],
        });
      }

      const newValues = {
        ...this.props.appliqueView[selectedSide].sashSettings,
        [type]: value, // stroke value
      };

      
      const st = this.props.stitchingTypeId.find((i: any) => {
        return i.stitchingType === "Double/Satin";
      });

      /* commented by Soumalya - 21-01-2024 */
      /* if (
        st &&
        newValues.stroke !== "" &&
        this.props.appliqueView[selectedSide].stitchingType.stitchingType ===
          "Standard"
      ) { 
        this.props.addPriceToCart({
          price: (st as any)["price"],
        });
      } */

      if(subCategoryId.name.toLocaleUpperCase().includes("STOLE")){
        let newBValues = {
          ...this.props.appliqueView[selectedSide].sashSettings,
          [type]: value, // stroke value
        };

        if(value != ""){
          this.props.updateAppliqueView({
            ...this.props.appliqueView,
            [selectedSide]: {
              ...this.props.appliqueView[selectedSide],
              sashSettings: newValues,
              stitchingType: {
                _id: st?._id,
                stitchingType: st?.stitchingType,
                price: st?.price,
                currency: st?.currency,
              }
            },
            ["back"]: {
              ...this.props.appliqueView["back"],
              sashSettings: newBValues,
            }
          });
        } else {
          this.props.updateAppliqueView({
            ...this.props.appliqueView,
            [selectedSide]: {
              ...this.props.appliqueView[selectedSide],
              sashSettings: newValues
            },
            ["back"]: {
              ...this.props.appliqueView["back"],
              sashSettings: newBValues,
            }
          });
        }

        this.props.updatePreview(
          {
            settingName: PreviewSettingNames.sashFillStrokeMarker,
            settingVal: { value: newValues },
          },
          DesignerViewStates.Applique
        );

        this.props.updatePreview(
          {
            settingName: PreviewSettingNames.sashFillStrokeMarker,
            settingVal: { value: newBValues },
          },
          DesignerViewStates.Applique,
          "back"
        );
      } else {
        if(value != ""){
          this.props.updateAppliqueView({
            ...this.props.appliqueView,
            [selectedSide]: {
              ...this.props.appliqueView[selectedSide],
              sashSettings: newValues,
              stitchingType: {
                _id: st?._id,
                stitchingType: st?.stitchingType,
                price: st?.price,
                currency: st?.currency,
              }
            }
          });
        } else {
          this.props.updateAppliqueView({
            ...this.props.appliqueView,
            [selectedSide]: {
              ...this.props.appliqueView[selectedSide],
              sashSettings: newValues
            },
          });
        }

        this.props.updatePreview(
          {
            settingName: PreviewSettingNames.sashFillStrokeMarker,
            settingVal: { value: newValues },
          },
          DesignerViewStates.Applique
        );
      }

      
    }
  }

  setBackGroundFillStorkeAndMarker(
    value: string,
    type: ForegroundBackgroundSettings
  ) {
    /* console.log("value : ", value);
    console.log("type : ", type); */
    const { availableBackgroundOptions, selectedSide, availableThreadOptions } =
      this.props;
    const {
      backgroundSettings: { stroke },
    } = this.props.appliqueView[selectedSide];

    if (type === ForegroundBackgroundSettings.material) {
      if (Object.keys(this.state.selectedBackMaterial).length > 0) {
        /* console.log(
          "setBackGroundFillStorkeAndMarker-material-removePriceToCart"
        ); */
        this.props.removePriceToCart({
          price: (this.state.selectedBackMaterial as any)["price"],
        });
      }
      let selectedMaterial = Object.values(availableBackgroundOptions).find(
        ({ value: _value }) => _value == value
      ) || { availableFills: [] };

      /* console.log("selectedMaterial : ", selectedMaterial); */

      const newValues = {
        material: value,
        // fill: !value ? "" : selectedMaterial?.availableFills[0].value,
        fill: !value ? "" : "",
        stroke,
      };

      this.setState({
        selectedBackMaterial: selectedMaterial,
      });

      /* console.log("setBackGroundFillStorkeAndMarker-material-addPriceToCart"); */
      if(selectedMaterial){
        this.props.addPriceToCart({
          price: (selectedMaterial as any)["price"],
        });
      }

      this.props.updateAppliqueView({
        ...this.props.appliqueView,
        [selectedSide]: {
          ...this.props.appliqueView[selectedSide],
          backgroundSettings: newValues,
        },
      });

      this.props.updatePreview(
        {
          settingName: PreviewSettingNames.backGroundFillStrokeMarker,
          settingVal: { value: newValues },
        },
        DesignerViewStates.Applique
      );
    } else if (type === ForegroundBackgroundSettings.fill) {
      if (Object.keys(this.state.selectedBackColor).length > 0) {
        /* console.log("setBackGroundFillStorkeAndMarker-fill-removePriceToCart"); */
        this.props.removePriceToCart({
          price: (this.state.selectedBackColor as any)["price"],
        });
      }
      /* console.log("availableBackgroundOptions : ", availableBackgroundOptions);
      console.log(
        "this.state.selectedBackMaterial : ",
        this.state.selectedBackMaterial
      ); */
      const selectedFill = availableBackgroundOptions[
        this.state.selectedBackMaterial?.label as string
      ].availableFills?.find((filll) => {
        /* console.log("value : ", value);
        console.log("filll : ", filll);
        console.log("filll.value : ", filll.value); */
        return value === filll.value;
      });
      /* console.log("selectedFill : ", selectedFill); */
      const newValues = {
        ...this.props.appliqueView[selectedSide].backgroundSettings,
        [type]: selectedFill?.value as string,
      };

      if (selectedFill) {
        this.setState({
          selectedBackColor: selectedFill,
        });
      }

      /* console.log("setBackGroundFillStorkeAndMarker-fill-addPriceToCart"); */
      if(selectedFill){
        this.props.addPriceToCart({
          price: (selectedFill as any)["price"],
        });
      }

      this.props.updateAppliqueView({
        ...this.props.appliqueView,
        [selectedSide]: {
          ...this.props.appliqueView[selectedSide],
          backgroundSettings: newValues,
        },
      });

      this.props.updatePreview(
        {
          settingName: PreviewSettingNames.backGroundFillStrokeMarker,
          settingVal: { value: newValues },
        },
        DesignerViewStates.Applique
      );
    } else {
      /* Thgread color / Stroke */
      if (this.state.selectedBackThreadColor) {
        if (Object.keys(this.state.selectedBackThreadColor).length > 0) {
          /* console.log(
            "setBackGroundFillStorkeAndMarker-Stroke-removePriceToCart"
          ); */
          this.props.removePriceToCart({
            price: (this.state.selectedBackThreadColor as any)["price"],
          });
        }
      }

      /* console.log("availableThreadOptions : ", availableThreadOptions); */

      let selectedThread = Object.values(availableThreadOptions).find(
        ({ value: _value }) => {
          return _value == value;
        }
      );

      this.setState({
        selectedBackThreadColor: selectedThread,
      });

      if (selectedThread) {
        /* console.log("setBackGroundFillStorkeAndMarker-Stroke-addPriceToCart"); */
        this.props.addPriceToCart({
          price: (selectedThread as any)["price"],
        });
      }

      const newValues = {
        ...this.props.appliqueView[selectedSide].backgroundSettings,
        [type]: value, // stroke value
      };

      const st = this.props.stitchingTypeId.find((i: any) => {
        return i.stitchingType === "Double/Satin";
      });

      /* commented by Soumalya - 21-01-2024 */
      /*
      if (
        st &&
        newValues.stroke !== "" &&
        this.props.appliqueView[selectedSide].stitchingType.stitchingType ===
          "Standard"
      ) {
        this.props.addPriceToCart({
          price: (st as any)["price"],
        });
      }*/

      if(value != ""){
        this.props.updateAppliqueView({
          ...this.props.appliqueView,
          [selectedSide]: {
            ...this.props.appliqueView[selectedSide],
            backgroundSettings: newValues,
            stitchingType: {
              _id: st?._id,
              stitchingType: st?.stitchingType,
              price: st?.price,
              currency: st?.currency,
            }
          },
        });
      } else {
        this.props.updateAppliqueView({
          ...this.props.appliqueView,
          [selectedSide]: {
            ...this.props.appliqueView[selectedSide],
            backgroundSettings: newValues
          },
        });
      }
      

      this.props.updatePreview(
        {
          settingName: PreviewSettingNames.backGroundFillStrokeMarker,
          settingVal: { value: newValues },
        },
        DesignerViewStates.Applique
      );
    }
  }

  render() {
    const {
      availableForegroundOptions,
      availableThreadOptions,
      availableBackgroundOptions,
      letterplacementId,
      stitchingTypeId,
      greekLetterSizeId,
      appliqueData,
      selectedSide,
      subCategoryId,
      selectedProduct
    } = this.props;

    /* console.log("availableBackgroundOptions : ",availableBackgroundOptions); */

    const {
      selectedGreekText,
      selectedNumber,
      letterSize,
      stitchingType,
      greekorNumber,
      isCenterApplique,
      heatSealedLetter,
      letterPlacement,
      specialInstructions,
      sashSettings, //: { material: materialBT, stroke: strokeBT, fill: fillBT }
      foreGroundSettings: { material, stroke, fill },
      backgroundSettings: {
        material: bgMaterial,
        stroke: bgStroke,
        fill: bgFill,
      },
      customFiles,
    } = this.props.appliqueView[selectedSide];

    const foreGroundMaterialOptions = Object.keys(
      availableForegroundOptions
    ).map((key: any) => ({
      label: (availableForegroundOptions as any)[key].label,
      value: (availableForegroundOptions as any)[key].value,
    }));

    const selectedForegroundMaterial = foreGroundMaterialOptions.find(
      ({ value }) => value === material
    );

    const foreGroundFillOptions =
      availableForegroundOptions[selectedForegroundMaterial?.label]
        ?.availableFills || [];

    const selectedforeGroundFill =
      (foreGroundFillOptions?.length > 0 &&
        foreGroundFillOptions?.find((option: any) => option.value === fill)) ||
      Foreground_Color_Select;
console.log("fill: ",fill, "Foreground_Color_Select: ",Foreground_Color_Select,foreGroundFillOptions,":three things")
    const selectedStroke =
      availableThreadOptions.find(({ value }) => value === stroke) ||
      Foreground_Thread_Select;

    /* sash settings */
    let sashFillOptions:any = [];
    let selectedSashFill:any = {};
    let selectedSashStroke:any = {};
    if(subCategoryId.name.toLocaleUpperCase().includes("STOLE")){
      console.log("====== sashSettings ======", sashSettings);

      const sashMaterialOptions = Object.keys(
        availableForegroundOptions
      ).map((key: any) => ({
        label: (availableForegroundOptions as any)[key].label,
        value: (availableForegroundOptions as any)[key].value,
      }));
  
      const selectedSashMaterial = sashMaterialOptions.find(
        ({ value }) => value === sashSettings?.material
      );
      console.log("====== selectedSashMaterial ======", selectedSashMaterial);
      sashFillOptions =
        availableForegroundOptions[selectedSashMaterial?.label]
          ?.availableFills || [];
      console.log("====== sashFillOptions ======", sashFillOptions);
      selectedSashFill =
        (sashFillOptions?.length > 0 &&
          sashFillOptions?.find((option: any) => option.value === sashSettings?.fill)) ||
          Body_Color_Select;
      console.log("====== selectedSashFill ======", selectedSashFill);
      selectedSashStroke =
        availableThreadOptions.find(({ value }) => value === sashSettings?.stroke) ||
        Trim_Color_Select;
      console.log("====== selectedSashStroke ======", selectedSashStroke);
    }

    console.log("====== sashFillOptions ======", sashFillOptions);

    const backgroundMaterialOptions = Object.keys(
      availableBackgroundOptions
    ).map((key: any) => ({
      label: (availableBackgroundOptions as any)[key].label,
      value: (availableBackgroundOptions as any)[key].value,
    }));

    const selectedBackgroundMaterial = backgroundMaterialOptions.find(
      ({ value }) => value === bgMaterial
    );

    const backgroundFills =
      availableBackgroundOptions[selectedBackgroundMaterial?.label]
        ?.availableFills || [];

    const selectedBackgroundFills =
      (backgroundFills.length > 0 &&
        backgroundFills.find(
          (currentFill: any) => currentFill.value === bgFill
        )) ||
      Background_Color_Select;

    const selectedBgStroke =
      availableThreadOptions.find(({ value }) => value === bgStroke) ||
      Background_Thread_Select;

    const selectedFontColorFill =
      (foreGroundFillOptions?.length > 0 &&
        foreGroundFillOptions?.find((option: any) => option.value === fill)) ||
      Font_Color_Select;

    const getGreekAlphabets = () => {
      return selectedGreekText.map((selectedVal, index) => {
        let greekAlphaOptions:any[] = [];
        switch(index){
            case 0:
                greekAlphaOptions = this.greekAlphabetOptions.map((opt, i) => {
                    if(opt.value == " " && i == 0){
                        return {
                            label: `Select ${(index+1)}st Greek Letter`,
                            value: opt.value
                        }
                    } else return opt;
                })
            break;  
            case 1:
                greekAlphaOptions = this.greekAlphabetOptions.map((opt, i) => {
                    if(opt.value == " " && i == 0){
                        return {
                            label: `Select ${(index+1)}nd Greek Letter`,
                            value: opt.value
                        }
                    } else return opt;
                })
            break;
            case 2:
                greekAlphaOptions = this.greekAlphabetOptions.map((opt, i) => {
                    if(opt.value == " " && i == 0){
                        return {
                            label: `Select ${(index+1)}rd Greek Letter`,
                            value: opt.value
                        }
                    } else return opt;
                })
            break;
            case 3:
            case 4:
            case 5:
                greekAlphaOptions = this.greekAlphabetOptions.map((opt, i) => {
                    if(opt.value == " " && i == 0){
                        return {
                            label: `Select ${(index+1)}th Greek Letter`,
                            value: opt.value
                        }
                    } else return opt;
                })
            break;  
        }

        const a = greekAlphaOptions.find(
          (i) => i.value === selectedGreekText[index]
        );
        return (
          <GreekDropDownWrapper key={index}>
            <Dropdown
              disabled
              options={greekAlphaOptions}
              onChange={(value: { label: string; value: GREEK_ALPHABETS }) =>
                this.setGreekTextChange(value, index)
              }
              value={a}
              placeholder={`Select Alphabet ${index + 1}`}
            />
          </GreekDropDownWrapper>
        );
      });
    };

    const getNumbers = () => {
      return selectedNumber?.map((number, index) => {
        const a = this.NumberOptions.find(
          (i) => i.value === selectedNumber[index]
        );
        return (
          <GreekDropDownWrapper key={index}>
            <Dropdown
              options={this.NumberOptions}
              onChange={(value: { label: string; value: NUMBERS }) =>
                this.setNumberChange(value, index)
              }
              value={a}
              placeholder={`Select Number ${index + 1}`}
            />
          </GreekDropDownWrapper>
        );
      });
    };

    const isRenderGreekorNumber = () => {
      const { selectedSide } = this.props;
      if (selectedSide === "back") {
        if (greekorNumber === "greek") {
          return getGreekAlphabets();
        } else {
          return getNumbers();
        }
      } else {
        return getGreekAlphabets();
      }
    };

    const isRenderLetterPlacement = () => {
      return (
        appliqueData.letterPlacement && (
          <LetterPlacementContainer>
            <TextZeta className="bold">Letter Placement</TextZeta>
            <Dropdown
              label=""
              options={letterplacementId
                ?.map(({ name, side, anchor, x_axis, y_axis }) => ({
                  label: name,
                  value: anchor,
                  side,
                  x_axis,
                  y_axis,
                }))
                .filter((i) => i.side === selectedSide)}
              onChange={this.setLetterPlacementChange}
              value={letterPlacement}
            />
          </LetterPlacementContainer>
        )
      );
    };

    const handleIsCenterApplique = (isCenterApplique: any) => {
      //console.log(isCenterApplique, "isCenterAppliqueisCenterApplique");
      this.props.updateAppliqueView({
        ...this.props.appliqueView,
        [selectedSide]: {
          ...this.props.appliqueView[selectedSide],
          isCenterApplique:
            !this.props.appliqueView[selectedSide].isCenterApplique,
        },
      });
      if (isCenterApplique) {
        this.props.updatePreview(
          {
            settingName: PreviewSettingNames.letterPlacement,
            settingVal: {
              value: {
                x_axis:
                  (typeof this.props.appliqueView[selectedSide].letterPlacement !== "undefined")?this.props.appliqueView[selectedSide].letterPlacement.value
                    .x_axis:35,
                y_axis:
                  (typeof this.props.appliqueView[selectedSide].letterPlacement !== "undefined")?this.props.appliqueView[selectedSide].letterPlacement.value
                    .y_axis:35,
              },
            },
          },
          DesignerViewStates.Applique
        );
      } else {
        this.props.updatePreview(
          {
            settingName: PreviewSettingNames.letterPlacement,
            settingVal: {
              value: {
                x_axis: 35,
                y_axis: 35,
              },
            },
          },
          DesignerViewStates.Applique
        );
      }
    };

    const handleIsHeatSealedLetters = () => {
      /* console.log(
        "this.props.appliqueView[selectedSide].heatSealedLetter : ",
        this.props.appliqueView[selectedSide].heatSealedLetter
      ); */
      if (this.props.appliqueView[selectedSide].heatSealedLetter) {
        /* console.log("handleIsHeatSealedLetters-PriceToCart"); */
        this.props.appliqueView[selectedSide].heatSealedLetter.isactive
          ? this.props.removePriceToCart({
              price: parseFloat(
                (this.props.appliqueView[selectedSide].heatSealedLetter as any)[
                  "cost"
                ]
              ),
            })
          : this.props.addPriceToCart({
              price: parseFloat(
                (this.props.appliqueView[selectedSide].heatSealedLetter as any)[
                  "cost"
                ]
              ),
            });
      }
      this.props.updateAppliqueView({
        ...this.props.appliqueView,
        [selectedSide]: {
          ...this.props.appliqueView[selectedSide],
          heatSealedLetter: {
            ...this.props.appliqueView[selectedSide].heatSealedLetter,
            isactive:
              !this.props.appliqueView[selectedSide].heatSealedLetter.isactive,
          },
        },
      });
    };

    if (appliqueData.name == "Imprint") {
      appliqueData.heatSealedLetter = {
        ...selectedProduct.heatSealedLetter,
        isactive: false
      };
    }

    console.log("appliqueData.name: ", appliqueData.name);

    // if (selectedSide === "back" && isCenterApplique) {
    //   this.props.updatePreview(
    //     {
    //       settingName: PreviewSettingNames.letterPlacement,
    //       settingVal: {
    //         value: {
    //           x_axis: 50,
    //           y_axis: 50,
    //         },
    //       },
    //     },
    //     DesignerViewStates.Applique
    //   );
    // }

    return (
      <>
        {/* {selectedSide === "back" && (
          <Checkbox
            label={"Center of Back Number"}
            value={isCenterApplique}
            checked={isCenterApplique}
            onChange={() => handleIsCenterApplique(isCenterApplique)}
          />
        )} */}
        {selectedSide === "back" && isCenterApplique && (
          <RadioContainer>
            <div style={{ display: "flex", gap: "10px" }}>
            <RadioButton
                type="radio"
                name={"numbers"}
                label={"Numbers (Center of Back)"}
                checked={greekorNumber === "numbers"}
                onChange={(e: any) => {

                  this.props.alphabetTypeContainer.current.style.display="none";

                  this.props.updateAppliqueView({
                    ...this.props.appliqueView,
                    [selectedSide]: {
                      ...this.props.appliqueView[selectedSide],
                      greekorNumber: e.target.name,
                      selectedGreekText: [
                        GREEK_ALPHABETS["Select Letter"],
                        GREEK_ALPHABETS["Select Letter"],
                        GREEK_ALPHABETS["Select Letter"],
                        GREEK_ALPHABETS["Select Letter"],
                        GREEK_ALPHABETS["Select Letter"],
                        GREEK_ALPHABETS["Select Letter"],
                      ],
                    },
                  });

                  // this.props.resetForm(selectedSide, {
                  //   greekorNumber: e.target.name,
                  // });
                  this.props.updatePreview(
                    {
                      settingName: PreviewSettingNames.contentText,
                      settingVal: {
                        value: defaulGreekText,
                      },
                    },
                    DesignerViewStates.Applique
                  );
                }}
              />
              <RadioButton
                type="radio"
                name={"greek"}
                label={"Greek Alphabet (Center of Back)"}
                checked={greekorNumber === "greek"}
                onChange={(e: any) => {

                  this.props.alphabetTypeContainer.current.style.display="inline-flex";

                  const { selectedSide } = this.props;
                  /* const newNumbersList: NumbersList = [
                    ...this.props.appliqueView[selectedSide].selectedNumber!,
                  ]; */

                  let newNumbersList = [
                    ...this.props.appliqueView[selectedSide].selectedNumber!,
                  ];

                  if (newNumbersList[2] != " ") {
                    /* console.log("greekorNumber-greek-removePriceToCart"); */
                    this.props.removePriceToCart({
                      price: 2,
                    });
                  }

                  this.props.updateAppliqueView({
                    ...this.props.appliqueView,
                    [selectedSide]: {
                      ...this.props.appliqueView[selectedSide],
                      greekorNumber: e.target.name,
                      selectedNumber: [
                        NUMBERS["Select Numbers"],
                        NUMBERS["Select Numbers"],
                        NUMBERS["Select Numbers"],
                      ],
                    },
                  });
                  // this.props.resetForm(selectedSide, {
                  //   greekorNumber: e.target.name,
                  // });
                  this.props.updatePreview(
                    {
                      settingName: PreviewSettingNames.contentText,
                      settingVal: {
                        value: defaulGreekText,
                      },
                    },
                    DesignerViewStates.Applique
                  );
                }}
              />
              
            </div>
          </RadioContainer>
        )}

        <FormContainer>
          {isRenderGreekorNumber()}
          {selectedSide === "back"
            ? !isCenterApplique
              ? isRenderLetterPlacement()
              : null
            : isRenderLetterPlacement()}
        </FormContainer>
        
        <div>
          {appliqueData.stitchingType && (
            <LeftSection>
              <TextZeta className="bold">Stitching Type</TextZeta>
              <RadioContainer>
                {stitchingTypeId.map((type, i) => {
                  return (
                    <div
                      key={`stitchingType` + i}
                      style={{ display: "flex", gap: "10px" }}
                    >
                      <RadioButton
                        type="radio"
                        name={stitchingType.stitchingType}
                        label={type.stitchingType}
                        disabled={
                          (selectedStroke.value !== "" ||
                            selectedBgStroke.value !== "") &&
                          type.stitchingType === "Standard"
                        }
                        onChange={() => {
                          this.setStichType(type);
                        }}
                        checked={
                          type.stitchingType === stitchingType.stitchingType
                        }
                      />
                      {type.price !== 0 && (
                        <StyledPrice>
                          +({type.currency}
                          {type.price})
                        </StyledPrice>
                      )}
                    </div>
                  );
                })}
              </RadioContainer>
            </LeftSection>
          )}
        </div>
        <div>
          {appliqueData.letterSize && greekorNumber === "greek" && (
            <RightSection>
              <TextZeta className="bold">Letter Size</TextZeta>
              <RadioContainer>
                {greekLetterSizeId.map((greekLetter, i) => (
                  <RadioButton
                    key={`greekLetterSize` + i}
                    name="letterSize"
                    label={`${greekLetter.size} ${i==0?'(Default)':''}`}
                    onChange={() => this.setLetterSize(greekLetter)}
                    checked={greekLetter.size === letterSize.size}
                    // onChange={() => {}}
                  />
                ))}
              </RadioContainer>
            </RightSection>
          )}
        </div>
        <div>
          {subCategoryId.name.toLocaleUpperCase().includes("STOLE") && (
            <div
            style={{
              boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)",
              border: "1px solid #ccc",
              padding: "10px 6px",
              borderRadius: "0.25rem",
              margin: "10px 0",
            }}
          >
            <TextZeta
              className="bold"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              Sash -
              <a
                href="/colors"
                target="_blank"
                style={{
                  fontSize: "12px",
                  textDecoration: "none",
                  paddingLeft: "10px",
                }}
              >
                View Colors
              </a>
            </TextZeta>
            <FormGroupColumn>
              {/* <DropdownWapper>
                <Dropdown
                  label="Select Foreground Material"
                  options={foreGroundMaterialOptions}
                  onChange={(selectedOption: any) =>
                    this.setForeGroundFillStorkeAndMarker(
                      selectedOption.value,
                      ForegroundBackgroundSettings.material
                    )
                  }
                  value={selectedForegroundMaterial}
                  // label="Select Foreground Material"
                />
              </DropdownWapper> */}
              <DropdownWapper>
                <Dropdown
                  label="Body Color"
                  options={[
                    Body_Color_Select,
                    ...sashFillOptions,
                  ]}
                  onChange={(selectedOption: any) =>
                    this.setSashFillStorkeAndMarker(
                      selectedOption.value,
                      ForegroundBackgroundSettings.fill
                    )
                  }
                  // label="Select Foreground Color"
                  value={selectedSashFill}
                />
              </DropdownWapper>
              <DropdownWapper>
                <Dropdown
                  label="Trim Color"
                  options={[
                    Trim_Color_Select,
                    ...availableThreadOptions,
                  ]}
                  onChange={(selectedOption: any) =>
                    this.setSashFillStorkeAndMarker(
                      selectedOption.value,
                      ForegroundBackgroundSettings.stroke
                    )
                  }
                  // label="Thread Color"
                  value={selectedSashStroke}
                />
              </DropdownWapper>
            </FormGroupColumn>
          </div>
          )}
        </div>
        <div>
          {appliqueData.foreground && (
            <div
              style={{
                boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)",
                border: "1px solid #ccc",
                padding: "10px 6px",
                borderRadius: "0.25rem",
                margin: "10px 0",
              }}
            >
              <TextZeta
                className="bold"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                Foreground 
                <a
                  href="/colors"
                  target="_blank"
                  style={{
                    fontSize: "12px",
                    textDecoration: "none",
                    paddingLeft: "10px",
                  }}
                >
                  View Colors
                </a>
              </TextZeta>
              <FormGroupColumn>
                <DropdownWapper>
                  <Dropdown
                    label="Select Foreground Material"
                    options={foreGroundMaterialOptions}
                    onChange={(selectedOption: any) =>
                      this.setForeGroundFillStorkeAndMarker(
                        selectedOption.value,
                        ForegroundBackgroundSettings.material
                      )
                    }
                    value={selectedForegroundMaterial}
                    // label="Select Foreground Material"
                  />
                </DropdownWapper>
                <DropdownWapper>
                  <Dropdown
                    label="Foreground Color"
                    options={[
                      Foreground_Color_Select,
                      ...foreGroundFillOptions,
                    ]}
                    onChange={(selectedOption: any) =>
                      this.setForeGroundFillStorkeAndMarker(
                        selectedOption.value,
                        ForegroundBackgroundSettings.fill
                      )
                    }
                    // label="Select Foreground Color"
                    value={selectedforeGroundFill}
                  />
                </DropdownWapper>
                <DropdownWapper>
                  <Dropdown
                    label="Foreground Thread Color"
                    options={[
                      Foreground_Thread_Select,
                      ...availableThreadOptions,
                    ]}
                    onChange={(selectedOption: any) =>
                      this.setForeGroundFillStorkeAndMarker(
                        selectedOption.value,
                        ForegroundBackgroundSettings.stroke
                      )
                    }
                    // label="Thread Color"
                    value={selectedStroke}
                  />
                </DropdownWapper>
              </FormGroupColumn>
            </div>
          )}
          {(appliqueData.background ||
            appliqueData.name == "Twill Letters") && (
            <div
              style={{
                boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)",
                border: "1px solid #ccc",
                padding: "10px 6px",
                borderRadius: "0.25rem",
                margin: "10px 0",
              }}
            >
              <TextZeta
                className="bold"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                Background 
                <a
                  href="/colors"
                  target="_blank"
                  style={{
                    fontSize: "12px",
                    textDecoration: "none",
                    paddingLeft: "10px",
                  }}
                >
                  View Colors
                </a>
              </TextZeta>
              <FormGroupColumn>
                <div>
                  <Dropdown
                    label="Select Background Material"
                    options={backgroundMaterialOptions}
                    value={selectedBackgroundMaterial}
                    onChange={(selectedOption: any) =>
                      this.setBackGroundFillStorkeAndMarker(
                        selectedOption.value,
                        ForegroundBackgroundSettings.material
                      )
                    }
                    // label="Select Background Material"
                  />
                </div>
                <div>
                  <Dropdown
                    label="Background Color"
                    options={[Background_Color_Select, ...backgroundFills]}
                    value={selectedBackgroundFills}
                    onChange={(selectedOption: any) =>
                      this.setBackGroundFillStorkeAndMarker(
                        selectedOption.value,
                        ForegroundBackgroundSettings.fill
                      )
                    }
                    // label="Select Background Color"
                  />
                </div>
                <div>
                  <Dropdown
                    label="Background Thread Color"
                    options={[
                      Background_Thread_Select,
                      ...availableThreadOptions,
                    ]}
                    value={selectedBgStroke}
                    onChange={(selectedOption: any) =>
                      this.setBackGroundFillStorkeAndMarker(
                        selectedOption.value,
                        ForegroundBackgroundSettings.stroke
                      )
                    }
                    // label="Thread Color"
                  />
                </div>
              </FormGroupColumn>
            </div>
          )}
        </div>
        {appliqueData.heatSealedLetter && (
          <div style={{ display: "flex" }}>
            <Checkbox
              value={heatSealedLetter}
              checked={heatSealedLetter.isactive}
              onChange={() => handleIsHeatSealedLetters()}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                marginLeft: "1.5rem",
                flexFlow: "column wrap",
              }}
            >
              <span>
                {" "}
                Heat Sealed Letters{" "}
                <StyledPrice>+(${heatSealedLetter.cost})</StyledPrice>
              </span>
              <span style={{ fontSize: ".8rem" }}>
                Increase the longevity of your applique letters, by making them
                bond with the garment.
              </span>
            </div>
          </div>
        )}

        {appliqueData.specialInstruction && (
          <BottomSection>
            <Textarea
              label="Special Instructions"
              onChange={this.setSpecialInstructions}
              value={specialInstructions}
            ></Textarea>
          </BottomSection>
        )}

        <div
          style={{
            boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)",
            border: "1px solid #ccc",
            padding: "10px 6px",
            borderRadius: "0.25rem",
            margin: "10px 0",
          }}
        >
          <label>Custom File Upload</label>
          <FormGroupColumn>
            <ImperativeFilePicker
              setCustomFileChange={this.setCustomFileChange}
              files={customFiles}
              appliqueInfo={appliqueData}
            />
          </FormGroupColumn>
        </div>
      </>
    );
  }
}

const ImperativeFilePicker = ({
  setCustomFileChange,
  files,
  appliqueInfo,
}: any) => {
  const {
    openFilePicker,
    filesContent,
    loading,
    errors,
    plainFiles,
    clear,
    removeFileByIndex,
    removeFileByReference,
  } = useImperativeFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: true,
    onFilesSelected: ({ plainFiles, filesContent, errors }) => {
      // this callback is always called, even if there are errors
      //console.log("onFilesSelected", plainFiles, filesContent, errors);
    },
    onFilesRejected: ({ errors }) => {
      // this callback is called when there were validation errors
      //console.log("onFilesRejected", errors);
    },
    onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
      // this callback is called when there were no validation errors
      //console.log("onFilesSuccessfullySelected", plainFiles, filesContent);
      //console.log("plainFiles", plainFiles);
      setCustomFileChange(filesContent);
    },
    onFileRemoved: (removedFile, removedIndex) => {
      // this callback is called when a file is removed from the list of selected files
      //console.log("onFileRemoved", removedFile, removedIndex);
    },
    validators: [
      new FileAmountLimitValidator({ max: 4 }),
      new FileTypeValidator(["jpg", "jpeg", "png"]),
      new FileSizeValidator({ maxFileSize: 2 * 1024 * 1024 /* 2 MB */ }),
      new ImageDimensionsValidator({
        maxHeight: 900, // in pixels
        maxWidth: 1600,
        minHeight: 50,
        minWidth: 50,
      }),
    ],
  });

  const removeImage = (removedIndex: number) => {
    // this callback is called when a file is removed from the list of selected files
    //console.log("remove Index", removedIndex);
    let fileArr = files;
    fileArr.splice(removedIndex, 1);
    setCustomFileChange(fileArr);
  };

  const clearAll = () => {
    clear();
    setCustomFileChange([]);
  };

  useEffect(() => {
    clear();
  }, [files]);

  if (errors.length) {
    /* console.log(errors); */

    return (
      <div>
        <button onClick={() => openFilePicker()}>
          Something went wrong, retry!{" "}
        </button>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {errors.map((err: any, i) => (
            <div key={`err_${i}`}>
              {err.name} : {err.reason}
              {/* e.g. "name":"FileAmountLimitError",
              "reason":"MAX_AMOUNT_OF_FILES_EXCEEDED" */}
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <button onClick={async () => openFilePicker()}>Select File(s)</button>
      <button onClick={() => clearAll()}>Remove All</button>
      <br />
      No. of Selected Files:
      {files.length}
      <br />
      {/*Amount of filesContent:
      {filesContent.length}
  <br />*/}
      <div
        style={
          appliqueInfo.name == "Imprint"
            ? {
                marginBottom: "16px",
                display: "flex",
                justifyContent: "space-around",
                flexWrap: "wrap",
              }
            : {
                marginBottom: "16px",
                display: "flex",
                flexDirection: "column",
                flexWrap: "wrap",
              }
        }
      >
        {files.map((file: any, i: number) => (
          <div style={{ margin: "5px" }} key={`files_${i}`}>
            <div
              style={{ display: "flex", alignItems: "center" }}
              key={file.name}
            >
              <div>{file.name}</div>
              <button style={{ marginLeft: 24 }} onClick={() => removeImage(i)}>
                Remove
              </button>
              {/*<button
              style={{ marginLeft: 24 }}
              onClick={() => removeFileByIndex(i)}
            >
              Remove by index
            </button>*/}
            </div>
            <div>
              {appliqueInfo.name == "Imprint" && (
                <img alt={file.name} src={file.content}></img>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state: IAppState) => {
  const {
    appliqueOptions: {
      availableBackgroundOptions,
      availableForegroundOptions,
      fontStyleOptions,
      isCustomizationOptionsLoaded,
      availableThreadOptions,
    },
    appliques,
    selectedApplique,
    appliqueView,
  } = state.applique;
  const {
    selectedProduct: { letterplacementId, stitchingTypeId, greekLetterSizeId, subCategoryId },
  } = state.products;

  let actualGreekLetterSizeId:any = [];
  if(subCategoryId.name.toLocaleUpperCase().includes("STOLE")){
    actualGreekLetterSizeId = greekLetterSizeId.map((greekLetterSize, i) => {
      let inch = parseInt(greekLetterSize.size.split('"')[0]);
      if(inch < 4){
        return greekLetterSize;
      }
    }).filter((item) => item != null);
  } else actualGreekLetterSizeId = greekLetterSizeId;

  return {
    subCategoryId,
    letterplacementId,
    stitchingTypeId,
    availableForegroundOptions,
    availableBackgroundOptions,
    greekLetterSizeId: actualGreekLetterSizeId.sort((a: any, b: any) => {
      if ( a._id < b._id ){
        return 1;
      }
      if ( a._id > b._id ){
        return -1;
      }
      return 0;
    }),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    addPriceToCart: (data: any) => dispatch(addPriceToCart(data)),
    removePriceToCart: (data: any) => dispatch(removePriceToCart(data)),
  };
};

export const GreekTextSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(GreekTextSettingsComponent);

const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${marginM};
`;

const GreekDropDownWrapper = styled.div`
  width: 27%;
  margin: 0 10px 10px 0;
`;

const LetterPlacementContainer = styled.div`
  // width: 50%;
  margin: 0 10px 10px 0;
`;

const LeftSection = styled.section`
  flex: 1;
  margin-right: ${marginL};
`;

const RightSection = styled.section`
  flex: 1;
`;

const RadioContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const FormGroupColumn = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BottomSection = styled.section``;

const DropdownWapper = styled.div`
  margin: 0 15px 10px 0;
`;

const StyledPrice = styled.span<{ color?: string }>`
  text-align: center;
  font-size: 0.85rem;
  color: ${(props) => props.color || "red"};
  flex: 1;
  align-self: center;
`;
