import React from "react";
import * as Style from "./Styled.component"; // Assuming you're importing styled components or custom styles
import ShippingRatesTable from "./ShippingRatesTable";
import SideMenuBar from "./SideMenuBar";
import { sidebarMenu } from "./StaticData.Constant";

const ShippingAndPickup = () => {
  return (
    <Style.MainLayoutSection>
    <SideMenuBar menuItems={sidebarMenu}/>
    <Style.PageContainer>
      {/* Main Heading */}
      <Style.MainHeading>SHIPPING & PICK-UP</Style.MainHeading>

      {/* Static content for shipping and pick-up */}
      <Style.SectionContainer>
        {/* Shipping vs. Pick-Up: Choose What Works Best for You */}
        <Style.Section>
          <Style.SubHeading>Shipping vs. Pick-Up: Choose What Works Best for You</Style.SubHeading>
          <Style.Paragraph>
            At StitchZone, we understand that convenience is key when it comes to receiving your beautifully crafted embroidery pieces. That’s why we offer you the flexibility to choose between shipping your order directly to your doorstep or picking it up in person at our location.
          </Style.Paragraph>
        </Style.Section>

        {/* Shipping Options */}
        <Style.Section>
          <Style.SubHeading>Shipping Options:</Style.SubHeading>
          <Style.Paragraph>
            - Nationwide Delivery: Enjoy the convenience of having your order shipped right to your door. We offer reliable shipping services to ensure your embroidered items arrive safely and on time, wherever you are in the country.
          </Style.Paragraph>
        </Style.Section>

        {/* Shipping Rates */}
        <Style.Section>
          <Style.SubHeading>Shipping Rates:</Style.SubHeading>
          <Style.Paragraph>
            - Our shipping rates are calculated based on your location and the size of your order. We strive to keep these costs affordable while maintaining high standards of delivery. Please note that international and RUSH shipments will be subject to additional charges. Select the shipping option that best suits your needs during checkout. Prices vary based on the delivery speed selected and the total weight of your order.
          </Style.Paragraph>
        </Style.Section>

        {/* Domestic Shipping Rates */}
        <Style.Section>
          <Style.SubHeading>Domestic Shipping Rates (within the United States):</Style.SubHeading>
          <Style.Paragraph>
            - Tracking: Stay informed with tracking information provided for all shipped orders. You’ll know exactly when to expect your package.
          </Style.Paragraph>
        </Style.Section>

        {/* Pick-Up Option */}
        <Style.Section>
          <Style.SubHeading>Pick-Up Option:</Style.SubHeading>
          <Style.Paragraph>
            - Local Pick-Up: If you’re located nearby or prefer a more personal touch, you can choose to pick up your order directly from our store. This option allows you to inspect your items in person and interact with our friendly staff.
            - Convenience: Skip the shipping time and costs by picking up your order at a time that suits you. We’ll notify you as soon as your items are ready for collection.
          </Style.Paragraph>
        </Style.Section>

        {/* How to Choose */}
        <Style.Section>
          <Style.SubHeading>How to Choose:</Style.SubHeading>
          <Style.Paragraph>
            When placing your order, simply select your preferred delivery method during checkout. Whether you opt for the convenience of shipping or the personalized service of pick-up, we are committed to ensuring your experience with StitchZone is seamless and enjoyable.
          </Style.Paragraph>
        </Style.Section>

        {/* Contact for Questions */}
        <Style.Section>
          <Style.SubHeading>For Any Questions:</Style.SubHeading>
          <Style.Paragraph>
            For any questions regarding shipping rates, pick-up times, or general inquiries, feel free to reach out to our customer service team. We’re here to assist you every step of the way.
          </Style.Paragraph>
        </Style.Section>
      </Style.SectionContainer>

      {/* Optional Rendering of Shipping Rates Table */}
      <ShippingRatesTable />
    </Style.PageContainer>
   </Style.MainLayoutSection>
  );
};

export default ShippingAndPickup;
