import React from 'react';
import * as Style from './Styled.component';
import SideMenuBar from './SideMenuBar';
import { sidebarMenu } from './StaticData.Constant';

const AmbassadorPage: React.FC = () => {
  return (
    <Style.MainLayoutSection>
    <SideMenuBar menuItems={sidebarMenu}/>
    <Style.PageContainer>
      <Style.MainHeading>AMBASSADOR PROGRAM</Style.MainHeading>

      <Style.SectionContainer>
        <Style.Section>
          <Style.SubHeading>Partner with Us</Style.SubHeading>
          <Style.Paragraph>
          Welcome to StitchZone’'s Ambassador Program designed for organizations that value creativity and quality craftsmanship! We invite you to join us in promoting our embroidery products while enjoying exclusive benefits and collaborative opportunities.
          </Style.Paragraph>
        </Style.Section>
        <Style.Section>
          <Style.SubHeading>About the Program</Style.SubHeading>
          <Style.Paragraph>
            Our Ambassador Program is tailored for organizations that want to align with a brand known for its dedication to quality and creativity. As an ambassador, your organization will play a crucial role in representing our brand to your community and beyond.
          </Style.Paragraph>
        </Style.Section>
        <Style.Section>
          <Style.SubHeading>Benefits of Partnership</Style.SubHeading>
          <Style.Paragraph>
            <ul>
              <li>Exclusive Discounts: Your organization may receive special discounts on our high-quality embroidery products.</li>
              <li>Early Access: Be the first to know about new product launches and have the opportunity to provide feedback and suggestions.</li>
              <li>Collaboration Opportunities: Partner with us on joint promotional activities, contests, and events to enhance visibility and engagement.</li>
              <li>Feature Opportunities: Showcase your organization's projects and achievements on our platform and social media channels.</li>
            </ul>
          </Style.Paragraph>
        </Style.Section>
        <Style.Section>
          <Style.SubHeading>Ambassador Responsibilities</Style.SubHeading>
          <Style.Paragraph>
            <ul>
              <li>Promotion: Share your organization's experiences with our products on your social media platforms and through your communication channels.</li>
              <li>Content Creation: Create engaging content such as reviews, testimonials, and stories featuring our embroidery products.</li>
              <li>Networking: Connect with other ambassadors and organizations in our network, sharing knowledge and inspiring creativity.</li>
            </ul>
          </Style.Paragraph>
        </Style.Section>
        <Style.Section>
          <Style.SubHeading>How to Apply</Style.SubHeading>
          <Style.Paragraph>
            To apply for our Ambassador Program for organizations, please fill out the application form <a href="#">[link to application form]</a>. We review applications on a rolling basis and will contact selected ambassadors directly.
          </Style.Paragraph>
        </Style.Section>
        <Style.Section>
          <Style.SubHeading>Join Us Today!</Style.SubHeading>
          <Style.Paragraph>
            Partner with us to promote creativity and quality craftsmanship within your organization and beyond. Apply now to become a part of our ambassador community and enjoy exclusive benefits and collaborative opportunities.
          </Style.Paragraph>
        </Style.Section>
        <Style.Section>
          <Style.Paragraph>
            For more information or inquiries, please contact us at <a href="mailto:info@stitchzone.com">info@stitchzone.com</a> or <a href="tel:+15108181182">(510) 818 1182</a>.
          </Style.Paragraph>
        </Style.Section>
      </Style.SectionContainer>
    </Style.PageContainer>
    </Style.MainLayoutSection>
  );
};

export default AmbassadorPage;
