import React from 'react';
import * as Style from './Styled.component';
import SideMenuBar from './SideMenuBar';
import { sidebarMenu } from './StaticData.Constant';

const AboutUsPage: React.FC = () => {
  return (
  <Style.MainLayoutSection>
  <SideMenuBar menuItems={sidebarMenu}/>
    <Style.PageContainer>
      {/* Main Heading */}
      <Style.MainHeading>ABOUT US</Style.MainHeading>

      {/* About Us Content */}
      <Style.SectionContainer>
        {/* General Introduction */}
        <Style.Section>
          <Style.Paragraph>
            StitchZone is a premier embroidery company specializing in custom Greek clothing for fraternities and sororities. With a commitment to quality and personalization, we cater to the unique needs of Greek organizations, companies, sports teams, and individuals seeking custom embroidery solutions.
          </Style.Paragraph>
        </Style.Section>

        {/* Our Vision */}
        <Style.Section>
          <Style.SubHeading>Our Vision</Style.SubHeading>
          <Style.Paragraph>
            At StitchZone, our vision is to be the leading provider of high-quality, custom-embroidered apparel, known for our attention to detail and customer-centric approach. We aim to enhance the pride and unity of organizations and individuals through our bespoke embroidery services.
          </Style.Paragraph>
        </Style.Section>

        {/* What We Offer */}
        <Style.Section>
          <Style.SubHeading>What We Offer</Style.SubHeading>
          <Style.Paragraph>
            1. Greek Clothing: We specialize in creating customized apparel for fraternities and sororities, including shirts, hoodies, jackets, and accessories. Our designs celebrate Greek life and heritage, ensuring members can proudly display their affiliations.
          </Style.Paragraph>
        </Style.Section>

        {/* Corporate Embroidery */}
        <Style.Section>
          <Style.Paragraph>
            2. Corporate Embroidery: StitchZone offers professional embroidery services for companies looking to brand their uniforms, promotional items, and corporate gifts. Our high-quality embroidery adds a touch of professionalism and distinction to your corporate identity.
          </Style.Paragraph>
        </Style.Section>

        {/* Team Apparel */}
        <Style.Section>
          <Style.Paragraph>
            3. Team Apparel: Whether for sports teams, clubs, or community groups, we provide custom embroidery for uniforms, hats, and gear. Our designs help foster team spirit and unity.
          </Style.Paragraph>
        </Style.Section>

        {/* Personalized Items */}
        <Style.Section>
          <Style.Paragraph>
            4. Personalized Items: From monogrammed towels to personalized gifts, we cater to individuals seeking custom embroidery for personal use. Our services are perfect for adding a unique touch to your belongings or gifting a special someone.
          </Style.Paragraph>
        </Style.Section>

        {/* Our Commitment to Quality */}
        <Style.Section>
          <Style.SubHeading>Our Commitment to Quality</Style.SubHeading>
          <Style.Paragraph>
            Quality is at the heart of everything we do at StitchZone. We use top-of-the-line embroidery machines and premium threads to ensure that every stitch is precise and durable. Our experienced team works diligently to bring your designs to life with meticulous attention to detail.
          </Style.Paragraph>
        </Style.Section>

        {/* Customization Process */}
        <Style.Section>
          <Style.SubHeading>Customization Process</Style.SubHeading>
          <Style.Paragraph>
            Our customization process is designed to be seamless and customer-friendly:
          </Style.Paragraph>
        </Style.Section>

        {/* Process Details */}
        <Style.Section>
          <Style.Paragraph>
            • Consultation: We begin with a detailed consultation to understand your specific needs and design preferences.
          </Style.Paragraph>
        </Style.Section>
       
        
        <Style.Section>
          <Style.Paragraph>
            • Embroidery: Using advanced technology, we embroider your approved design onto the selected apparel or items.
          </Style.Paragraph>
        </Style.Section>
        
        <Style.Section>
          <Style.Paragraph>
            • Quality Check: Every piece undergoes a rigorous quality check to ensure it meets our high standards before delivery.
          </Style.Paragraph>
        </Style.Section>

        {/* Join the StitchZone Community */}
        <Style.Section>
          <Style.SubHeading>Join the StitchZone Community</Style.SubHeading>
          <Style.Paragraph>
            Join a community that values quality, creativity, and personalization. At StitchZone, we are dedicated to providing exceptional embroidery services that meet your unique needs. Whether you're part of a Greek organization, a business, a team, or an individual, StitchZone is here to help you make a statement.
          </Style.Paragraph>
        </Style.Section>

        {/* Contact Us */}
        <Style.Section>
          <Style.SubHeading>Contact Us</Style.SubHeading>
          <Style.Paragraph>
            • Website: <a href="https://www.stitchzone.com/" target="_blank" rel="noopener noreferrer">www.stitchzone.com</a>
          </Style.Paragraph>
        </Style.Section>

        <Style.Section>
          <Style.Paragraph>
            • Email: <a href="mailto:info@stitchzone.com">info@stitchzone.com</a>
          </Style.Paragraph>
        </Style.Section>

        {/* Social Media Links */}
        <Style.Section>
          <Style.Paragraph>
            • Follow us on social media:
          </Style.Paragraph>
          <Style.Paragraph>
            <a href="https://m.facebook.com/profile.php?id=100063908976508" target="_blank" rel="noopener noreferrer">Facebook</a> | 
            <a href="https://www.instagram.com/stitchzone/?hl=en" target="_blank" rel="noopener noreferrer"> Instagram</a> | 
            <a href="https://twitter.com/stitchzone?lang=en" target="_blank" rel="noopener noreferrer"> X</a>
          </Style.Paragraph>
        </Style.Section>

        <Style.Section>
          <Style.Paragraph>
            Experience the art of embroidery with StitchZone – where every stitch tells your story.
          </Style.Paragraph>
        </Style.Section>
      </Style.SectionContainer>
    </Style.PageContainer>
    </Style.MainLayoutSection>
  );
};

export default AboutUsPage;
