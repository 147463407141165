import React, { useState } from "react";

import {
  StyledInput,
  StyledButton,
  ButtonContainer,
  StyledTextarea,
  StyeledCheckbox,
  StyledSignup,
} from "./styled.component";
import { footerClient } from "../../ApiClients/FooterClient";
import { toast } from "@happeouikit/toast";
import { useDispatch } from "react-redux";
import { fundraiserResponse } from "../../models/ApiResponses/FooterResponse";



interface FundraiseFormProps {}

interface FormData {
  fundRaiseStartDate:string;
  contactName: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  joinMailingList: boolean;
  phone: string;
  email: string;
  organization: string;
  school: string;
  comments: string;
}

export const FundraiseForm: React.FC<FundraiseFormProps> = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState<FormData>({
    contactName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    joinMailingList: true,
    phone: '',
    email: '',
    organization: '',
    school: '',
    comments: '',
    fundRaiseStartDate:"",
  });

  const [errors, setErrors] = useState<Partial<Record<keyof FormData, string>>>({});

  const validateForm = (): boolean => {
    const newErrors: Partial<Record<keyof FormData, string>> = {};
    const requiredFields = [
      'contactName',
      'fundRaiseStartDate',
      'address1',
      'city',
      'state',
      'zip',
      'phone',
      'email',
      'organization',
      'school',
    ];

    // Loop through the required fields and check if they are empty
    requiredFields.forEach((field) => {
      if (!formData[field as keyof FormData]) {
        newErrors[field as keyof FormData] = `${separateCamelCase(field)} is required`;
      }
    });

    // Validate phone number format (XXX-XXX-XXXX)
    const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;
    if (formData.phone && !phoneRegex.test(formData.phone)) {
      newErrors.phone = 'Phone number must be in the format XXX-XXX-XXXX';
    }

    // Validate email format (basic email validation)
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (formData.email && !emailRegex.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }

    // Validate Zip Code (5 digits)
    if (formData.zip && !/^\d{5}$/.test(formData.zip)) {
      newErrors.zip = 'Zip code must be 5 digits';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    // Format the phone number while typing
    if (name === 'phone') {
      const formattedPhoneNumber = formatPhoneNumber(value);
      setFormData((prevState) => ({
        ...prevState,
        [name]: formattedPhoneNumber,
      }));
    }
     else if(name ==="zip"){
     let zip = value.length >5?value.slice(0,-1) :value
      setFormData((prevState) => ({
        ...prevState,
        [name]:zip.replace(/\D/g, ''),
      }));
    }else if(name === "fundRaiseStartDate"){
      setFormData((prevState) => ({
        ...prevState,
        [name]:applyDateMask(value),
      }));
    }
      else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const applyDateMask = (input: string) => {
    // Remove all non-numeric characters
    let value = input;
    // Remove any non-digit characters
    value = value.replace(/\D/g, '');

    // Ensure the input is no longer than 10 characters (mm-dd-yyyy)
    if (value.length > 8) {
      value = value.substring(0, 8);
    }
    if(value.length ===1 && parseInt( value.substring(0, 1),10) >1){
      value= "0"+value
    }
    // Format the date input to mm-dd-yyyy with auto-adjusting hyphens
    if (value.length >= 3) {
      value = value.substring(0, 2) + '-' + value.substring(2);
    }
    if (value.length === 4 && parseInt(value.substring(3, 4), 10) > 3) {
      value = value.substring(0, 3) + '0' + value.substring(3);
    }
    if (value.length >= 6) {
      value = value.substring(0, 5) + '-' + value.substring(5);
    }

    // Handle month and day limits
    if (value.length >= 2) {
      const month = parseInt(value.substring(0, 2), 10);
      if (month > 12) {
        value = '12' + value.substring(2);
      }
    }

    if (value.length >= 5) {
      const day = parseInt(value.substring(3, 5), 10);
      const month = parseInt(value.substring(0, 2), 10);
      if (month === 2) {
        // handled February for leap year (assuming 29 days every year)
        const maxDay = day > 29 ? 29 : day;
        value = value.substring(0, 3) + (maxDay < 10 ? '0' + maxDay : maxDay) + value.substring(5);
      } else if ([4, 6, 9, 11].includes(month)) {
        // Handle months with 30 days
        const maxDay = day > 30 ? 30 : day;
        value = value.substring(0, 3) + (maxDay < 10 ? '0' + maxDay : maxDay) + value.substring(5);
      } else if (day > 31) {
        // Handle months with 31 days
        value = value.substring(0, 3) + '31' + value.substring(5);
      }
    }

    return value;
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      joinMailingList: checked,
    }));
  };
  // Separate camel case for error messages
  function separateCamelCase(input: string): string {
    return input
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/^([a-z])/, (match) => match.toUpperCase())
      .trim();
  }

  // Phone number formatting (XXX-XXX-XXXX)
  const formatPhoneNumber = (value: string): string => {
    const cleaned = value.replace(/\D/g, '');

    if (cleaned.length <= 3) {
      return cleaned;
    } else if (cleaned.length <= 6) {
      return `${cleaned.slice(0, 3)}-${cleaned.slice(3)}`;
    } else {
      return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Validate the form before submission
    if (!validateForm()) {
      return; 
    }

    footerClient
      .postFundraiser(formData, dispatch)
       .then((res: fundraiserResponse) => {
                 if (!res?.error) {
                  
                   toast.success({ message: res?.message?res.message : "Email send successfully" });
                  }
               })
               .catch((e) => {
                 let err = JSON.parse(e?.metadata?.apiErrorMessage); 
                 //toast.error({ message: "Log in failed.", delay: 10000 });
                 toast.error({ title: err.message, description: err.error, delay: 10000 });
               });
  };

  const handleClear = () => {
    setFormData({
      contactName: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      joinMailingList: true,
      phone: '',
      email: '',
      organization: '',
      school: '',
      comments: '',
      fundRaiseStartDate:""
    });
    setErrors({}); // Clear errors on clear
  };

  return (
    <>
      <StyledSignup> Sign Up</StyledSignup>
      <p> Please fill out the following information </p>
      <p style={{color: "red"}}> * indicates required fields</p>
      <form onSubmit={handleSubmit}>
      <StyledInput
  type="text"
  name="contactName"
  value={formData.contactName}
  placeholder="Name *"
  autoComplete="off"
  onChange={handleInputChange}
/>
{errors.contactName && <p style={{ color: 'red' }}>{errors.contactName}</p>}

<StyledInput
  type="text"
  name="address1"
  value={formData.address1}
  placeholder="Address Line 1 *"
  autoComplete="off"
  onChange={handleInputChange}
/>
{errors.address1 && <p style={{ color: 'red' }}>{errors.address1}</p>}

<StyledInput
  type="text"
  name="address2"
  value={formData.address2}
  placeholder="Address Line 2"
  autoComplete="off"
  onChange={handleInputChange}
/>
{errors.address2 && <p style={{ color: 'red' }}>{errors.address2}</p>}

<StyledInput
  type="text"
  name="city"
  value={formData.city}
  placeholder="City *"
  autoComplete="off"
  onChange={handleInputChange}
/>
{errors.city && <p style={{ color: 'red' }}>{errors.city}</p>}

<StyledInput
  type="text"
  name="state"
  value={formData.state}
  placeholder="State *"
  autoComplete="off"
  onChange={handleInputChange}
/>
{errors.state && <p style={{ color: 'red' }}>{errors.state}</p>}

<StyledInput
  type="text"
  name="zip"
  value={formData.zip}
  placeholder="ZIP *"
  autoComplete="off"
  onChange={handleInputChange}
/>
{errors.zip && <p style={{ color: 'red' }}>{errors.zip}</p>}

<StyledInput
  type="text"
  name="phone"
  value={formData.phone}
  placeholder="Phone Number *"
  autoComplete="off"
  onChange={handleInputChange}
/>
{errors.phone && <p style={{ color: 'red' }}>{errors.phone}</p>}

<StyledInput
  type="text"
  name="email"
  value={formData.email}
  placeholder="Email *"
  autoComplete="off"
  onChange={handleInputChange}
/>
{errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}

<StyledInput
  type="text"
  name="organization"
  value={formData.organization}
  placeholder="Organization *"
  autoComplete="off"
  onChange={handleInputChange}
/>
{errors.organization && <p style={{ color: 'red' }}>{errors.organization}</p>}

<StyledInput
  type="text"
  name="school"
  value={formData.school}
  placeholder="School *"
  autoComplete="off"
  onChange={handleInputChange}
/>
{errors.school && <p style={{ color: 'red' }}>{errors.school}</p>}

<StyledInput
  type="text"
  name="fundRaiseStartDate"
  value={formData.fundRaiseStartDate}
  placeholder="Start Date (MM-DD-YYYY) *"
  autoComplete="off"
  onChange={handleInputChange}
/>
{errors.fundRaiseStartDate && <p style={{ color: 'red' }}>{errors.fundRaiseStartDate}</p>}

<StyeledCheckbox
  label="Join StitchZone's mailing list"
  checked={formData.joinMailingList} // Bind checkbox value to formData
  onChange={handleCheckboxChange} // Handle checkbox change
/>
{errors.joinMailingList && <p style={{ color: 'red' }}>{errors.joinMailingList}</p>}

<StyledTextarea
  rows={5}
  cols={86}
  name="comments"
  value={formData.comments}
  placeholder="Comments"
  onChange={handleInputChange}
/>
{errors.comments && <p style={{ color: 'red' }}>{errors.comments}</p>}
      <ButtonContainer>
        <StyledButton type="submit">Submit</StyledButton>
        <StyledButton type="button" onClick={handleClear}>
          Clear
        </StyledButton>
      </ButtonContainer>
    </form>
      <div style={{marginBottom: "25px"}} />
    </>
  );
};
