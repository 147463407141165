import React from "react";
import {
  Section,
  Logo,
  Desc,
  FormContainer,
  Title,
  SubTitle,
  Link,
  SignUpCta,
  SignUpLink,
} from "./styled.component";
import { LoginForm } from "./Login.form";
import LogoImg from "../../images/Logo.png";
import SZLogo from "../../images/stitchzone_logo_slogan_white.png";
import { ILoginRequestParams, LoginResponse } from "../../models";
import { authClient } from "../../ApiClients/AuthClient";
import { toast } from "@happeouikit/toast";
import { authCookieName, userDetails } from "../../constants";
import { error } from "console";
interface LoginPageProps {}

interface LoginPageState {}

export class LoginPage extends React.Component<LoginPageProps, LoginPageState> {
  constructor(props: LoginPageProps) {
    super(props);
    this.state = {};
    if (localStorage.getItem("userDetails") != null) {
      window.location.href = "/";
    }
    this.handleLogin = this.handleLogin.bind(this);
  }

  handleLogin(params: ILoginRequestParams) {
    authClient
      .loginUser(params)
      .then((res: LoginResponse) => {
        console.log(res.data);
        if (res?.data?.token) {
          toast.success({ message: res.message });
          document.cookie = `${authCookieName}=${res.data.token};`;
          if (localStorage.getItem("userDetails") != null) {
            localStorage.removeItem("userDetails");
          }

          localStorage.setItem("userDetails", JSON.stringify(res.data.user));
          window.history.back();
        } else {
          toast.error({
            message: "Log in failed. Please try again",
            delay: 10000,
          });
        }
      })
      .catch((e) => {
        console.log("🚀 ~ file: LogIn.page.tsx:46 ~ handleLogIn ~ e", e);
        let err = JSON.parse(e.metadata.apiErrorMessage); 
        //toast.error({ message: "Log in failed.", delay: 10000 });
        if (err.error === "Incorrect credentials!!!"|| err.error ==="user is not registered")
        toast.error({ title: String(err.message).charAt(0).toUpperCase() + String(err.message).slice(1), description: "Incorrect email or password", delay: 10000 });
        else
        toast.error({ title: String(err.message).charAt(0).toUpperCase() + String(err.message).slice(1), description: err.error, delay: 10000 });
      });
  }

  render() {
    return (
      <Section>
        <FormContainer>
          <Title>Welcome Back!</Title>
          <SubTitle>Log In to your account</SubTitle>
          <LoginForm handleLogin={this.handleLogin} />
          <Link href="/forget-password">Forgot Password?</Link>
          <SignUpCta>
            Don't have an account?{" "}
            <SignUpLink href="/sign-up">Sign Up</SignUpLink>
          </SignUpCta>
        </FormContainer>
      </Section>
    );
  }
}
