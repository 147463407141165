import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Dropdown } from "@happeouikit/form-elements";
import {Flex,
  Container,
  Table} from "./styled.component";

import Tab from "./Tab";

const OrderInfo = ({ orderDataItem }) => {
  const [tab, setTab] = useState(1);
  const [appliqueView, setAppliqueView] = useState({
    label: "Front",
    value: "front",
  });

  const [embroidaryLocations, setEmbroidaryLocations] = useState([]);
  const [embroidaryLocationView, setEmbroidaryLocationView] = useState({});

  useEffect(() => {
    if (orderDataItem) {
      if (orderDataItem.customizedProducts.embroidaryId.length > 0) {
        const newA = orderDataItem.customizedProducts.embroidaryId.map((i) => {
          console.log(i,"i in order info")
          return {
            label: i?.name,
            value: i?.name,
          };
        });
        setEmbroidaryLocations(newA);
        setEmbroidaryLocationView(newA[0]);
      }
    }
  }, [orderDataItem]);

  const renderEm = orderDataItem.customizedProducts.embroidaryId.find((i) => {
    return i?.name === embroidaryLocationView.value;
  });

  console.log(
    renderEm,
    "renderEm",
    orderDataItem,
    orderDataItem.customizedProducts.appliqueId[appliqueView.value]
      .stitchingType
  );

  

  const formatPrice = (price) => {
    let formattedPrice = parseFloat(price).toFixed(2);
    return isNaN(price)?"0.00":formattedPrice;
  }

  return (
    <Container>
      <Flex>
        <Title></Title>
        <Tab onChange={setTab} tab={tab} />
      </Flex>
      {tab === 1 && (
        <Flex>
          <Table>
            <tbody border="collapse">
              <tr>
                <td
                  style={{
                    fontSize: "1em",
                    textTransform: "uppercase",
                  }}
                >
                  Name:
                </td>

                <td style={{ color: "#888383" }}>
                  {orderDataItem.productId.name && orderDataItem.productId.name}
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td
                  style={{
                    fontSize: "1em",
                    textTransform: "uppercase",
                  }}
                >
                  Description:
                </td>

                <td style={{ color: "#888383" }}>
                  {orderDataItem.productId.description &&
                    orderDataItem.productId.description}
                </td>
                <td></td>
                <td></td>
                {/*orderDataItem.customizedProducts.previewImages && (
                  <td rowspan="8">
                    {orderDataItem.customizedProducts.previewImages
                      .Applique_front && (
                      <>
                        <h6>{`Applique Front`}</h6>
                        <img
                          src={
                            orderDataItem.customizedProducts.previewImages
                              .Applique_front
                          }
                          alt={`Applique_front preview`}
                          width={"250"}
                          height={"100%"}
                        />
                      </>
                    )}
                    {orderDataItem.customizedProducts.previewImages
                      .Applique_back && (
                      <>
                        <h6>{`Applique Back`}</h6>
                        <img
                          src={
                            orderDataItem.customizedProducts.previewImages
                              .Applique_back
                          }
                          alt={`Applique_back preview`}
                          width={"250"}
                          height={"100%"}
                        />
                      </>
                    )}
                    {orderDataItem.customizedProducts.previewImages
                      .EmboideryLocations_front && (
                      <>
                        <h6>{`Emboidery Front`}</h6>
                        <img
                          src={
                            orderDataItem.customizedProducts.previewImages
                              .EmboideryLocations_front
                          }
                          alt={`EmboideryLocations_front preview`}
                          width={"250"}
                          height={"100%"}
                        />
                      </>
                    )}
                    {orderDataItem.customizedProducts.previewImages
                      .EmboideryLocations_back && (
                      <>
                        <h6>{`Emboidery Back`}</h6>
                        <img
                          src={
                            orderDataItem.customizedProducts.previewImages
                              .EmboideryLocations_back
                          }
                          alt={`EmboideryLocations_back preview`}
                          width={"250"}
                          height={"100%"}
                        />
                      </>
                    )}
                    {orderDataItem.customizedProducts.previewImages
                      .EmboideryLocations_arm_right && (
                      <>
                        <h6>{`Emboidery Right Arm`}</h6>
                        <img
                          src={
                            orderDataItem.customizedProducts.previewImages
                              .EmboideryLocations_arm_right
                          }
                          alt={`EmboideryLocations_arm_right preview`}
                          width={"250"}
                          height={"100%"}
                        />
                      </>
                    )}
                    {orderDataItem.customizedProducts.previewImages
                      .EmboideryLocations_arm_left && (
                      <>
                        <h6>{`Emboidery Left Arm`}</h6>
                        <img
                          src={
                            orderDataItem.customizedProducts.previewImages
                              .EmboideryLocations_arm_left
                          }
                          alt={`EmboideryLocations_arm_left preview`}
                          width={"250"}
                          height={"100%"}
                        />
                      </>
                    )}
                  </td>
                        )*/}
              </tr>
              <tr>
                <td
                  style={{
                    fontSize: "1em",
                    textTransform: "uppercase",
                  }}
                >
                  Item:
                </td>

                <td style={{ color: "#888383" }}>
                  {orderDataItem.productId.item && orderDataItem.productId.item}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontSize: "1em",
                    textTransform: "uppercase",
                  }}
                >
                  Includes:
                </td>

                <td style={{ color: "#888383" }}>
                  {orderDataItem.productId.includes &&
                    orderDataItem.productId.includes}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontSize: "1em",
                    textTransform: "uppercase",
                  }}
                >
                  Color:
                </td>

                <td style={{ color: "#888383" }}>
                  {orderDataItem.customizedProducts?.colors &&
                    orderDataItem.customizedProducts?.colors?.colorName
                      .charAt(0)
                      .toUpperCase() +
                      orderDataItem.customizedProducts?.colors?.colorName.slice(
                        1
                      )}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontSize: "1em",
                    textTransform: "uppercase",
                  }}
                >
                  Base Price:
                </td>
                <td style={{ color: "#888383" }}>
                  {orderDataItem.totalQuantity} item(s)
                </td>
                <td style={{ color: "#888383" }}>
                  {orderDataItem.totalQuantity || 0} x {orderDataItem.currency}
                  {formatPrice(orderDataItem?.productId?.price)}
                </td>
                <td style={{ color: "#888383" }}>
                  {orderDataItem.currency}
                  {formatPrice(orderDataItem.totalQuantity * orderDataItem?.productId?.price)}
                </td>
              </tr>
              <tr>
                <td>Qty & Size:</td>
                <td style={{ color: "#888383" }}>
                  {orderDataItem.customizedProducts?.colors &&
                    orderDataItem.customizedProducts?.colors[
                      "size&quantity"
                    ].map((i) => (
                      <div>
                        {i.quantity} - {i.sizeCode}
                      </div>
                    ))}
                </td>
                <td style={{ color: "#888383" }}>
                  {orderDataItem.customizedProducts?.colors &&
                    orderDataItem.customizedProducts.colors[
                      "size&quantity"
                    ].map((i) => (
                      <div>
                        {i.quantity} x {orderDataItem.currency} {formatPrice(i.sizePrice)}
                      </div>
                    ))}
                </td>
                <td style={{ color: "#888383" }}>
                  {orderDataItem.customizedProducts?.colors &&
                    orderDataItem.customizedProducts.colors[
                      "size&quantity"
                    ].map((i) => (
                      <div>
                        {orderDataItem.currency} {formatPrice(i.quantity * i.sizePrice)}
                      </div>
                    ))}
                </td>
              </tr>
            </tbody>
          </Table>
        </Flex>
      )}
      {tab === 2 && (
        <>
          <Flex>
            <h4>Applique</h4>
            <Dropdown
              options={[
                { label: "Front", value: "front" },
                { label: "Back", value: "back" },
              ]}
              onChange={(val) => {
                setAppliqueView(val);
              }}
              value={appliqueView}
            />
          </Flex>
          <Flex>
            <Table>
              <tbody>
                <tr>
                  {orderDataItem.customizedProducts.appliqueId[
                    appliqueView.value
                  ].stitchingType && (
                    <>
                      <td
                        style={{
                          fontSize: "1em",
                          textTransform: "uppercase",
                        }}
                      >
                        Stitching:
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.stitchingType?.stitchingType
                        }
                      </td>
                      {orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.stitchingType?.price > 0 ? <td style={{ color: "#888383" }}>
                        {orderDataItem.totalQuantity} x{" "}
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.stitchingType?.currency
                        }
                        {
                          formatPrice(orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.stitchingType?.price)
                        }
                      </td> : <td style={{ color: "#888383" }}></td>}
                      {orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.stitchingType?.price > 0 ? <td style={{ color: "#888383" }}>
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.stitchingType?.currency
                        }
                        {formatPrice(orderDataItem.totalQuantity *
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.stitchingType?.price)}
                      </td>: <td style={{ color: "#888383" }}></td>}
                      {orderDataItem.customizedProducts.previewImages ? (
                        <th colspan='2'>Preview</th>
                      ) : (
                        <></>
                      )}
                      {orderDataItem.customizedProducts.appliqueId[
                        appliqueView.value
                      ].customFiles ? (
                        <th colspan='2'>Custom Upload</th>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </tr>
                <tr>
                  <td
                    style={{
                      fontSize: "1em",
                      textTransform: "uppercase",
                    }}
                  >
                    Organization:
                  </td>
                  <td style={{ color: "#888383" }}>
                    {
                      orderDataItem.customizedProducts.appliqueId[
                        appliqueView.value
                      ]?.organization
                    }
                  </td>
                  <td></td>
                  <td></td>
                  {orderDataItem.customizedProducts.previewImages && (
                    <td rowspan="8">
                      {appliqueView.value == "front" &&
                        orderDataItem.customizedProducts.previewImages
                          .Applique_front && (
                          <>
                            <img
                              src={
                                orderDataItem.customizedProducts.previewImages
                                  .Applique_front
                              }
                              alt={`Applique_front preview`}
                            />
                          </>
                        )}

                      {appliqueView.value == "back" &&
                        orderDataItem.customizedProducts.previewImages
                          .Applique_back && (
                          <>
                            <img
                              src={
                                orderDataItem.customizedProducts.previewImages
                                  .Applique_back
                              }
                              alt={`Applique_back preview`}
                            />
                          </>
                        )}
                    </td>
                  )}
                  {orderDataItem.customizedProducts.appliqueId[
                    appliqueView.value
                  ].customFiles && (
                    <td rowspan="8">
                      {orderDataItem.customizedProducts.appliqueId[
                        appliqueView.value
                      ].customFiles.map((imgObj) => (
                        <img
                          src={imgObj.content}
                          alt={imgObj.name}
                          width={"250"}
                          height={"100%"}
                        />
                      ))}
                    </td>
                  )}
                </tr>
                <tr>
                  {orderDataItem.customizedProducts.appliqueId[
                    appliqueView.value
                  ].letterSize && (
                    <>
                      <td
                        style={{
                          fontSize: "1em",
                          textTransform: "uppercase",
                        }}
                      >
                        Letter Size:
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.letterSize?.size
                        }
                      </td>
                      <td></td>
                      <td></td>
                    </>
                  )}
                </tr>
                <tr>
                  <td
                    style={{
                      fontSize: "1em",
                      textTransform: "uppercase",
                    }}
                  >
                    Placement:
                  </td>
                  <td style={{ color: "#888383" }}>
                    {
                      orderDataItem.customizedProducts.appliqueId[
                        appliqueView.value
                      ]?.letterPlacement
                    }
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  {orderDataItem.customizedProducts.appliqueId[
                    appliqueView.value
                  ].foreground.foregroundMaterial && (
                    <>
                      <td
                        style={{
                          fontSize: "1em",
                          textTransform: "uppercase",
                        }}
                      >
                        Foreground:
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.foreground?.foregroundMaterial?.name
                        }
                      </td>
                      {orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.foreground?.foregroundMaterial?.price > 0 ? <td style={{ color: "#888383" }}>
                        {orderDataItem.totalQuantity} x{" "}
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.foreground?.foregroundMaterial?.currency
                        }
                        {
                          formatPrice(orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.foreground?.foregroundMaterial?.price)
                        }
                      </td>:<td style={{ color: "#888383" }}></td>}
                      {orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.foreground?.foregroundMaterial?.price > 0 ? <td style={{ color: "#888383" }}>
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.foreground?.foregroundMaterial?.currency
                        }
                        {formatPrice(orderDataItem.totalQuantity *
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.foreground?.foregroundMaterial?.price)}
                      </td>:<td style={{ color: "#888383" }}></td>}
                    </>
                  )}
                </tr>
                <tr>
                  {orderDataItem.customizedProducts.appliqueId[
                    appliqueView.value
                  ].foreground.threadColor && (
                    <>
                      <td
                        style={{
                          fontSize: "1em",
                          textTransform: "uppercase",
                        }}
                      >
                        FG Thread:
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.foreground?.threadColor?.name
                        }
                      </td>
                      {orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.foreground?.threadColor?.price > 0 ? <td style={{ color: "#888383" }}>
                        {orderDataItem.totalQuantity} x{" "}
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.foreground?.threadColor?.currency
                        }
                        {
                          formatPrice(orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.foreground?.threadColor?.price)
                        }
                      </td>:<td style={{ color: "#888383" }}></td>}
                      {orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.foreground?.threadColor?.price > 0 ? <td style={{ color: "#888383" }}>
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.foreground?.threadColor?.currency
                        }
                        {formatPrice(orderDataItem.totalQuantity *
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.foreground?.threadColor?.price)}
                      </td>:<td style={{ color: "#888383" }}></td>}
                    </>
                  )}
                </tr>
                <tr>
                  {orderDataItem.customizedProducts.appliqueId[
                    appliqueView.value
                  ].background.backgroundMaterial && (
                    <>
                      <td
                        style={{
                          fontSize: "1em",
                          textTransform: "uppercase",
                        }}
                      >
                        Background:
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.background?.backgroundMaterial?.name
                        }
                      </td>
                      {orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.background?.backgroundMaterial?.price > 0 ? <td style={{ color: "#888383" }}>
                        {orderDataItem.totalQuantity} x{" "}
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.background?.backgroundMaterial?.currency
                        }
                        {
                          formatPrice(orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.background?.backgroundMaterial?.price)
                        }
                      </td>:<td style={{ color: "#888383" }}></td>}
                      {orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.background?.backgroundMaterial?.price > 0 ? <td style={{ color: "#888383" }}>
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.background?.backgroundMaterial?.currency
                        }
                        
                        {formatPrice(orderDataItem.totalQuantity * orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.background?.backgroundMaterial?.price)}
                      </td>:<td style={{ color: "#888383" }}></td>}
                    </>
                  )}
                </tr>
                <tr>
                  {orderDataItem.customizedProducts.appliqueId[
                    appliqueView.value
                  ].background.threadColor && (
                    <>
                      <td
                        style={{
                          fontSize: "1em",
                          textTransform: "uppercase",
                        }}
                      >
                        BG Thread:
                      </td>
                      <td style={{ color: "#888383" }}>
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.background?.threadColor?.name
                        }
                      </td>
                      {orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.background?.threadColor?.price > 0 ? <td style={{ color: "#888383" }}>
                        {orderDataItem.totalQuantity} x{" "}
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.background?.threadColor?.currency
                        }
                        {
                          formatPrice(orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.background?.threadColor?.price)
                        }
                      </td>:<td style={{ color: "#888383" }}></td>}
                      {orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.background?.threadColor?.price > 0 ? <td style={{ color: "#888383" }}>
                        {
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.background?.threadColor?.currency
                        }
                        {formatPrice(orderDataItem.totalQuantity *
                          orderDataItem.customizedProducts.appliqueId[
                            appliqueView.value
                          ]?.background?.threadColor?.price)}
                      </td>:<td style={{ color: "#888383" }}></td>}
                    </>
                  )}
                </tr>
                {orderDataItem.customizedProducts.appliqueId[appliqueView.value]
                  .heatSealedLetter && (
                  <tr>
                    <td
                      style={{
                        fontSize: "1em",
                        textTransform: "uppercase",
                      }}
                    >
                      Heat Sealed:
                    </td>
                    <td style={{ color: "#888383" }}>
                      {
                        orderDataItem.customizedProducts.appliqueId[
                          appliqueView.value
                        ]?.heatSealedLetter?.isactive?"Yes":"No"
                      }
                    </td>
                    {orderDataItem.customizedProducts.appliqueId[
                          appliqueView.value
                        ]?.heatSealedLetter?.cost > 0 ? <td style={{ color: "#888383" }}>
                      {orderDataItem.totalQuantity} x{" "}
                      {orderDataItem.currency}
                      {
                        orderDataItem.customizedProducts.appliqueId[
                          appliqueView.value
                        ]?.heatSealedLetter?.isactive?formatPrice(orderDataItem.customizedProducts.appliqueId[
                          appliqueView.value
                        ]?.heatSealedLetter?.cost):"0.00"
                      }
                    </td>:<td style={{ color: "#888383" }}></td>}
                    {orderDataItem.customizedProducts.appliqueId[
                          appliqueView.value
                        ]?.heatSealedLetter?.cost > 0 ? <td style={{ color: "#888383" }}>
                      {orderDataItem.currency}
                      {orderDataItem.customizedProducts.appliqueId[
                          appliqueView.value
                        ]?.heatSealedLetter?.isactive?formatPrice(orderDataItem.totalQuantity *
                        orderDataItem.customizedProducts.appliqueId[
                          appliqueView.value
                        ]?.heatSealedLetter?.cost):"0.00"}
                    </td>:<td style={{ color: "#888383" }}></td>}
                  </tr>
                )}

                <tr>
                  <td
                    style={{
                      fontSize: "1em",
                      textTransform: "uppercase",
                    }}
                  >
                    Special Instruction's:
                  </td>
                  <td style={{ color: "#888383" }}>
                    {
                      orderDataItem.customizedProducts.appliqueId[
                        appliqueView.value
                      ]?.specialInstruction
                    }
                  </td>
                </tr>
              </tbody>
            </Table>
          </Flex>
        </>
      )}
      {tab === 3 && (
        <>
          <Flex>
            <h4>
              <strong>Embroidary Locations</strong>
            </h4>
            <Dropdown
              options={embroidaryLocations}
              onChange={(val) => {
                console.log(val, "valval");
                setEmbroidaryLocationView(val);
              }}
              value={embroidaryLocationView}
            />
          </Flex>
          <Flex>
            <Table>
              <tbody>
                {renderEm &&
                  (renderEm.name !==
                  "Crest and Images ( Price varies with size and design )" ? (
                    renderEm.name === "Custom Locations"
                    ?
                    <>
                        <tr>
                            <td></td>
                            {Object.keys(renderEm.keys).map((k, v) => {
                              return Object.keys(renderEm.keys[k]).filter((cl) => cl.indexOf("location_") !== -1).map((loc) => (
                                <td
                                  style={{
                                    color: "#888383",
                                    fontWeight: "bold",
                                    fontStyle: "italic",
                                  }}
                                >
                                  {renderEm.keys[k][loc].value}
                                  {console.log("==v== : ",renderEm.keys[k][loc])}
                                </td>
                              ));
                            })}
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                              <b
                                style={{
                                  fontSize: "1em",
                                  textTransform: "uppercase",
                                }}
                              >
                                Base Price:
                              </b>
                            </td>
                            {Object.keys(renderEm.keys).map((k) => {
                              const sel = renderEm.basePrice.find(
                                (l) => k === l.name
                              );
                              //let tot = Object.keys(renderEm.keys[k]).filter((cl) => cl.indexOf("location_") !== -1).length;
                              return Object.keys(renderEm.keys[k]).filter((cl) => cl.indexOf("location_") !== -1).map((loc) => (
                                <>
                                  <td style={{ color: "#888383" }}>
                                    {/* {tot}
                                    {"x"} */}
                                    {sel.currency}
                                    {formatPrice(sel.price)}
                                  </td>
                                </>
                              ));
                            })}
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td
                              style={{
                                fontSize: "1em",
                                textTransform: "uppercase",
                              }}
                            >
                              <b>Font Style:</b>
                            </td>
                            {Object.keys(renderEm.keys).map((k) => {
                              return Object.keys(renderEm.keys[k]).filter((cl) => cl.indexOf("fontStyles_") !== -1).map((font) => {
                                console.log("font : ", font);
                                return (
                                  <td style={{ color: "#888383" }}>
                                    {renderEm.keys[k][font].name}
                                  </td>
                                );
                              });
                            })}
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                              <b
                                style={{
                                  fontSize: "1em",
                                  textTransform: "uppercase",
                                }}
                              >
                                Font Size:
                              </b>
                            </td>
                            {Object.keys(renderEm.keys).map((k) => {
                              return Object.keys(renderEm.keys[k]).filter((cl) => cl.indexOf("fontSize_") !== -1).map((fontSize) => {
                                console.log("fontSize : ", fontSize);
                                return (
                                  <td style={{ color: "#888383" }}>
                                    {renderEm.keys[k][fontSize].name}
                                  </td>
                                );
                              });
                            })}
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                              <b
                                style={{
                                  fontSize: "1em",
                                  textTransform: "uppercase",
                                }}
                              >
                                Thread Color:
                              </b>
                            </td>
                            {Object.keys(renderEm.keys).map((k) => {
                              return Object.keys(renderEm.keys[k]).filter((cl) => cl.indexOf("threadColor_") !== -1).map((threadColor) => {
                                console.log("threadColor : ", threadColor);
                                return (
                                  <td style={{ color: "#888383" }}>
                                    {renderEm.keys[k][threadColor].name}
                                  </td>
                                );
                              });
                            })}
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                              <b
                                style={{
                                  fontSize: "1em",
                                  textTransform: "uppercase",
                                }}
                              >
                                Text Line 1:
                              </b>
                            </td>
                            {Object.keys(renderEm.keys).map((k) => {
                              return Object.keys(renderEm.keys[k]).filter((cl) => cl.indexOf("textLine1_") !== -1).map((textLine1) => {
                                console.log("textLine1 : ", textLine1);
                                return (
                                  <td style={{ color: "#888383" }}>
                                    {renderEm.keys[k][textLine1].value}
                                  </td>
                                );
                              });
                            })}
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                              <b
                                style={{
                                  fontSize: "1em",
                                  textTransform: "uppercase",
                                }}
                              >
                                Text Line 2:
                              </b>
                            </td>
                            {Object.keys(renderEm.keys).map((k) => {
                              return Object.keys(renderEm.keys[k]).filter((cl) => cl.indexOf("textLine2_") !== -1).map((textLine2) => {
                                console.log("textLine2 : ", textLine2);
                                return (
                                  <td style={{ color: "#888383" }}>
                                    {renderEm.keys[k][textLine2].value}
                                  </td>
                                );
                              });
                            })}
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                              <b
                                style={{
                                  fontSize: "1em",
                                  textTransform: "uppercase",
                                }}
                              >
                                Text Line 3:
                              </b>
                            </td>
                            {Object.keys(renderEm.keys).map((k) => {
                              return Object.keys(renderEm.keys[k]).filter((cl) => cl.indexOf("textLine3_") !== -1).map((textLine3) => {
                                console.log("textLine3 : ", textLine3);
                                return (
                                  <td style={{ color: "#888383" }}>
                                    {renderEm.keys[k][textLine3].value}
                                  </td>
                                );
                              });
                            })}
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                              <b
                                style={{
                                  fontSize: "1em",
                                  textTransform: "uppercase",
                                }}
                              >
                                Special Instructions:
                              </b>
                            </td>
                            {Object.keys(renderEm.keys).map((k) => {
                                let tot = Object.keys(renderEm.keys[k]).filter((cl) => cl.indexOf("location_") !== -1).length;
                                return (
                                  <td style={{ color: "#888383" }} colSpan={tot}>
                                    {renderEm.keys[k]["specialInstructions_actual"].value}
                                  </td>
                                );
                            })}
                            <td></td>
                            <td></td>
                        </tr>
                    </>
                    :
                    <>
                    {
                      renderEm.keys
                      ?
                        <>
                          <tr>
                            <td></td>
                            {Object.keys(renderEm.keys).map((k) => (
                              <td
                                style={{
                                  color: "#888383",
                                  fontWeight: "bold",
                                  fontStyle: "italic",
                                }}
                              >
                                {k}
                              </td>
                            ))}
                            <td></td>
                            <td></td>
                            {orderDataItem.customizedProducts.previewImages ? (
                            <th colspan="1">Preview</th>
                          ) : (
                            <></>
                          )}
                          </tr>
                          <tr>
                            <td>
                              <b
                                style={{
                                  fontSize: "1em",
                                  textTransform: "uppercase",
                                }}
                              >
                                Base Price:
                              </b>
                            </td>
                            {Object.keys(renderEm.keys).map((k) => {
                              const sel = renderEm.basePrice.find(
                                (l) => k === l.name
                              );
                              return (
                                <>
                                  <td style={{ color: "#888383" }}>
                                    {orderDataItem.totalQuantity}
                                    {"x"}
                                    {sel.currency}
                                    {formatPrice(sel.price)}
                                  </td>
                                </>
                              );
                            })}
                            <td></td>
                            <td></td>
                            {orderDataItem.customizedProducts.previewImages && (
                              <td rowspan="8">
                                {embroidaryLocationView.value == "Front" &&
                                  orderDataItem.customizedProducts.previewImages
                                    .EmboideryLocations_front && (
                                    <>
                                      <img
                                        src={
                                          orderDataItem.customizedProducts.previewImages
                                            .EmboideryLocations_front
                                        }
                                        alt={`Emboidery front preview`}
                                      />
                                    </>
                                  )}

                                {embroidaryLocationView.value == "Back" &&
                                  orderDataItem.customizedProducts.previewImages
                                    .EmboideryLocations_back && (
                                    <>
                                      <img
                                        src={
                                          orderDataItem.customizedProducts.previewImages
                                            .EmboideryLocations_back
                                        }
                                        alt={`Emboidery back preview`}
                                      />
                                    </>
                                  )}
                                  {embroidaryLocationView.value == "Right Arm" &&
                                  orderDataItem.customizedProducts.previewImages
                                    .EmboideryLocations_arm_right && (
                                    <>
                                      <img
                                        src={
                                          orderDataItem.customizedProducts.previewImages
                                            .EmboideryLocations_arm_right
                                        }
                                        alt={`Emboidery right arm preview`}
                                      />
                                    </>
                                  )}

                                {embroidaryLocationView.value == "Left Arm" &&
                                  orderDataItem.customizedProducts.previewImages
                                    .EmboideryLocations_arm_left && (
                                    <>
                                      <img
                                        src={
                                          orderDataItem.customizedProducts.previewImages
                                            .EmboideryLocations_arm_left
                                        }
                                        alt={`Emboidery left arm preview`}
                                      />
                                    </>
                                  )}
                              </td>
                            )}
                          </tr>
                          <tr>
                            <td
                              style={{
                                fontSize: "1em",
                                textTransform: "uppercase",
                              }}
                            >
                              <b>Font Style:</b>
                            </td>
                            {Object.keys(renderEm.keys).map((k) => (
                              <td style={{ color: "#888383" }}>
                                {renderEm.keys[k]?.fontStyles?.name}
                              </td>
                            ))}
                          </tr>
                          <tr>
                            <td>
                              <b
                                style={{
                                  fontSize: "1em",
                                  textTransform: "uppercase",
                                }}
                              >
                                Font Size:
                              </b>
                            </td>
                            {Object.keys(renderEm.keys).map((k) => (
                              <td style={{ color: "#888383" }}>
                                {renderEm.keys[k]?.fontSize?.name}{" "}
                                {renderEm.keys[k]?.fontSize?.currency}
                                {formatPrice(renderEm.keys[k]?.fontSize?.price)}
                              </td>
                            ))}
                          </tr>
                          <tr>
                            <td>
                              <b
                                style={{
                                  fontSize: "1em",
                                  textTransform: "uppercase",
                                }}
                              >
                                Thread Color:
                              </b>
                            </td>
                            {Object.keys(renderEm.keys).map((k) => (
                              <td style={{ color: "#888383" }}>
                                {renderEm.keys[k]?.threadColor?.name}{" "}
                                {renderEm.keys[k]?.threadColor?.currency}
                                {renderEm.keys[k]?.threadColor?.price > 0 ? formatPrice(renderEm.keys[k]?.threadColor?.price) : ""}
                              </td>
                            ))}
                          </tr>
                          <tr>
                            <td>
                              <b
                                style={{
                                  fontSize: "1em",
                                  textTransform: "uppercase",
                                }}
                              >
                                Text Line 1:
                              </b>
                            </td>
                            {Object.keys(renderEm.keys).map((k) => (
                              <td style={{ color: "#888383" }}>
                                {renderEm.keys[k]?.textLine1?.value}
                              </td>
                            ))}
                          </tr>
                        </>
                      :
                        <>
                          <tr>
                            <td>
                              <b
                                style={{
                                  fontSize: "1em",
                                  textTransform: "uppercase",
                                }}
                              >
                                Special Instructions:
                              </b>
                            </td>
                            <td style={{ color: "#888383" }}>
                              {renderEm.value}
                            </td>
                          </tr>
                        </>
                    } 
                    </>
                  ) : (
                    <>
                      <tr>
                        <td></td>
                        {Object.keys(renderEm.keys).map((k) => (
                          <td>{k}</td>
                        ))}
                        <td></td>
                        <td></td>
                        {orderDataItem.customizedProducts.previewImages ? (
                          <th colspan="1">Preview</th>
                        ) : (
                          <></>
                        )}
                      </tr>
                      <tr>
                        <td>
                          <b
                            style={{
                              fontSize: "1em",
                              textTransform: "uppercase",
                            }}
                          >
                            Base Price:
                          </b>
                        </td>
                        {Object.keys(renderEm.keys).map((k) => {
                          const sel = renderEm.basePrice.find(
                            (l) => k === l.name
                          );
                          return (
                            <>
                              <td style={{ color: "#888383" }}>
                                {orderDataItem.totalQuantity}
                                {"x"}
                                {sel.currency}
                                {formatPrice(sel.price)}
                              </td>
                            </>
                          );
                        })}
                        <td></td>
                        <td></td>
                        {orderDataItem.customizedProducts.previewImages && (
                          <td rowspan="8">
                            {embroidaryLocationView.value == "Crest and Images(price varies with size and design)" &&
                              orderDataItem.customizedProducts.previewImages
                                .EmboideryLocations_front && (
                                <>
                                  <img
                                    src={
                                      orderDataItem.customizedProducts.previewImages
                                        .EmboideryLocations_front
                                    }
                                    alt={`Emboidery front preview`}
                                  />
                                </>
                              )}
                          </td>
                        )}
                      </tr>
                      <tr>
                        <td>
                          <b
                            style={{
                              fontSize: "1em",
                              textTransform: "uppercase",
                            }}
                          >
                            Embroidary Location:
                          </b>
                        </td>
                        {Object.keys(renderEm.keys).map((k) => (
                          <td style={{ color: "#888383" }}>
                            {renderEm.keys[k].embroidaryLocation.name}
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <td>
                          <b
                            style={{
                              fontSize: "1em",
                              textTransform: "uppercase",
                            }}
                          >
                            Image Size:
                          </b>
                        </td>
                        {Object.keys(renderEm.keys).map((k) => (
                          <td style={{ color: "#888383" }}>
                            {renderEm.keys[k].imageSize.name}{" "}
                          </td>
                        ))}
                      </tr>

                      
                    </>
                  ))}
              </tbody>
            </Table>
          </Flex>
        </>
      )}
    </Container>
  );
};

export default OrderInfo;

const Title = styled.div`
  max-width: 50%;
  text-align: left;
  
  color: #424447;
  opacity: 1;
`;