export interface IOneProductResposneV2 {
  message: string;
  data: IOneProductV2;
  error: null;
}

export interface IOneProductV2 {
  _id?: string;
  cName: string;
  categoryId: ICategoryID;
  subCategoryId: ICategoryID;
  typeId: TypeID;
  typeDetailId: TypeDetailID;
  gender: string;
  name: string;
  includes: string;
  item: string;
  description: string;
  price: number;
  currency: string;
  colors: IColorV2[];
  size: string[];
  totalQuantity: number;
  appliqueId: IAppliqueID[];
  embroidaryId: EmbroidaryID[];
  letterplacementId: LetterplacementID[];
  greekLetterSizeId: GreekLetterSizeId[];
  stitchingTypeId: StitchingTypeId[];
  imgSize: {
    height: number;
    width: number;
  };
  heatSealedLetter: {
    isactive: boolean;
    cost: string;
  }
  priority: 0;
  sName: string;
  clearance: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  __v?: number;
}

export interface IAppliqueID {
  _id: string;
  name: string;
  images?: any[];
  letterPlacement: boolean;
  letterSize: boolean;
  fontStyle: boolean;
  stitchingType: boolean;
  foreground: boolean;
  background: boolean;
  specialInstruction: boolean;
  heatSealedLetter: {
    isactive: boolean;
    cost: string;
  }
  alphabetLetter?: boolean;
  greekLetter?: boolean;
  __v: number;
}

export interface ICategoryID {
  _id: string;
  name: string;
  image: string;
}

export interface IColorV2 {
  colorName: string;
  colorCode: string;
  value:string;
  "size&quantity": SizeQuantity[];
  images: ImagesV2;
}

export interface ImagesV2 {
  front: string;
  back: string;
  arm_left: string;
  arm_right: string;
  cuff_left: string;
  cuff_right: string;
  on_collar: string;
  collar_flipped_up: string;
  hood: string;
  hood_forward: string;
  hood_backward: string;
  custom: string;
}

export interface SizeQuantity {
  sizeName: string;
  sizeCode: string;
  quantity: number;
  sizePrice: number;
}

export interface EmbroidaryID {
  _id: string;
  name: string;
  images: ImagesV2;
  keys: Record<string, EmboidaryKeys[]>;
  createdAt: Date;
  updatedAt: Date;
  basePrice: any;
  __v: number;
  location: string;
}

export enum EmboidaryInputType {
  Select = "select",
  Text = "text",
  TextArea = "textarea"
}

export interface EmboidaryKeys {
  label: string;
  name: string;
  inputType: EmboidaryInputType;
  params?: string;
}

export interface LetterplacementID {
  _id: string;
  name: string;
  anchor: string;
  side: string;
  createdAt: Date;
  updatedAt: Date;
  x_axis: number;
  y_axis: number;
  __v: number;
}

export interface GreekLetterSizeId {
  _id: string;
  size: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
}

export interface StitchingTypeId {
  _id: string;
  price: number;
  currency: string;
  stitchingType: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
}

export interface TypeID {
  _id: string;
  name: string;
  type?: string;
}

export interface TypeDetailID {
  _id: string;
  name: string;
}
