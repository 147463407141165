import React from 'react';
import * as Style from './Styled.component';
import { sidebarMenu } from './StaticData.Constant';
import SideMenuBar from './SideMenuBar';

const PrivacyPage: React.FC = () => {
  return (
    <Style.MainLayoutSection>
    <SideMenuBar menuItems={sidebarMenu}/>
    <Style.PageContainer>
      {/* Main Heading */}
      <Style.MainHeading>PRIVACY POLICY</Style.MainHeading>

      {/* Sub-headings and Paragraphs */}
      <Style.SectionContainer>
        {/* Privacy Intro */}
        <Style.Section>
          <Style.Paragraph>
            At StitchZone, we understand the importance of your privacy and are committed to protecting it. This Privacy Statement outlines how we collect, use, disclose, and safeguard your information when you visit our website or make a purchase from us.
          </Style.Paragraph>
        </Style.Section>

        {/* Information We Collect */}
        <Style.Section>
          <Style.SubHeading>Information We Collect</Style.SubHeading>
          <Style.Paragraph>
            When you visit our website, we may collect personal information such as your name, email address, shipping address, and payment information. This information is collected when you voluntarily submit it to us through our online forms or during the checkout process.
          </Style.Paragraph>
        </Style.Section>

        {/* How We Use Your Information */}
        <Style.Section>
          <Style.SubHeading>How We Use Your Information</Style.SubHeading>
          <Style.Paragraph>
            We use the information we collect in the following ways:
          </Style.Paragraph>
        </Style.Section>

        {/* Use Case 1 */}
        <Style.Section>
          <Style.Paragraph>
            1. To Process Your Orders: We use your name, shipping address, and payment information to process and fulfill your orders.
          </Style.Paragraph>
        </Style.Section>

        {/* Use Case 2 */}
        <Style.Section>
          <Style.Paragraph>
            2. To Communicate With You: We may use your email address to send you order confirmations, updates, and marketing communications (if you have opted in to receive them).
          </Style.Paragraph>
        </Style.Section>

        {/* Use Case 3 */}
        <Style.Section>
          <Style.Paragraph>
            3. To Improve Our Website: We analyze how our visitors use our website to improve the user experience and optimize our website's performance.
          </Style.Paragraph>
        </Style.Section>

        {/* Data Security */}
        <Style.Section>
          <Style.SubHeading>Data Security</Style.SubHeading>
          <Style.Paragraph>
            We take the security of your information seriously. We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, disclosure, alteration, or destruction.
          </Style.Paragraph>
        </Style.Section>

        {/* Sharing Your Information */}
        <Style.Section>
          <Style.SubHeading>Sharing Your Information</Style.SubHeading>
          <Style.Paragraph>
            We do not sell, trade, or otherwise transfer your personal information to outside parties except as necessary to fulfill your order (e.g., to shipping carriers) or as required by law.
          </Style.Paragraph>
        </Style.Section>

        {/* Your Rights */}
        <Style.Section>
          <Style.SubHeading>Your Rights</Style.SubHeading>
          <Style.Paragraph>
            You have the right to access, correct, update, or delete your personal information at any time. If you would like to exercise any of these rights, please contact us using the contact information provided below.
          </Style.Paragraph>
        </Style.Section>

        {/* Changes to This Privacy Statement */}
        <Style.Section>
          <Style.SubHeading>Changes to This Privacy Statement</Style.SubHeading>
          <Style.Paragraph>
            We may update this Privacy Statement from time to time to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated Privacy Statement on our website.
          </Style.Paragraph>
        </Style.Section>

        {/* Contact Us */}
        <Style.Section>
          <Style.SubHeading>Contact Us</Style.SubHeading>
          <Style.Paragraph>
            If you have any questions about this Privacy Statement or our privacy practices, please contact us at info@stitchzone.com or at (510) 818 1182.
          </Style.Paragraph>
        </Style.Section>
      </Style.SectionContainer>
    </Style.PageContainer>
    </Style.MainLayoutSection>

  );
};

export default PrivacyPage;
