import { Dispatch } from "redux";
import { BaseApiClient } from "./BaseApiClient";
import {
  getCategory,
  getSubCategory,
  getProductList,
  getProducts,
  getMegaMenu
} from "../actions";
import {
  CategoryResponse,
  IEmboidaryOptionsResponse,
  IEmbroidary,
  IGetOneProductResponse,
  IGetProductResponse,
  ISubcategoryResponse,
  ProductResponse,
} from "../models";
import { Endpoints } from "../constants";
import { MegaMenuResponse } from "../models/ApiResponses/MegaMenuResponse";

class ProductClient extends BaseApiClient {
  public async getCategory(dispatch: Dispatch) {
    try {
      const categoryData = await this.get<CategoryResponse>("public/category");
      dispatch(getCategory(categoryData));
    } catch (error) {
      console.log(error);
    }
  }

  public async getSubCategory(catId: string, dispatch: Dispatch) {
    try {
      const { data } = await this.get<ISubcategoryResponse>(
        Endpoints.subcategoryGet.replace("{{id}}", catId)
      );
      dispatch(getSubCategory(data));
    } catch (error) {
      console.log(error);
    }
  }

  public async getProduct(subCatId: string, dispatch: Dispatch) {
    try {
      const url = `${Endpoints.productList}?subCategoryId=${subCatId}`;
      const { data } = await this.get<IGetProductResponse>(url);
      dispatch(getProductList(data));
    } catch (e) {
      console.log(
        "🚀 ~ file: ProductClient.ts:33 ~ ProductClient ~ getProduct ~ e",
        e
      );
    }
  }

  public async getProducts(dispatch: Dispatch) {
    try {
      const productData = await this.get<ProductResponse>("public/productlist");
      dispatch(getProducts(productData));
    } catch (error) {
      console.log(error);
    }
  }

  public async getProductDetails(id: string) {
    try {
      const url = `${Endpoints.productGet}?_id=${id}`;
      const { data } = await this.get<IGetOneProductResponse>(url);
      return data;
    } catch (e) {
      console.log(
        "🚀 ~ file: ProductClient.ts:33 ~ ProductClient ~ getProduct ~ e",
        e
      );
    }
  }

  public async getEmboidaryOptions(params: Record<string, string>) {
    try {
      const url = this.constructUrl(Endpoints.emboidaryOptions, params);
      const { data } = await this.get<IEmboidaryOptionsResponse>(url);
      return data;
    } catch (e) {
      console.log(
        "🚀 ~ file: ProductClient.ts:65 ~ ProductClient ~ getEmboidaryOptions ~ e:",
        e
      );
    }
  }

  async getEmbroidaryLocationList() {
    const url = Endpoints.embroidaryLocationList;
    const resposne = await this.get<IEmboidaryOptionsResponse>(url);
    return resposne.data;
  }

  async getEmboidaryDesign() {
    const url = Endpoints.emboidaryDesign;
    const resposne = await this.get<IEmboidaryOptionsResponse>(url);
    return resposne.data;
  }

  async getEmbroidaryImageSize() {
    const url = Endpoints.embroidaryImageSize;
    const resposne = await this.get<IEmboidaryOptionsResponse>(url);
    return resposne.data;
  }

  async getAllEmboidaryOptions() {
    let objToSave = {};
    return this.getEmboidaryOptions({}).then((data) => {
      console.log("getEmboidaryOptions : ", data);
      objToSave = {
        ...objToSave,
        ...data
      };

      return this.getEmbroidaryLocationList();
    }).then((data) => {
      console.log("getEmbroidaryLocationList : ", data);
      objToSave = {
        ...objToSave,
        embroidaryLocation: data
      };

      return this.getEmboidaryDesign();
    }).then((data) => {
      console.log("getEmboidaryDesign : ", data);
      objToSave = {
        ...objToSave,
        design: data
      };

      return this.getEmbroidaryImageSize();
    }).then((data) => {
      console.log("getEmbroidaryImageSize : ", data);
      objToSave = {
        ...objToSave,
        imageSize: data
      };
      console.log('objToSave ===> ', objToSave);

      return objToSave;
    });

    

    /*const data = await Promise.all([
      this.getEmboidaryOptions({}),
      this.getEmbroidaryLocationList(),
      this.getEmboidaryDesign(),
      this.getEmbroidaryImageSize(),
    ]);
    console.log('data ===> ', data);
    const objToSave = {
      ...data[0],
      embroidaryLocation: data[1],
      design: data[2],
      imageSize: data[3],
    };*/

    //return objToSave;
  }
  public async getMegaMenu(dispatch: Dispatch) {
    try {
      const megaMenuData = await this.get<MegaMenuResponse>("public/megaMenuData");
      dispatch(getMegaMenu(megaMenuData));
    } catch (error) {
      console.log(error);
    }
  }
}

export const categoryClient = new ProductClient();
